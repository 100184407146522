import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';
import LabeledEditTextField from '../../components/LabeledEditTextField'
import AwesomeAlert from 'react-native-awesome-alerts';

import { StyleSheet, View, Text, Linking } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class VehicleDetails extends Component {

  constructor(props) {
    super(props);
	
	this.state = {
      VRN: props.policies.vehicle.VRN
    };
	this.updateVRN = this.updateVRN.bind(this);
	this.saveDetails = this.saveDetails.bind(this);
  }

  updateVRN(value: string) {
    var nextState = Object.assign({}, this.state);
    nextState.VRN = value;
    this.setState(nextState);
  }

  saveDetails(value: string) {
	let theApp = this;
	if (this.props.policies.vehicle.VRN != value)
	{
		this.props.api.updateVRN(this.props.policies.id, this.props.policies.vehicle.VRN, value, function done(details) {
				theApp.setState({showVRNAlert: true});
		}, function notFound() {
			theApp.setState({loading: false, showVRNError: true});
		}, function fail(xhr) {
			console.log(xhr);
			theApp.setState({loading: false, showVRNError: true});
		});
	}
  }

  render() {
	  let theApp = this;
	return (
      <View>
        <ScreenHeading text="Vehicle Details" goBack={this.props.onDone}/>
		<View style={styles.container}>
			<Para>
			  The details we hold about your vehicle are below. Please click the edit buttons to submit updated details.
			</Para>
			<LabeledEditTextField label="VRN" id="VRN" value={this.state.VRN} onChange={this.updateVRN} onSave={this.saveDetails} />
			<Para bold="true">
			Please note:
			</Para>
			<Para bold="true">
			- If you have only recently updated your VRN with the DVLA please allow up to 10 days before you submit your amendment on our system, as our system will only accept VRNs that have been fully processed and updated on the DVLA system.
			</Para>
		  <Para bold="true">- This VRN amendment is for a personalised plate changes or a correction of the original VRN only.
If you are requesting to transfer your vehicle to a new vehicle, please refer to your terms and conditions that outline the process and e-mail your request to <Text style={styles.buttonText} onPress={() => Linking.openURL('mailto:customersupport@autoprotect.net')}>customersupport@autoprotect.net</Text>.</Para>
		  <Bottom>
			<DefaultButton text="Return to main menu" onClick={this.props.onDone}/>
		  </Bottom>
		  </View>
			<AwesomeAlert
					  show={this.state.showVRNAlert}
					  showProgress={false}
					  title="Message"
					  message="Your updated VRN has been submitted to our system."
					  closeOnTouchOutside={true}
					  closeOnHardwareBackPress={false}
					  showCancelButton={false}
					  showConfirmButton={true}
					  confirmText="OK"
					  confirmButtonColor="#DD6B55"
					  onCancelPressed={() => {
						theApp.setState({showVRNAlert: false});
					  }}
					  onConfirmPressed={() => {
						theApp.setState({showVRNAlert: false});
					  }}
					/>
			<AwesomeAlert
					  show={this.state.showVRNError}
					  showProgress={false}
					  title="Message"
					  message="There was a problem with updated VRN. We will contact you in the next 2 working days."
					  closeOnTouchOutside={true}
					  closeOnHardwareBackPress={false}
					  showCancelButton={false}
					  showConfirmButton={true}
					  confirmText="OK"
					  confirmButtonColor="#DD6B55"
					  onCancelPressed={() => {
						theApp.setState({showVRNError: false});
					  }}
					  onConfirmPressed={() => {
						theApp.setState({showVRNError: false});
					  }}
					/>
      </View>
    );
 }
 
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default VehicleDetails;
