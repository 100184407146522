import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import DatePicker from '../../components/DatePicker';
import ScreenHeading from '../../components/ScreenHeading';
import moment from 'moment'
import ButtonList from '../../components/ButtonList';
import DefaultButton from '../../components/DefaultButton';
import waitingimg from '../../loading-png-gif.gif';

import { StyleSheet, View, Text, Image } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class SmartRepairDate extends Component {
  constructor(props) {
    super();
    this.state = {
      date: new Date(),
	  suggested_dates: [],
	  dates_index: 0,
	  current_suggested_date: '',
	  loading: true,
	  arrival_time_text: '',
	  formatted_date: '',
	  showDatesText: 'Retrieving dates...',
	  yes_text: 'Yes',
	  not_submitting: true,
    };
	this.GetShineDates(props);
  }
  
  
  GetShineDates(props) {
	  let theApp = this;
	  this.setState({suggested_dates:[]});
	props.api.shineDatesLookup(props.vehiclePostcode, function done(suggested_dates) {
      if (suggested_dates) {
		if (suggested_dates.length > 0)
		{
    setTimeout(function () {
        const nextState = Object.assign({}, theApp.state, {suggested_dates: suggested_dates});
        theApp.setState(nextState);
		theApp.setState({current_suggested_date: suggested_dates[theApp.state.dates_index]});
		 var date = new Date(suggested_dates[theApp.state.dates_index].Date.split(' ').join('')); 
		 var formattedDate = moment(date, 'DD-MM-YYYY').format('DD MMM YYYY');
		 console.log(formattedDate);
		 console.log(suggested_dates);
		theApp.setState({formatted_date: formattedDate});
		
		if (suggested_dates[theApp.state.dates_index].Timeslot == "AM")
		{
			theApp.setState({arrival_time_text: 'Between 8:00 am and 10:00am'});
		}
		else
		{
			theApp.setState({arrival_time_text: 'Between 11:30 and 15:30'});
		}
		
		theApp.setState({showDatesText: 'Show my dates'});


    }, 50);
		}
		else
		{
			props.onNoAppointment();
		}

      }
	  else
		{
			props.onNoAppointment();
		}
    }, function notFound() {
		this.props.onNoAppointment();
    }, function fail(xhr) {
      this.props.onNoAppointment();
    });	 
 }

  canGetDates() {
    return (
      this.state.suggested_dates.length > 0
    );
  }
  
  notSubmitting() {
	  return this.state.not_submitting;
  }

  handleSelectDate(index) {
	  this.setState({not_submitting: false});
	  this.setState({yes_text: "Please wait"});
	  
	  var date = new Date(this.state.suggested_dates[index].Date.split(' ').join('')); 
	  var formattedDate = moment(date, 'DD-MM-YYYY').format('DD MMM YYYY');
	  this.props.onContinue(formattedDate + " " + this.state.suggested_dates[index].Timeslot, this.state.suggested_dates[index].Booking_Key);
  }
 
 GetNewShineDate()
 {
	 if (this.state.dates_index < (this.state.suggested_dates.length - 1))
	 {
		 const theApp = this;
		 var new_index = this.state.dates_index+1;
		 this.setState({dates_index: new_index});
		 //this.setState({loading: true});
		 setTimeout(function () {
			theApp.setState({current_suggested_date: theApp.state.suggested_dates[theApp.state.dates_index]});
			 var date = new Date(theApp.state.suggested_dates[theApp.state.dates_index].Date.split(' ').join('')); 
			 var formattedDate = moment(date, 'DD-MM-YYYY').format('DD MMM YYYY');
			 console.log(formattedDate);
			theApp.setState({formatted_date: formattedDate});
			if (theApp.state.suggested_dates[theApp.state.dates_index].Timeslot == "AM")
			{
				theApp.setState({arrival_time_text: 'arrival time between 8:00 am and 10:00 am'});
			}
			else
			{
				theApp.setState({arrival_time_text: 'arrival time between 11:30 and 15:30'});
			}
			//theApp.setState({loading: false});
			}, 1);
	
	 }
	 else
	 {
		 this.props.onNoAppointment();
	 }
 }

  render() {
		if (this.state.loading == true)
		{
			return (
			  <View>
				<ScreenHeading text="SMART Claim" goBack={this.props.goBack}/>
				<View style={styles.container}>
					<Para bold={true}>
						We are now going to offer up to 5 repair dates- Please note if you don't accept a date then this will push your repair date further away.
					</Para>
					<Para>
						With a small number of claims a date won't be offered but we will contact you directly so there is no need for you to call us.
					</Para>
					<View id='waiting-image'>
						<ConditionalButton text={this.state.showDatesText} onClick={() => this.setState({loading: false})} condition={() => this.canGetDates()} />
					</View>
				  </View>
			  </View>
			);
		}
		else
		{
			return (
			  <View>
				<ScreenHeading text="SMART Claim" goBack={this.props.goBack}/>
				<View style={styles.container}>
					<Para>
					  Select a date and time from the following:
					</Para>
					<ButtonList>
					{ this.notSubmitting() ?
					this.state.suggested_dates.map((thedate, index) => (
						<View style={Styles.optionsButton}>
						  <ConditionalButton condition={() => this.notSubmitting()}
							text = { moment(thedate.Date.split(' ').join(''), 'DD-MM-YYYY').format('DD MMM YYYY') + " " + thedate.Timeslot }
							onClick={(e) => this.handleSelectDate(index)}
						  />
						</View>
					  )):
					  <View><Para>Please wait ...</Para></View>
					  }
					</ButtonList>
					<Para>The arrival time for AM slots is between 8:00 am and 10:00am</Para>
					<Para>The arrival time for PM slots is between 11:30 and 15:30</Para>
				</View>
			  </View>
			);
		}
  }

  setDate(newDate) {
    this.setState({date: newDate});
  }

  canSubmit() {
    return true;
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default SmartRepairDate;
