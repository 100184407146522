import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';
import LabeledEditTextField from '../../components/LabeledEditTextField'
import Waiting from '../../SignIn/Waiting';
//import Pdf from 'react-native-pdf';

import { StyleSheet, View, Text, Linking, Dimensions } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

	function base64ToBlob( base64, type = "application/octet-stream" ) {
	  const binStr = atob( base64 );
	  const len = binStr.length;
	  const arr = new Uint8Array(len);
	  for (let i = 0; i < len; i++) {
		arr[ i ] = binStr.charCodeAt( i );
	  }
	  return new Blob( [ arr ], { type: type } );
	}


class PolicyDocumentation extends Component {

  constructor(props) {
    super(props);
	
	this.state = {
      policyData: null,
      documentName: '',
      documentName: '',
	  loading: true,
	  IsDocumentRecreationPending: false,
	  document_showing: false
    };
	
	this.downloadDocument = this.downloadDocument.bind(this);


	let theApp = this;
	console.log(props.policies);
	this.props.api.getPolicyDocument(props.policies.id, function done(details) {
	  if (details) {
		  console.log(details);
		  theApp.setState({ policyData: details.DocumentData, documentName: details.DocumentName, IsDocumentRecreationPending: details.IsDocumentRecreationPending, loading: false });
	  }
	}, function fail(xhr) {
		console.log(xhr);
		theApp.setState({loading: false});
	});
  }
  
  downloadDocument() {
	  //Linking.openURL('data:application/pdf;base64,' + this.state.policyData);
	  //window.open('data:application/pdf;base64,' + this.state.policyData, '_blank')
	  //this.setState({document_showing: true});
	  
	  /*var base64 = (this.state.policyData)
		const blob = base64ToBlob( base64, 'application/pdf' );
		const url = URL.createObjectURL( blob );
		const pdfWindow = window.open("");
		pdfWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");*/
  }


  render() {
	  let theApp = this;
	  if (this.state.loading)
	  {
			return (
			  <Waiting />
			);
	  }
	  else if (this.state.policyData == null) {
		  return (
		  <View>
			<ScreenHeading text="Policy Documentation" goBack={this.props.onDone}/>
			<View style={styles.container}>
				<Para>
				  There is currently no policy documentation available. Please check back later.
				</Para>
			  <Bottom>
				<DefaultButton text="Return to main menu" onClick={this.props.onDone}/>
			  </Bottom>
			  </View>
		  </View>
		  );
	  }
	  else if (this.state.document_showing == true) {
	return (
		  <View>
			<ScreenHeading text="Policy Documentation" goBack={this.props.onDone}/>
			<View style={styles.container}>
			</View>
			<View style={styles.container}>
			  <Bottom>
				<DefaultButton text="Return to main menu" onClick={this.props.onDone}/>
			  </Bottom>
			  </View>
		  </View>
		);
		  
	  }
	  else if (this.state.IsDocumentRecreationPending == true) {
	return (
		  <View>
			<ScreenHeading text="Policy Documentation" goBack={this.props.onDone}/>
			<View style={styles.container}>
				<Para>
				  Your policy documentation is currently in the process of being updated. Please check back later.
				</Para>
			</View>
			<View style={styles.container}>
			  <Bottom>
				<DefaultButton text="Return to main menu" onClick={this.props.onDone}/>
			  </Bottom>
			  </View>
		  </View>
		);
		  
	  }
	  else {
	return (
		  <View>
			<ScreenHeading text="Policy Documentation" goBack={this.props.onDone}/>
			<View style={styles.container}>
				<Para>
				  Click the button below to view your policy documentation.
				</Para>
				<View style={Styles.optionsButton}>
				  <DefaultButton
					text={this.state.documentName}
					onClick={this.downloadDocument}
				  />
				</View>
			  <Bottom>
				<DefaultButton text="Return to main menu" onClick={this.props.onDone}/>
			  </Bottom>
			  </View>
		  </View>
		);
	  }
 }
 
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default PolicyDocumentation;
