import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import DatePicker from '../../components/DatePicker';
import ScreenHeading from '../../components/ScreenHeading';
import YesNo from '../../components/YesNo';
import VerticalSpace from '../../components/VerticalSpace'

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class GapInsurer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      financed: props.financed,
      settlementAccepted: props.settlementAccepted,
      lessthan12months: props.lessthan12months,
      newforold: props.newforold,
      date: props.date
    };
  }

  render() {
    return(
      <View>
        <ScreenHeading text="GAP Claim" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  To help us to assist you can you please provide us with some information about the claim you have made with your road risk insurer.
			</Para>
			<Para>
			  Provide us with the date the insurer declared your vehicle a total loss.
			</Para>
			<DatePicker date={this.state.date} onChange={(date) => this.setDate(date)}/>
			<YesNo question="Is your vehicle financed?" checkState={this.state.financed} onSelect={(yes) => this.handleFinancedSelected(yes)}/>
			<YesNo question="Have you accepted any settlement figure offered by your Insurer?" checkState={this.state.settlementAccepted} onSelect={(yes) => this.handleSettlementSelected(yes)}/>
			{this.dontAccept()}
			<YesNo question="Is your vehicle less than 12 months old?" checkState={this.state.lessthan12months} onSelect={(yes) => this.handleLessThan12MonthsSelected(yes)}/>
			{this.showNewForOld()}
			<VerticalSpace height={30} />
			<Bottom>
			  <ConditionalButton text="Submit" condition={() => this.canSubmit()} onClick={() => this.props.onSubmit(this.state.date, this.state.financed, this.state.settlementAccepted, this.state.newforold, this.state.lessthan12months)}/>
			</Bottom>
		  </View>
      </View>
    );
  }

  setDate(newDate) {
    const nextState = Object.assign({}, this.state, {date: newDate});
    this.setState(nextState);
  }

  handleFinancedSelected(selected) {
    this.setState(Object.assign({}, this.state, {financed: selected}));
  }

  handleSettlementSelected(selected) {
    this.setState(Object.assign({}, this.state, {settlementAccepted: selected}));
  }

  handleLessThan12MonthsSelected(selected) {
	if (selected === false)
	{
		this.state.newforold = null;
	}
    this.setState(Object.assign({}, this.state, {lessthan12months: selected}));

  }

  handleNewForOldSelected(selected) {
    this.setState(Object.assign({}, this.state, {newforold: selected}));
  }

  dontAccept() {
    if (this.state.settlementAccepted === false) {
      return(
        <Para bold={true}>
            That's good we really need to discuss any offer with you and your insurer before you accept it.
        </Para>
      );
    } else {
      return null;
    }
  }

  showNewForOld() {
    if (this.state.lessthan12months === true) {
      return(
        <YesNo question="Has your motor insurer offered you new for old?" checkState={this.state.newforold} onSelect={(yes) => this.handleNewForOldSelected(yes)}/>
      );
    } else {
      return null;
    }
  }

  canSubmit() {
    if (this.state.financed === null) {
      return false;
    }
    if (this.state.settlementAccepted === null) {
      return false;
    }
    if (this.state.lessthan12months === null) {
      return false;
    }
	else
	{
		if (this.state.lessthan12months === true) {
			if (this.state.newforold === null) {
			  return false;
			}
		}
	}
    return this.state.date >= Date.parse(this.props.policy.coverStartDate) &&
      this.state.date <= new Date();
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default GapInsurer;
