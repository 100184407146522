import React, { Component } from 'react';

class PhotosBadge extends Component {
  render() {
    return(
      <div className={"PhotosBadge PhotosBadge-count-" + this.props.count}>
        <span className="PhotosBadge-count">{this.props.count}</span>
      </div>
    );
  }
}

export default PhotosBadge;
