import $ from 'jquery';
import each from 'async/each';
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as ImageManipulator from 'expo-image-manipulator';
import {decode as atob, encode as btoa} from 'base-64'
import { Platform } from 'react-native'
import axios from 'axios'

const delay = ms => new Promise(res => setTimeout(res, ms));

class API {
  constructor() {
    this.urlBase = 'https://api.autoprotect-app.co.uk/v2/';
    this.apiBase = this.urlBase + 'api/';
    this.username = 'consumerapp';
    this.password = '13e77e4fb7c2346075fa1533420d1d2c';
    this.standardTimeout = 30 * 1000;

    // OAuth token
    this.accessToken = '';
    this.accessTokenExpiresIn = '';
    this.accessTokenExpiryTime = '';
    // Customer session token
    this.sessionToken = '';
    this.tfaToken = '';
    // AP Staff Id
	const theApp = this;
	AsyncStorage.getItem('submittedBy', (err, result) => {
          theApp.submittedBy = result || '';
    });
    

    // Get the OAuth token on start-up
    this.getAccessToken();

    this.warrantyPhoto = this.warrantyPhoto.bind(this);
    this.alloyPhoto = this.alloyPhoto.bind(this);
    this.tyrePhoto = this.tyrePhoto.bind(this);
    this.smartPhoto = this.smartPhoto.bind(this);
  }
  
  xWwwFormUrlEncoded (data: any): string {
    const components = []
    for (const property in data) {
      const encodedKey = encodeURIComponent(property)
      const encodedValue = encodeURIComponent(data[property])
      components.push(encodedKey + '=' + encodedValue)
    }
    return components.join('&')
  }

  async getAccessToken () {
    const api = this

    const data = this.xWwwFormUrlEncoded({
      grant_type: 'password',
      username: this.username,
      password: this.password
    })

    const response = await fetch(
      this.urlBase + 'token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: data
      }
    )
    const json = await response.json()
    api.accessToken = json.access_token
	api.accessTokenExpiresIn = json.expires_in
	api.accessTokenExpiryTime = new Date(Date.now() + (json.expires_in * 60))
  }



  async timeoutPromise() {
	  await delay(this.standardTimeout);
  }
  
  async policies(data, done, notFound, fail) {
    let api = this;
    var url = this.apiBase +
      'policies/?registrationNumber=' + encodeURIComponent(data.registrationNumber) +
      '&postcode=' + encodeURIComponent(data.postcode) +
      '&surname=' + encodeURIComponent(data.surname) +
      '&policyNumber=' + encodeURIComponent(data.policyNumber) +
      '&version=15' +
      '&src=' + Platform.OS;

    // Fake a not found response.
    if (data.registrationNumber === 'bad' && data.policyNumber !== 'good') {
      url = this.apiBase + '404';
    }
	
    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        api.timeoutPromise()
      ])
	  if (response == null) {
		  fail()
	  }
      else if (response.status == 401) {
        fail()
	  }
      else if (response.status != 200) {
        notFound()
      } else {
        const json = await response.json()
        api.sessionToken = json.session_token

        done(json)
      }
    } catch (e) {
      fail(e)
    }
  }
  
  async policyDetails(done, notFound, fail) {
    let api = this;
    let url = this.apiBase +
      'policydetails?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken);

	
    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        api.timeoutPromise()
      ])
	  if (response == null) {
		  fail()
	  }
      else if (response.status == 401) {
        fail()
	  }
      else if (response.status != 200) {
        notFound()
      } else {
        const json = await response.json()
        done(json)
      }
    } catch (e) {
      fail(e)
    }
  }
  
  async getContactDetails(policyNumber, done, notFound, fail) {
    let api = this;
    var url = this.apiBase +
      'CustomerContactData/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
      '&policyId=' + encodeURIComponent(policyNumber);


    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        api.timeoutPromise()
      ])
	  if (response == null) {
		  fail()
	  }
      else if (response.status == 401) {
        fail()
	  }
      else if (response.status != 200) {
        notFound()
      } else {
        const json = await response.json()

        done(json)
      }
    } catch (e) {
      fail(e)
    }
  }

  async setContactDetails(policyNumber, emailAddress, firstName, lastName, done, notFound, fail) {
    let api = this;
    var url = this.apiBase +
      'CustomerContactData/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
      '&policyId=' + encodeURIComponent(policyNumber) +
      '&emailAddress=' + encodeURIComponent(emailAddress);


    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        api.timeoutPromise()
      ])
	  if (response == null) {
		  fail()
	  }
      else if (response.status == 401) {
        fail()
	  }
      else if (response.status != 200) {
        notFound()
      } else {
        const json = await response.text()

        done(json)
      }
    } catch (e) {
      fail(e)
    }
  }

 
  async updateName(policyNumber, previousFirstName, newFirstName, previousLastName, newLastName, imageData, done, fail, progress) {
    let url = this.apiBase +
      'CustomerContactData/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
      '&policyId=' + encodeURIComponent(policyNumber) +
      '&previousFirstName=' + encodeURIComponent(previousFirstName) +
      '&newFirstName=' + encodeURIComponent(newFirstName) +
      '&previousLastName=' + encodeURIComponent(previousLastName) +
      '&newLastName=' + encodeURIComponent(newLastName);
	  
	try {
    // separate out the mime component
    var mimeString = imageData.split(',')[0].split(':')[1].split(';')[0];
	
    let blob = '';
    let fd = new FormData();

	if (mimeString == 'image/jpeg' || mimeString == 'image/png')
	{
		const manipResult = await ImageManipulator.manipulateAsync(
		  imageData,
		  [{ resize: { width: 640 } }],
		  { compress: 0.1, format: ImageManipulator.SaveFormat.PNG, base64: true }
		);
		

		if (Platform.OS != "web")
		{
			blob = this.dataURItoBlob('data:image/png;base64,' + manipResult.base64);
			//fd.append('uploadedImage', blob, 'photo.png');
			fd.append('uploadedImage', {
				uri: manipResult.uri,
				type: 'image/png',
				name: 'photo.png',
				data: blob,
			  });
		}
		else
		{
			blob = this.dataURItoBlob(manipResult.uri);
			fd.append('uploadedImage', blob, 'photo.png');
		}	
	}
	else
	{
		blob = this.dataURItoBlob(imageData);
		fd.append('uploadedImage', blob, 'photo.pdf');
	}


      /*const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders(),
			body: fd,
			timeout: 5 * 60 * 1000,
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])*/

	axios.request({
		 method: "post", 
		url: url, 
		data: fd,
		headers: this.accessHeadersFormData(),
		timeout: 5 * 60 * 1000,
		onUploadProgress: (p) => {
		  progress(p.loaded, p.total)
		}
	})
	  .then(function (response) {
		  done(response)
	  })
	  .catch(function (error) {
		// handle error
		console.log(error)
		fail(error)
	  })
	  .finally(function () {
		// always executed
	  });

    } catch (e) {
		console.log(e)
      fail(e)
    }

  }

  async updateAddress(policyNumber, addressLine1, addressLine2, addressLine3, addressLine4, postcode, done, notFound, fail) {
	  let api = this;
    let url = this.apiBase +
      'CustomerContactData/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
      '&policyId=' + encodeURIComponent(policyNumber) +
      '&addressLine1=' + encodeURIComponent(addressLine1) +
      '&addressLine2=' + encodeURIComponent(addressLine2) +
      '&addressLine3=' + encodeURIComponent(addressLine3) +
      '&addressLine4=' + encodeURIComponent(addressLine4) +
      '&postcode=' + encodeURIComponent(postcode);
	  

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        api.timeoutPromise()
      ])
	  if (response == null) {
		  fail()
	  }
      else if (response.status == 401) {
        fail()
	  }
      else if (response.status != 200) {
        notFound()
      } else {
        const json = await response.text()

        done(json)
      }
    } catch (e) {
      fail(e)
    }
  }


  async updateVRN(policyNumber, previousVRN, newVRN, done, notFound, fail) {
    let api = this;
    var url = this.apiBase +
      'CustomerContactData/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
      '&policyId=' + encodeURIComponent(policyNumber) +
      '&previousVRN=' + encodeURIComponent(previousVRN) +
      '&newVRN=' + encodeURIComponent(newVRN);


    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        api.timeoutPromise()
      ])
	  if (response == null) {
		  fail()
	  }
      else if (response.status == 401) {
        fail()
	  }
      else if (response.status != 200) {
        notFound()
      } else {
        const json = await response.text()

        done(json)
      }
    } catch (e) {
      fail(e)
    }
  }

  async rehydrateSession(token) {
	  this.sessionToken = token
  }

  /*async userSession(data, done, fail) {
    let api = this;
    let url = this.apiBase +
      'usersession?session_token=' + encodeURIComponent(this.sessionToken) +
      '&registrationNumber=' + encodeURIComponent(data.registrationNumber) +
      '&postcode=' + encodeURIComponent(data.postcode) +
      '&policyNumber=' + encodeURIComponent(data.policyNumber) +
      '&firstName=' + encodeURIComponent(data.firstName) +
      '&lastName=' + encodeURIComponent(data.surname) +
      '&mobileNumber=' + encodeURIComponent(data.mobileNumber) +
      '&email=' + encodeURIComponent(data.email) +
      '&mileage=' + encodeURIComponent(data.mileage) +
      '&src=' + Platform.OS;

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      api.sessionToken = json.session_token
	  if (response.status == 200)
	  {
		done(json)
	  }
	  else
	  {
		  fail('failed')
	  }
    } catch (e) {
      fail(e)
    }
  }*/

  async refundAmount(policy, date, done, fail) {
    let url = this.apiBase +
      'refundamount/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
      '&policyNumber=' + encodeURIComponent(policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(policy.id) +
      '&cancellationDate=' + this.formatDate(date);

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json.amount)
    } catch (e) {
      fail(e)
    }
  };

  async sendTFACode(done, fail) {
    let url = this.apiBase +
      'usersession/?session_token=' + encodeURIComponent(this.sessionToken) +
	  '&version=2';

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  };

  async checkTFACode(code, done, fail) {
    let url = this.apiBase +
      'usersession/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(code) +
	  '&version=2';
	  
	  // for testing
	/*if (code == "123123")
	{
		done();
		return;
	}*/

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
	  if (response == null) {
		  fail()
	  }
      else if (response.status == 200) {
		  const retVal1 = await response.text()
		  let retVal = retVal1.substring(9, 11)
		  if (retVal == "xZ")
		  {
			  this.tfaToken = retVal1;
				await AsyncStorage.setItem('ast', this.sessionToken)
			  done()
		  }
		  else
		  {
			  fail()
		  }
	  }
      else {
        fail()
      }
    } catch (e) {
      fail()
    }
  };

  async postcodeLookup(postcode, done, fail) {
    let url = this.apiBase +
      'postcodelookup/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
	  'postcode=' + encodeURIComponent(postcode);

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json.result)
    } catch (e) {
      fail(e)
    }
  };

  /*async getPolicyDocument(policyId, done, fail) {
    let url = this.apiBase +
      'PolicyPackDocument/?policyNumber=' + encodeURIComponent(policyId);

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  };*/

  async shineDatesLookup(postcode, done, fail) {
    let url = this.apiBase +
      'shinedates/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&postcode=' + encodeURIComponent(postcode);

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout * 2)
      ])
      const json = await response.json()
	  /*const json = {
    'Results': [
        {
            'Date': '2023-01-16',
            'Timeslot': 'PM',
            'Technician': 'Jason Jones',
            'Technician_ID': '96898',
            'Drive_Times': {
                'CT126DR To CT66JJ': '00:22:54',
                'CT66JJ To ME46EA': '00:41:17',
                'ME46EA To CT126DR': '00:56:00'
            },
            'Booking_Key': '96898_2023-01-16_PM'
	  },{
            'Date': '2023-01-17',
            'Timeslot': 'PM',
            'Technician': 'Jason Jones',
            'Technician_ID': '96898',
            'Drive_Times': {
                'CT126DR To CT66JJ': '00:22:54',
                'CT66JJ To ME46EA': '00:41:17',
                'ME46EA To CT126DR': '00:56:00'
            },
            'Booking_Key': '96898_2023-01-16_PM'
	  }]}*/

      done(json.Results)
    } catch (e) {
      fail(e)
    }
  };

  async shineAlloyDatesLookup(postcode, done, fail) {
    let url = this.apiBase +
      'shinealloydates/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&postcode=' + encodeURIComponent(postcode);

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json.Results)
    } catch (e) {
      fail(e)
    }
  };

  async cancellation(policy, date, reason, done, fail) {
    let url = this.apiBase +
      'cancellation/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
      '&policyNumber=' + encodeURIComponent(policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(policy.id) +
      '&product=' + encodeURIComponent(policy.product) +
      '&cancellationDate=' + this.formatDate(date) +
      '&cancellationReason=' + encodeURIComponent(reason) +
      '&cancellationFeePaid=' + this.formatDate(new Date()) +
      '&submittedBy=' + this.submittedBy;
console.log(url);
    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
console.log(json);
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  /*async getcancellationfromsessiontoken(session_token, done, fail) {
    let url = this.apiBase +
      'cancellation/?session_token=' + encodeURIComponent(session_token);
	  const me = this;

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json.result)
    } catch (e) {
      fail(e)
    }
  }*/

  /*async cancellationPayment(cancellationId, token, done, fail) {
    let url = this.apiBase +
      'worldpaypayment/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&cancellation_id=' + encodeURIComponent(cancellationId) +
      '&wp_token=' + encodeURIComponent(token);

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }*/

  async cancellationBankDetails(cancellationId, accountName, sortCode, accountNumber, done, fail) {
    let url = this.apiBase +
      'CancellationBankDetails/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
      '&cancellation_id=' + encodeURIComponent(cancellationId) +
      '&account_name=' + encodeURIComponent(accountName) +
      '&sort_code=' + encodeURIComponent(sortCode) +
      '&account_number=' + encodeURIComponent(accountNumber);

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  async gapClaim(policy, insurerDate, financed, settlementAccepted, mileage, incidentDate, incident, newforold, done, fail) {
    let url = this.apiBase +
      'gapclaim/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
      '&policyNumber=' + encodeURIComponent(policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(policy.id) +
      '&dateDeclaredLoss=' + this.formatDate(insurerDate) +
      '&isVehicleFinanced=' + financed +
      '&isSettlementFigureAccepted=' + settlementAccepted +
      '&mileageAtIncident=' + encodeURIComponent(mileage) +
      '&incidentDate=' + this.formatDate(incidentDate) +
      '&incidentType=' + encodeURIComponent(incident) +
      '&newForOldOffered=' + newforold +
      '&submittedBy=' + this.submittedBy;

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
	  if (response == null) {
		  fail()
	  }
      else if (response.status == 401) {
        fail()
	  }
      else if (response.status != 200) {
        notFound()
      } else {
        const json = await response.json()

        done(json)
      }
    } catch (e) {
      fail(e)
    }
  };

  async resendGapEmail(gapClaimId, done, fail) {
    let url = this.apiBase +
      'gapresendemail/?gapClaimId=' + encodeURIComponent(gapClaimId);

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  };

  async warrantyClaim(policy, date, faultCategory, additionalInfo, vehicleLocation, done, fail) {
    let url = this.apiBase +
      'warrantyclaim/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
      '&policyNumber=' + encodeURIComponent(policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(policy.id) +
      '&dateOfBreakdown=' + this.formatDate(date) +
      '&faultCategory=' + encodeURIComponent(faultCategory) +
      '&additionalInfo=' + encodeURIComponent(additionalInfo) +
      '&vehicleLocation=' + encodeURIComponent(vehicleLocation) +
      '&submittedBy=' + this.submittedBy;

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  warrantyPhoto(claimId, imageData, photoType, done, fail, uploadProgress) {
    this.uploadPhoto('warrantyClaimPhoto/', 'warrantyClaimId', claimId, imageData, photoType, done, fail, uploadProgress);
  }

  async alloyClaim(policy, description, damageType, chosenWheel, date, wheelType, done, fail) {
    let url = this.apiBase +
      'alloyclaim/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
      '&policyNumber=' + encodeURIComponent(policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(policy.id) +
      '&dateOfDamage=' + this.formatDate(date) +
      '&descriptionOfDamage=' + encodeURIComponent(description) +
      '&typeOfDamage=' + encodeURIComponent(damageType) +
      '&typeOfWheel=' + encodeURIComponent(wheelType) +
      '&whichWheel=' + encodeURIComponent(chosenWheel) +
      '&submittedBy=' + this.submittedBy;

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  alloyPhoto(claimId, imageData, photoType, done, fail, uploadProgress) {
    this.uploadPhoto('alloyClaimPhoto/', 'alloyClaimId', claimId, imageData, photoType, done, fail, uploadProgress);
  }

  async tyreClaim(policy, damageType, chosenWheel, isDrivable, date, done, fail) {
    let url = this.apiBase +
      'tyreclaim/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
      '&policyNumber=' + encodeURIComponent(policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(policy.id) +
      '&dateOfDamage=' + this.formatDate(date) +
      '&typeOfDamage=' + encodeURIComponent(damageType) +
      '&whichWheel=' + encodeURIComponent(chosenWheel) +
      '&isDrivable=' + encodeURIComponent(isDrivable) +
      '&submittedBy=' + this.submittedBy;

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  tyrePhoto(claimId, imageData, photoType, done, fail, uploadProgress) {
    this.uploadPhoto('tyreclaimphoto/', 'tyreClaimId', claimId, imageData, photoType, done, fail, uploadProgress);
  }

  async smartClaim(policy, date, damageType, damageLocation, done, fail) {
    let url = this.apiBase +
      'smartclaim/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
      '&policyNumber=' + encodeURIComponent(policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(policy.id) +
      '&dateOfDamage=' + this.formatDate(date) +
      '&typeOfDamage=' + encodeURIComponent(damageType) +
      '&locationOfDamage=' + encodeURIComponent(damageLocation) +
      '&submittedBy=' + this.submittedBy;

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  async smartRepairDate(postcode, address, repair_date, booking_Key, done, fail) {
    let url = this.apiBase +
      'shinedates/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&postcode=' + encodeURIComponent(postcode) +
      '&address=' + encodeURIComponent(address) +
      '&repair_date=' + encodeURIComponent(repair_date) +
      '&booking_key=' + encodeURIComponent(booking_Key);

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  async alloyRepairDate(postcode, address, repair_date, booking_Key, done, fail) {
    let url = this.apiBase +
      'shinealloydates/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&postcode=' + encodeURIComponent(postcode) +
      '&address=' + encodeURIComponent(address) +
      '&repair_date=' + encodeURIComponent(repair_date) +
      '&booking_key=' + encodeURIComponent(booking_Key);

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  smartPhoto(claimId, imageData, photoType, done, fail, uploadProgress) {
    this.uploadPhoto('smartclaimphoto/', 'smartClaimId', claimId, imageData, photoType, done, fail, uploadProgress);
  }

  /*uploadPhoto(endpoint, claimIdParam, claimId, imageData, photoType, done, fail) {
    let url = this.apiBase + endpoint +
      '?session_token=' + encodeURIComponent(this.sessionToken) +
      '&' + claimIdParam + '=' + claimId +
      '&photoType=' + encodeURIComponent(photoType);

    let blob = this.dataURItoBlob(imageData);
    let fd = new FormData();
    fd.append('uploadedImage', blob, 'photo.png');

    $.ajax({
      url: url,
      headers: this.accessHeaders(),
      contentType: false,
      enctype: 'multipart/form-data',
      data: fd,
      processData: false,
      method: 'POST',
      timeout: 5 * 60 * 1000
    }).done(function(data) {
      done(data);
    }).fail(function(xhr) {
      fail(xhr);
    });
  };*/

  async uploadPhoto(endpoint, claimIdParam, claimId, imageData, photoType, done, fail, progress) {
    let url = this.apiBase + endpoint +
      '?session_token=' + encodeURIComponent(this.sessionToken) +
      '&code=' + encodeURIComponent(this.tfaToken) +
      '&' + claimIdParam + '=' + claimId +
      '&photoType=' + encodeURIComponent(photoType);
	  
	try {
	const manipResult = await ImageManipulator.manipulateAsync(
      imageData,
	  [{ resize: { width: 640 } }],
      { compress: 0.1, format: ImageManipulator.SaveFormat.PNG, base64: true }
    );
    let blob = '';
	
    let fd = new FormData();

	if (Platform.OS != "web")
	{
		blob = this.dataURItoBlob('data:image/png;base64,' + manipResult.base64);

		//fd.append('uploadedImage', blob, 'photo.png');
		fd.append('uploadedImage', {
			uri: manipResult.uri,
			type: 'image/png',
			name: 'photo.png',
			data: blob,
		  });
	}
	else
	{
		blob = this.dataURItoBlobWeb(manipResult.uri);
		fd.append('uploadedImage', blob, 'photo.png');
	}

      /*const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders(),
			body: fd,
			timeout: 5 * 60 * 1000,
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])*/

	axios.request({
		 method: "post", 
		url: url, 
		data: fd,
		headers: this.accessHeadersFormData(),
		timeout: 5 * 60 * 1000,
		onUploadProgress: (p) => {
		  progress(photoType, p.loaded, p.total)
		}
	})
	  .then(function (response) {
		  done(response)
	  })
	  .catch(function (error) {
		// handle error
		console.log(error)
		fail(error)
	  })
	  .finally(function () {
		// always executed
	  });

    } catch (e) {
		console.log(e)
      fail(e)
    }

  };

  // Uploads photos in parallel. Each photo object in the array should have the
  // following structure:
  // { data: 'uri-image-data', type: 'photo type' }
  // photoFunc should be one of the api photo uploading functions (alloyPhoto,
  // tyrePhoto, or smartPhoto).
  uploadPhotos(photoFunc, claimId, photos, done, fail, uploadProgress) {
    each(
      photos,
      function submit(photo, callback) {
        photoFunc(
          claimId,
          photo.data,
          photo.type,
          function done() {
            callback();
          },
          function fail(xhr) {
            callback(xhr);
          },
		  uploadProgress
        )
      },
      function callback(err) {
        if (err) {
          fail(err);
        } else {
          done();
        }
      }
    );
  }

  accessHeaders() {
	  let dtnow = new Date()
	  if (this.accessTokenExpiryTime < dtnow)
	  {
		  this.getAccessToken()
	  }

    return {
      "Authorization": "Bearer " + this.accessToken
    };
  }

  accessHeadersFormData() {
	  let dtnow = new Date()
	  if (this.accessTokenExpiryTime < dtnow)
	  {
		  this.getAccessToken()
	  }

    return {
      "Authorization": "Bearer " + this.accessToken,
	  "Content-Type": "multipart/form-data"
    };
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  // https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
  dataURItoBlobWeb(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }

	dataURItoBlob(dataURI) {
	 var byteString = atob(dataURI.split(',')[1]);
		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab].buffer, { type: 'image/png' });
		
	}
}

export default API;
