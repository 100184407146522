import React, { Component } from 'react';
import FailedSignIn from './FailedSignIn';
import PolicySignIn from './PolicySignIn';
import StandardSignIn from './StandardSignIn';
import PolicyHolder from './PolicyHolder';
import NotPolicyHolder from './NotPolicyHolder';
import YourDetails from './YourDetails';
import YourVehicle from './YourVehicle';
import Waiting from './Waiting';
import SignInTFA from './SignInTFA';
import AsyncStorage from '@react-native-async-storage/async-storage'
import waitingimg from '../loading-png-gif.gif';


class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signInType: 'standard',
      registrationNumber: props.registrationNumber,
      postcode: props.postcode,
      surname: '',
      policyNumber: props.policyNumber,
	  policies: props.policies,
	  details: null,
	  loading: true
    }

    this.handlePolicySignIn = this.handlePolicySignIn.bind(this);
    this.handleStandardSignIn = this.handleStandardSignIn.bind(this);
    this.handleNotPolicyHolder = this.handleNotPolicyHolder.bind(this);
    this.handlePolicyHolder = this.handlePolicyHolder.bind(this);
    this.handleSubmitDetails = this.handleSubmitDetails.bind(this);
    this.handleTFA = this.handleTFA.bind(this);
    this.handleTryAgain = this.handleTryAgain.bind(this);
    this.handleYourVehicle = this.handleYourVehicle.bind(this);
  };
  
  componentDidMount() {
	  let theApp = this;
	  if (this.props.backToEnd == true)
	  {
		  this.setState({signInType: 'standard'});
	  }
		AsyncStorage.getItem('ast').then((ast) => {
			if (ast != null && ast != '')
			{
				AsyncStorage.getItem('details.postcode').then((postcode) => {
					AsyncStorage.getItem('details.registrationNumber').then((registrationNumber) => {
						AsyncStorage.getItem('details.surname').then((surname) => {
							theApp.setState({registrationNumber: registrationNumber, postcode: postcode, surname: surname});
								theApp.setState({loading: false});
								
						});
					});
				});
			}
			else
			{
				theApp.setState({loading: false});
			}
		})
  }

  handlePolicySignIn(policyNumber, onFail) {
    const nextState = Object.assign(
      {}, this.state, {policyNumber: policyNumber}
    );
    this.setState(nextState);

    this.handleSignIn(nextState, 'failed', onFail);
  }

  handleStandardSignIn(registrationNumber, postcode, surname, onFail) {
    const nextState = Object.assign(
      {}, this.state, {registrationNumber: registrationNumber, postcode: postcode, surname: surname}
    );

    this.setState(nextState);

    this.handleSignIn(nextState, 'failed', onFail);
  }

  handleSignIn(state, notFoundScreen, onFail) {
    let signIn = this;

    this.props.api.policies(state, function done(policies) {
      if (policies) {
        const nextState = Object.assign({}, state, {signInType: 'policyHolder', policies: policies});
        signIn.setState(nextState);

		  const details = {
			registrationNumber: state.registrationNumber,
			postcode: state.postcode,
			policyNumber: state.policyNumber,
			firstName: state.firstName,
			surname: state.surname,
			mobileNumber: state.mobileNumber,
			email: state.email,
			mileage: 0
		  };
		  signIn.setState({details: details})


      }
	  else
	  {
		  onFail();
	  }
    }, function notFound() {
      const nextState = Object.assign({}, state, {signInType: notFoundScreen});
      signIn.setState(nextState);
    }, function fail(xhr) {
      console.log(xhr);
      onFail();
    });
  }

  handleYourVehicle() {
    const nextState = Object.assign({}, this.state, {signInType: 'tfa'});
    this.setState(nextState);
  }

  handlePolicyHolder() {
    const nextState = Object.assign({}, this.state, {signInType: 'yourVehicle'});
    this.setState(nextState);
  }

  handleNotPolicyHolder() {
    const nextState = Object.assign({}, this.state, {signInType: 'notPolicyHolder'});
    this.setState(nextState);
  }

  handleSubmitDetails(firstName, surname, mobileNumber, email, mileage, onFail) {
    let signIn = this;
    const nextState = Object.assign(
      {}, this.state, {
        firstName: firstName,
        surname: surname,
        mobileNumber: mobileNumber,
        email: email,
        mileage: mileage
      }
    );
    this.setState(nextState);

    this.props.api.userSession(nextState, function done(data) {
      const details = {
        registrationNumber: nextState.registrationNumber,
        postcode: nextState.postcode,
        policyNumber: nextState.policyNumber,
        firstName: nextState.firstName,
        surname: nextState.surname,
        mobileNumber: nextState.mobileNumber,
        email: nextState.email,
        mileage: nextState.mileage
      };
      signIn.setState({details: details, signInType: 'tfa'})
    }, function fail(xhr) {
      console.log(xhr);
      onFail();
    });

  }

  handleTFA() {
    let signIn = this;
		//signIn.props.onAuthenticated(signIn.state.details, signIn.state.policies);
    this.props.api.policyDetails(function done(policies) {
      if (policies) {
		signIn.props.onAuthenticated(signIn.state.details, policies);
      }
	  else
	  {
		  const nextState = Object.assign({}, signIn.state, {signInType: 'failed'});
			signIn.setState(nextState);
	  }
    }, function notFound() {
      const nextState = Object.assign({}, signIn.state, {signInType: 'failed'});
      signIn.setState(nextState);
    }, function fail(xhr) {
      const nextState = Object.assign({}, signIn.state, {signInType: 'failed'});
      signIn.setState(nextState);
    });
  }

  handleTryAgain() {
    const nextState = Object.assign({}, this.state, {signInType: 'standard'});
    this.setState(nextState);
  }

  render() {
	  if (this.state.loading)
	  {
			return (
			  <Waiting />
			);
	  }
	  else
	  {
		switch(this.state.signInType) {
		  case 'failed':
			return (
			  <FailedSignIn onTryAgain={this.handleTryAgain} goBack={() => this.setState({signInType: 'signin'})}/>
			);
		  case 'policy':
			return (
			  <PolicySignIn onContinue={this.handlePolicySignIn} goBack={() => this.props.goHome()} onNoAgreementNumber={() => this.setState({signInType: 'failed'})} policyNumber={this.state.policyNumber}/>
			);
		  case 'policyHolder':
			return (
			  <PolicyHolder onPolicyHolder={this.handlePolicyHolder} goBack={() => this.setState({signInType: 'signin'})} onNotPolicyHolder={this.handleNotPolicyHolder}/>
			);
		  case 'notPolicyHolder':
			return (
			  <NotPolicyHolder onDone={this.handleTryAgain} goBack={() => this.setState({signInType: 'policyHolder'})}/>
			);
		  case 'yourVehicle':
			return (
			  <YourVehicle onDone={this.handleYourVehicle} goBack={() => this.setState({signInType: 'policyHolder'})} onNotYourVehicle={() => this.setState({signInType: 'failed'})} policies={this.state.policies}/>
			);
		  case 'yourDetails':
			return (
			  <YourDetails onSubmit={this.handleSubmitDetails} goBack={() => this.setState({signInType: 'yourVehicle'})} surname={this.state.surname}/>
			);
		  case 'tfa':
			return (
			  <SignInTFA api={this.props.api} onDone={this.handleTFA} goBack={() => this.setState({signInType: 'yourVehicle'})} />
			);
		  default:
			return (
			  <StandardSignIn onContinue={this.handleStandardSignIn} goBack={() => this.props.goHome()} registrationNumber={this.state.registrationNumber} postcode={this.state.postcode} surname={this.state.surname}/>
			);
		}
	  }
  }
}

export default SignIn;
