import React from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';
import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

function WarrantyThanks(props) {
  return (
    <View>
      <ScreenHeading text={"Warranty"} goBack={props.goBack}/>
	  <View style={styles.container}>
		  <Para>
			Thank you, we have received your warranty claim
		  </Para>
		  <Para>
			We'll review the information and image you sent us within the next two working days.
		  </Para>
		  <Para>
			If there's anything else we need, we'll be in touch.
		  </Para>

		  <Bottom>
			<DefaultButton text="Return to main menu" onClick={props.onDone}/>
		  </Bottom>
	  </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default WarrantyThanks;
