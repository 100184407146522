import React, {Component} from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Heading from '../../components/Heading'
import Styles from '../../../constants/Styles'

class GapSuccess extends Component {
  render() {
    return(
      <View className="GapSuccess">
        <ScreenHeading text="GAP Claim" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Heading>Claim Commenced</Heading>
			<Para>
			  Thank you for providing the information we need to get your claim underway. Please look out for an email from donotreply@autoprotect-mailer.net and remember to check your spam or junk mail folder. This email contains a link to a secure web
			  page where you can upload the important
			  documents we require to process and finalise
			  your claim. You can check our FAQs section on
			  the app to find out what these are.
			</Para>
			<Bottom>
			  <DefaultButton text="Return to Menu" onClick={this.props.onDone}/>
			</Bottom>
		  </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default GapSuccess;
