import React, { FunctionComponent } from 'react'
import { Image, Linking, StyleSheet, TouchableOpacity, View } from 'react-native'
import Styles from '../../constants/Styles'
import faq from '../faq.png';
import home from '../home.png';
import logo from '../logo.png';
import Para from './Para'

type NavigationBarIconsProps = {
  goHome: Function
  logout: Function
  loggedin: Any
}

const NavigationBarIcons: FunctionComponent<NavigationBarIconsProps> = ({ goHome, loggedin, logout }) => {
    return autoprotectNav({ goHome, loggedin, logout })
}

const autoprotectNav: FunctionComponent<NavigationBarIconsProps> = ({ goHome, loggedin, logout }) => {
  const faqLink = 'https://www.autoprotect.co.uk/customer_support'
  return (
    <View style={styles.navigationBarIcons}>
      <TouchableOpacity onPress={goHome}>
        <Image style={styles.navigationBarIconSmall} source={home}></Image>
      </TouchableOpacity>
      <Image style={styles.navigationBarLogo} source={logo}></Image>
		  {loggedin == false ?
      <TouchableOpacity onPress={() => Linking.openURL(`${faqLink}`)}>
        <Image style={styles.navigationBarIconSmall} source={faq}></Image>
		  </TouchableOpacity> :       <TouchableOpacity onPress={logout}>
        <Para color={'#ffffff'}>Logout</Para>
      </TouchableOpacity>
 }
    </View>
  )
}

const styles = StyleSheet.create({
  navigationBarIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Styles.standardPadding
  },
  navigationBarLogo: {
    height: 47,
    width: 151,
	marginTop: 3
  },
  navigationBarIconSmall: {
	marginTop: 10,
    height: 30,
    width: 38
  }
})

export default NavigationBarIcons
