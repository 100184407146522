import React, {Component} from 'react';
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import SelectedButton from '../../components/SelectedButton';
import DatePicker from '../../components/DatePicker';
import LabeledTextField from '../../components/LabeledTextField';
import ScreenHeading from '../../components/ScreenHeading';
import ButtonList from '../../components/ButtonList';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class GapIncident extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.date,
      mileage: props.mileage,
      incident: props.incident || ''
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  handleFieldChange(value) {
    this.setState({mileage: value});
  }

  render() {
    return(
      <View>
        <ScreenHeading text="GAP Claim" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<LabeledTextField label="Mileage at time of incident (if known)." id="mileage" onChange={this.handleFieldChange} value={this.state.mileage}/>
			<Para>Incident date</Para>
			<DatePicker date={this.state.date} onChange={(date) => this.setDate(date)}/>
			<Para>Choose incident</Para>
				<ButtonList>
				  <View style={Styles.optionsButton}><SelectedButton selected={this.state.incident == 'Theft'} text="Theft" onClick={() => this.setIncident('Theft')}/></View>
				  <View style={Styles.optionsButton}><SelectedButton selected={this.state.incident == 'Fire'} text="Fire" onClick={() => this.setIncident('Fire')}/></View>
				  <View style={Styles.optionsButton}><SelectedButton selected={this.state.incident == 'Vandalism'} text="Vandalism" onClick={() => this.setIncident('Vandalism')}/></View>
				  <View style={Styles.optionsButton}><SelectedButton selected={this.state.incident == 'Road Accident'} text="Road Accident" onClick={() => this.setIncident('Road Accident')}/></View>
				  <View style={Styles.optionsButton}><SelectedButton selected={this.state.incident == 'Flood'} text="Flood" onClick={() => this.setIncident('Flood')}/></View>
				</ButtonList>

			{this.crimeReport()}
			<Bottom>
			  <ConditionalButton text="Submit" condition={() => this.canSubmit()} onClick={() => this.props.onSubmit(this.state.mileage, this.state.date, this.state.incident)}/>
			</Bottom>
		  </View>
      </View>
    );
  }

  setDate(newDate) {
    const nextState = Object.assign({}, this.state, {date: newDate});
    this.setState(nextState);
  }

  setIncident(value) {
    this.setState(
      Object.assign({}, this.state, {incident: value})
    );
  }

  crimeReport() {
    const incident = this.state.incident;
    if (incident === 'Theft' || incident === 'Fire' || incident === 'Vandalism') {
      return (
        <View style={styles.crimeReport}>
          <Text style={styles.crimeReportText}>
            Nothing to do just now but when we contact you next we will ask for a crime report if the total loss was due to theft, vandalism or fire.
          </Text>
        </View>
      );
    } else {
      return null;
    }
  }

  canSubmit() {
    return this.state.incident !== null && this.state.incident !== '' &&
      this.state.date <= new Date();
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  crimeReport: {
	  backgroundColor: '#d54b55',
	  color: '#fff',
	  margin: 10,
	  padding: 5,
	  fontWeight: 'bold',
	  fontStyle: 'italic',
  },
  crimeReportText: {
	  color: '#fff',
	  margin: 5,
	  padding: 5,
	  fontWeight: 'bold',
	  fontStyle: 'italic',
  }
})

export default GapIncident;
