import React, { Component } from 'react';
import ButtonList from '../../components/ButtonList';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';
import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class CancellationReason extends Component {
  constructor() {
    super();
    this.handleChosenReason = this.handleChosenReason.bind(this);
  }

  render() {
    return (
      <View>
        <ScreenHeading text="Cancellation" goBack={this.props.goBack}/>
		  <View style={styles.container}>
			<Para>
			  Please select the reason for your cancellation.
			</Para>
			<ButtonList>
			  <View style={Styles.listButton}><DefaultButton text="Buying a new car" onClick={() => this.handleChosenReason('Buying a new car')}/></View>
			  <View style={Styles.listButton}><DefaultButton text="No longer required" onClick={() => this.handleChosenReason('No longer required')}/></View>
			  <View style={Styles.listButton}><DefaultButton text="Found better cover" onClick={() => this.handleChosenReason('Found better cover')}/></View>
			  <View style={Styles.listButton}><DefaultButton text="Found cheaper cover" onClick={() => this.handleChosenReason('Found cheaper cover')}/></View>
			  <View style={Styles.listButton}><DefaultButton text="Sold my car" onClick={() => this.handleChosenReason('Sold my car')}/></View>
			  <View style={Styles.listButton}><DefaultButton text="Other" onClick={() => this.handleChosenReason('Other')}/></View>
			</ButtonList>
		</View>
      </View>
    );
  }

  handleChosenReason(reason) {
    this.props.onChosen(reason);
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default CancellationReason;
