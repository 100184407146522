import React, { Component } from 'react';

import WarrantyDate from './WarrantyDate';
import WarrantyFaultCategory from './WarrantyFaultCategory';
import WarrantyAdditionalInfo from './WarrantyAdditionalInfo';
import WarrantyLocation from './WarrantyLocation';
import WarrantyPhoto from './WarrantyPhoto';
import WarrantySubmitting from './WarrantySubmitting';
import WarrantyThanks from './WarrantyThanks';
import WarrantyFail from './WarrantyFail';

class Warranty extends Component {
  constructor() {
    super();
	
    this.state = this.freshState();

    this.handleChosenDate = this.handleChosenDate.bind(this);
    this.handleFaultCategory = this.handleFaultCategory.bind(this);
    this.handleAdditionalInfo = this.handleAdditionalInfo.bind(this);
    this.handlePhotoUpload = this.handlePhotoUpload.bind(this);
    this.handleLocationInput = this.handleLocationInput.bind(this);
    this.apiFail = this.apiFail.bind(this);
    this.uploadProgress = this.uploadProgress.bind(this);
    this.retryPhotos = this.retryPhotos.bind(this);

  };

  freshState() {
    return {
      stage: 'chooseDate',
      additionalInfo: '',
      faultCategory: null,
      vehicleLocation: '',
      photo: [],
      date: new Date(),
	  photoUploadProgress: 0,
	  photoUploadFailed: false,
      claimId: null
    };
  }

  handleChosenDate(date) {
    this.setState({stage: 'faultCategory', date: date});
  }

  handleFaultCategory(faultCategory) {
    this.setState({stage: 'additionalInfo', faultCategory: faultCategory});
  }
  
  handleAdditionalInfo(additionalInfo) {
    this.setState({stage: 'location', additionalInfo: additionalInfo});
  }

  handlePhotoUpload(photo) {
    const nextState = Object.assign(
      {}, this.state, {stage: 'submitting', photo: photo}
    );
    this.setState(nextState);
    this.submitClaim(nextState);
  }

  handleLocationInput(vehicleLocation) {
    const nextState = Object.assign(
      {}, this.state, {
        stage: 'photoUpload',
        vehicleLocation: vehicleLocation
      }
    );
    this.setState(nextState);
  }

  startAgain() {
    var state = this.freshState();
    state.stage = 'chooseDate';
    this.setState(state);
  }

  render() {
    if (this.state.stage === 'chooseDate') {
      return <WarrantyDate date={this.state.date} onContinue={this.handleChosenDate} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'faultCategory') {
      return <WarrantyFaultCategory onContinue={this.handleFaultCategory} faultCategory={this.state.faultCategory} goBack={() => this.setState({stage: 'chooseDate'})}/>;
    } else if (this.state.stage === 'additionalInfo') {
      return <WarrantyAdditionalInfo onSubmit={this.handleAdditionalInfo} additionalInfo={this.state.additionalInfo} goBack={() => this.setState({stage: 'faultCategory'})}/>;
    } else if (this.state.stage === 'location') {
      return <WarrantyLocation onContinue={this.handleLocationInput} vehicleLocation={this.state.vehicleLocation} goBack={() => this.setState({stage: 'additionalInfo'})}/>;
    } else if (this.state.stage === 'photoUpload') {
      return <WarrantyPhoto onContinue={this.handlePhotoUpload} onRetry={this.retryPhotos} goBack={() => this.setState({stage: 'location'})}/>;
    } else if (this.state.stage === 'submitting') {
      return <WarrantySubmitting photoUploadProgress={this.state.photoUploadProgress} photoUploadFailed={this.state.photoUploadFailed} onRetry={this.retryPhotos} goBack={() => this.setState({stage: 'photoUpload'})}/>;
    } else if (this.state.stage === 'thanks') {
      return <WarrantyThanks onDone={() => this.props.onDone()} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'fail') {
      return <WarrantyFail onRetry={() => this.setState({'stage': 'chooseDate'})} goBack={() => this.props.onDone()}/>;
    }
  }

  submitClaim(state) {
    const component = this;
    this.props.api.warrantyClaim(
      this.props.policy,
      state.date,
      state.faultCategory,
      state.additionalInfo,
	  state.vehicleLocation,
      function done(data) {
        state.claimId = data.claim_id;
        component.setState(state);
        component.submitPhotos(state);
      },
      component.apiFail
    );
  }

  apiFail(xhr) {
    console.log(xhr);
    this.setState({stage: 'fail'});
  }

  submitPhotos(state) {
    const component = this;
    const photos =
      state.photo.map(function(p) { return {type: 'speedometer', data: p}; });
    component.props.api.uploadPhotos(
      component.props.api.warrantyPhoto,
      state.claimId,
      photos,
      function done() { component.setState({stage: 'thanks'}) },
      component.apiFail,
	  component.uploadProgress
    );
  }
  
  retryPhotos()
  {
	  const nextState = Object.assign(
      {}, this.state, {
        photoUploadFailed: false
      }
    );
	  
	  this.setState(nextState)
      this.submitClaim(nextState);
  }

  uploadProgress(photoType, loaded, total)
  {
	  let pc = loaded / total
	  console.log(pc)
	  this.setState({photoUploadProgress: pc})
  }
}

export default Warranty;
