import React, { Component } from 'react';
import ButtonList from '../../components/ButtonList';
import DefaultButton from '../../components/DefaultButton';
import SelectedButton from '../../components/SelectedButton';
import ScreenHeading from '../../components/ScreenHeading';
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'

class WarrantyFaultCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
		AirConditioning : false,
		InCarentertainment_Satnav : false,
		Brakes : false,
		CentralLocking : false,
		Clutch : false,
		Radiator_WaterPump : false,
		Driveshafts : false,
		Engine : false,
		Fuel_Ignition : false,
		Gearbox_Automatic : false,
		Gearbox_Manual : false,
		Lights : false,
		Mirrors : false,
		Steering : false,
		Windows : false,
		Wipers : false,
		Other : false
    };
    this.handleChosenType = this.handleChosenType.bind(this);
  }
  
  componentDidMount() {
	if (this.props.faultCategory != null)
	{
		var a = this.props.faultCategory.split(','),
		i;

		for (i = 0; i < a.length; i++) {
			this.handleChosenType(null, a[i]);
		}
	}
	  
  }

  render() {
    return (
      <View>
        <ScreenHeading text="Warranty" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  What type of fault has caused your vehicle's breakdown? (Please select one or more)
			</Para>
			<ButtonList>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.AirConditioning} style={{backgroundColor: 'green'}} text="Air Conditioning" onClick={(e) => this.handleChosenType(e, 'Air Conditioning')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.InCarentertainment_Satnav} text="In Car Entertainment / Satnav" onClick={(e) => this.handleChosenType(e, 'In Car Entertainment / Satnav')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.Brakes} text="Brakes" onClick={(e) => this.handleChosenType(e, 'Brakes')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.CentralLocking} text="Central Locking" onClick={(e) => this.handleChosenType(e, 'Central Locking')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.Clutch} text="Clutch" onClick={(e) => this.handleChosenType(e, 'Clutch')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.Radiator_WaterPump} text="Radiator / Water Pump" onClick={(e) => this.handleChosenType(e, 'Radiator / Water Pump')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.Driveshafts} text="Drive Shafts" onClick={(e) => this.handleChosenType(e, 'Drive Shafts')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.Engine} text="Engine" onClick={(e) => this.handleChosenType(e, 'Engine')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.Fuel_Ignition} text="Fuel / Ignition" onClick={(e) => this.handleChosenType(e, 'Fuel / Ignition')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.Gearbox_Automatic} text="Gearbox (Automatic)" onClick={(e) => this.handleChosenType(e, 'Gearbox (Automatic)')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.Gearbox_Manual} text="Gearbox (Manual)" onClick={(e) => this.handleChosenType(e, 'Gearbox (Manual)')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.Lights} text="Lights" onClick={(e) => this.handleChosenType(e, 'Lights')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.Mirrors} text="Mirrors" onClick={(e) => this.handleChosenType(e, 'Mirrors')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.Steering} text="Steering" onClick={(e) => this.handleChosenType(e, 'Steering')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.Windows} text="Windows" onClick={(e) => this.handleChosenType(e, 'Windows')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.Wipers} text="Wipers" onClick={(e) => this.handleChosenType(e, 'Wipers')}/></View>
			  <View style={Styles.optionsButton}><SelectedButton selected={this.state.Other} text="Other" onClick={(e) => this.handleChosenType(e, 'Other')}/></View>
			</ButtonList>
			<VerticalSpace height={30} />
			<Bottom>
			  <ConditionalButton text="Submit" condition={(e) => this.canSubmit()} onClick={(e) => this.props.onContinue(this.getChosenTypes())} />
			</Bottom>
			<VerticalSpace height={15} />
		</View>
      </View>
    );
  }

  handleChosenType(e, value) {
    if (value == "Air Conditioning")
	{
		this.setState({AirConditioning: !this.state.AirConditioning});
	}
    else if (value == "In Car Entertainment / Satnav")
	{
		this.setState({InCarentertainment_Satnav: !this.state.InCarentertainment_Satnav});
	}
    else if (value == "Brakes")
	{
		this.setState({Brakes: !this.state.Brakes});
	}
    else if (value == "Central Locking")
	{
		this.setState({CentralLocking: !this.state.CentralLocking});
	}
    else if (value == "Clutch")
	{
		this.setState({Clutch: !this.state.Clutch});
	}
    else if (value == "Radiator / Water Pump")
	{
		this.setState({Radiator_WaterPump: !this.state.Radiator_WaterPump});
	}
    else if (value == "Drive Shafts")
	{
		this.setState({Driveshafts: !this.state.Driveshafts});
	}
    else if (value == "Engine")
	{
		this.setState({Engine: !this.state.Engine});
	}
    else if (value == "Fuel / Ignition")
	{
		this.setState({Fuel_Ignition: !this.state.Fuel_Ignition});
	}
    else if (value == "Gearbox (Automatic)")
	{
		this.setState({Gearbox_Automatic: !this.state.Gearbox_Automatic});
	}
    else if (value == "Gearbox (Manual)")
	{
		this.setState({Gearbox_Manual: !this.state.Gearbox_Manual});
	}
    else if (value == "Lights")
	{
		this.setState({Lights: !this.state.Lights});
	}
    else if (value == "Mirrors")
	{
		this.setState({Mirrors: !this.state.Mirrors});
	}
    else if (value == "Steering")
	{
		this.setState({Steering: !this.state.Steering});
	}
    else if (value == "Windows")
	{
		this.setState({Windows: !this.state.Windows});
	}
    else if (value == "Wipers")
	{
		this.setState({Wipers: !this.state.Wipers});
	}
    else if (value == "Other")
	{
		this.setState({Other: !this.state.Other});
	}
  }
  
  getChosenTypes()
  {
	  var retval = "";
	  if (this.state.AirConditioning == true)
	  {
		  retval += "Air Conditioning,";
	  }
	  if (this.state.InCarentertainment_Satnav == true)
	  {
		  retval += "In Car Entertainment / Satnav,";
	  }
	  if (this.state.Brakes == true)
	  {
		  retval += "Brakes,";
	  }
	  if (this.state.CentralLocking == true)
	  {
		  retval += "Central Locking,";
	  }
	  if (this.state.Clutch == true)
	  {
		  retval += "Clutch,";
	  }
	  if (this.state.Radiator_WaterPump == true)
	  {
		  retval += "Radiator / Water Pump,";
	  }
	  if (this.state.Driveshafts == true)
	  {
		  retval += "Drive shafts,";
	  }
	  if (this.state.Engine == true)
	  {
		  retval += "Engine,";
	  }
	  if (this.state.Fuel_Ignition == true)
	  {
		  retval += "Fuel / Ignition,";
	  }
	  if (this.state.Gearbox_Automatic == true)
	  {
		  retval += "Gearbox (Automatic),";
	  }
	  if (this.state.Gearbox_Manual == true)
	  {
		  retval += "Gearbox (Manual),";
	  }
	  if (this.state.Lights == true)
	  {
		  retval += "Lights,";
	  }
	  if (this.state.Mirrors == true)
	  {
		  retval += "Mirrors,";
	  }
	  if (this.state.Steering == true)
	  {
		  retval += "Steering,";
	  }
	  if (this.state.Windows == true)
	  {
		  retval += "Windows,";
	  }
	  if (this.state.Wipers == true)
	  {
		  retval += "Wipers,";
	  }
	  if (this.state.Other == true)
	  {
		  retval += "Other,";
	  }
	  
	  if (retval.length > 0)
	  {
		  if (retval.substring(retval.length-1,1) == ",")
		  {
			  retval = retval.slice(0, -1);
		  }
	  }
	  
	  return retval;
  }
  
  canSubmit() {
	 if (this.state.AirConditioning || this.state.InCarentertainment_Satnav || this.state.Brakes || this.state.CentralLocking || this.state.Clutch || this.state.Radiator_WaterPump || this.state.Driveshafts || this.state.Engine || this.state.Fuel_Ignition || this.state.Gearbox_Automatic || this.state.Gearbox_Manual || this.state.Lights || this.state.Mirrors || this.state.Steering || this.state.Windows || this.state.Wipers || this.state.Other)
	 {
		return true;
	 }
	 else
	 {
		 return false;
	 }
  }
}
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default WarrantyFaultCategory;
