import React from 'react'
import { StyleSheet, View } from 'react-native'
import DefaultButton from '../components/DefaultButton'
import NavigationBar from '../components/NavigationBar'
import Para from '../components/Para'
import ScreenHeading from '../components/ScreenHeading'
import VerticalSpace from '../components/VerticalSpace'
import Styles from '../../constants/Styles'

interface PolicyHolderProps {
  navigation: any
  onPolicyHolder:Function
  onNotPolicyHolder:Function
  goHome: Function
  goBack: Function
}

export default function PolicyHolder ({ navigation, onPolicyHolder, onNotPolicyHolder, goHome, goBack }: PolicyHolderProps) {
  return (
    <View>
      <ScreenHeading text="Agreement search" goBack={goBack}/>
      <View style={styles.container}>
        <Para>
          Are you the agreement holder?
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onClick={onPolicyHolder} text="Yes"/>
        <VerticalSpace height={15}/>
        <DefaultButton onClick={onNotPolicyHolder} text="No"/>
        <VerticalSpace height={15}/>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
