import React from 'react'
import { StyleSheet, View } from 'react-native'
import Colors from '../../constants/Colors'
import NavigationBarIcons from '../components/NavigationBarIcons'

type NavigationBarProps = {
  goHome: Function
  logout: Function
  loggedin: Any
}

const NavigationBar = ({ goHome, logout, loggedin }: NavigationBarProps) => {
  return (
    <View style={styles.navigationBar}>
      <NavigationBarIcons goHome={goHome} logout={logout} loggedin={loggedin}></NavigationBarIcons>
    </View>
  )
}

const styles = StyleSheet.create({
  navigationBar: {
    backgroundColor: Colors.primaryPurple, // AP
    //backgroundColor: Colors.white, // Shine
    color: Colors.white, // AP
    //color: Colors.dark, // Shine
    paddingBottom: 15,
    paddingTop: 15
  }
})

export default NavigationBar
