import React from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';
import VerticalSpace from '../../components/VerticalSpace'
import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

function CancellationThanks(props) {
  return (
    <View>
      <ScreenHeading text={"Cancellation"} goBack={props.goBack}/>
	  <View style={styles.container}>
		  <Para>
			Thank you, we have received your cancellation request.
		  </Para>
		  <Para>
			We will contact you within two working days if we require further information. Otherwise, your refund will be processed in three to five working days
		  </Para>

		  <Bottom>
			<DefaultButton text="Cancel another product" onClick={props.onDone}/>
			<VerticalSpace height={15}/>
			<DefaultButton text="Return to main menu" onClick={props.onDone}/>
		  </Bottom>
		</View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default CancellationThanks;
