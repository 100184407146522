import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ButtonList from '../../components/ButtonList';
import PhoneButton from '../../components/PhoneButton';
import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'


class CancellationPremiumSure extends Component {
  constructor(props) {
    super();
    this.state = {
	  submitPressed: false
    };
    let component = this;
  }

  render() {
      return this.display();
  }

  onCancel() {
    this.setState({submitPressed: false});
  }

  handleSubmit() {
    this.setState({submitPressed: true});
   
  }

  display() {
		  return (
			<View style={styles.container}>
				<Para color={'#ff0000'}>
				  You are about to cancel your agreement. Please note, once this is done we will not able to reverse this. Are you sure you wish to proceed?
				</Para>
				<Bottom>
					<ButtonList>
						<View style={Styles.listButton}>
						  <DefaultButton text="Yes, Proceed" onClick={this.props.onDone}/>
						</View>
						<View style={Styles.listButton}>
						  <DefaultButton text="No, Go Back" onClick={this.props.goBack}/>
						</View>
					</ButtonList>
				</Bottom>
			</View>
		  
		  );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default CancellationPremiumSure;
