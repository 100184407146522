import React from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';
import { StyleSheet, View } from 'react-native'
import Styles from '../../../constants/Styles'
import Para from '../../components/Para'

function CancellationIntro(props) {
	if (props.policy.currencyCode == 'GBP') {	  
  return (
    <View className="CancellationIntro">
      <ScreenHeading text="Cancellation" goBack={props.goBack}/>
	  <View style={styles.container}>
		  <Para>
			Sorry you wish to leave us. Please note the following:
		  </Para>
			<Para>	- There is a &pound;35 cancellation fee per product</Para>
			<Para>	- We require your bank details so any applicable refund can be processed.</Para>
			<Para>	- Upon cancellation, the product benefits you currently have will stop.</Para>
		  <Para>
			If you would still like to proceed please select continue.
		  </Para>
		  <Bottom>
			<DefaultButton text="Continue" onClick={props.onContinue}/>
		  </Bottom>
		</View>
    </View>
  );
	}
	else
	{
  return (
    <View className="CancellationIntro">
      <ScreenHeading text="Cancellation" goBack={props.goBack}/>
	  <View style={styles.container}>
		  <Para>
			Sorry you wish to leave us. Please note the following:
		  </Para>
			<Para>	- There is a &euro;35 cancellation fee per product</Para>
			<Para>	- We require your bank details so any applicable refund can be processed.</Para>
			<Para>	- Upon cancellation, the product benefits you currently have will stop.</Para>
		  <Para>
			If you would still like to proceed please select continue.
		  </Para>
		  <Bottom>
			<DefaultButton text="Continue" onClick={props.onContinue}/>
		  </Bottom>
		</View>
    </View>
  );
	}
}


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})


export default CancellationIntro;
