import React, { Component } from 'react';
import { StyleSheet, View, Platform } from 'react-native'
import Bottom from './Bottom';
import ButtonList from './ButtonList';
import ConditionalButton from './ConditionalButton';
import DefaultButton from './DefaultButton';
import PhotoUpload from './PhotoUpload';
import Para from './Para';
import PhotoPreviewWithDelete from './PhotoPreviewWithDelete';
import ScreenHeading from './ScreenHeading';
import Styles from '../../constants/Styles'

class MultiplePhotoUpload extends Component {
  constructor(props) {
    super();

    this.state = {
      photos: props.photos,
      addingPhoto: props.photos.length === 0
    };

    this.removePhoto = this.removePhoto.bind(this);
  }

  onPhotoUploaded(data) {
    var photos = this.state.photos.slice();
    photos.push(data);
    this.setState({photos: photos, addingPhoto: false});
  }

  addPhoto() {
    this.setState({addingPhoto: true});
  }

  cancelAddPhoto() {
    this.setState({addingPhoto: false});
  }

  removePhoto(index) {
    let photos = this.state.photos.slice();
    photos.splice(index, 1);
    this.setState({photos: photos});
  }

  onDone() {
    this.props.onDone(this.state.photos);
  }

  render() {
    if (this.state.addingPhoto) {
      return (
        <View>
          <ScreenHeading text={this.props.heading}/>
			<View style={styles.container}>
			  <Para>Please upload a {this.props.photo} photo.</Para>
			  <PhotoUpload data={null} onUpload={(data) => this.onPhotoUploaded(data)} onCancel={() => this.cancelAddPhoto()}/>
			</View>
        </View>
      );
    } else {
      return this.multiplePhotoUpload();
    }
  }

  multiplePhotoUpload() {
    const photos = this.state.photos;
    var previews = [];
    for (var i = 0; i < photos.length; i ++) {
      previews.push(
        <PhotoPreviewWithDelete key={"preview" + i} data={photos[i]} index={i} onDelete={this.removePhoto}/>
      );
    }
    return(
      <View className="MultiplePhotoUpload">
        <ScreenHeading text={this.props.heading}/>
		<View style={styles.container}>
			<Para>Your {this.props.photo} photos:</Para>
			{previews}
			<Bottom>
			  <ButtonList>
				<View style={Styles.optionsButton}>
				  <ConditionalButton text="Add Photo" condition={() => this.state.photos.length < 2} onClick={() => this.addPhoto()}/>
				</View>
				<View style={Styles.optionsButton}>
				  <DefaultButton text="Done" onClick={() => this.onDone()}/>
				</View>
			  </ButtonList>
			</Bottom>
		</View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default MultiplePhotoUpload;
