import React, { useEffect, useState } from 'react'
import { ApiDamageLocation } from '../../ApiTypes'
import { GestureResponderEvent, Image, ImageSourcePropType, Pressable, StyleSheet, View } from 'react-native'
import VerticalSpace from '../../components/VerticalSpace'
import Para from '../../components/Para'

interface SmartDamageMapProps {
  location: ApiDamageLocation
  onLocationChange: (location: ApiDamageLocation | null) => void
}

const displayImageWidth = 210
const displayImageHeight = 368
const originalImageWidth = 1281
const scaleFactor = originalImageWidth / displayImageWidth
let selectedImage = require('../../smart-damage2/Normal.png')
let local_location = null

const SmartDamageMap = ({ location, onLocationChange }: SmartDamageMapProps) => {
  const hitTargets: Array<[number, number, number, number, ApiDamageLocation, ImageSourcePropType]> = [
    [894, 1615, 1281, 1981, "driver's side rear arch", require('../../smart-damage2/DS_RearArch.png')],
    [901, 1242, 1281, 1615, "driver's side rear door", require('../../smart-damage2/DS_RearDoor.png')],
    [900, 811, 1281, 1242, "driver's side front door", require('../../smart-damage2/DS_Door.png')],
    [960, 190, 1281, 586, "driver's side front arch", require('../../smart-damage2/DS_FrontArch.png')],
    [972, 607, 1281, 790, "driver's side wing mirror", require('../../smart-damage2/DS_WingMirror.png')],
    [978, 0, 1281, 181, "driver's side front bumper corner", require('../../smart-damage2/DS_FrontBumperCorner.png')],
    [1050, 1981, 1281, 2194, "driver's side rear bumper corner", require('../../smart-damage2/DS_RearBumperCorner.png')],
    [0, 1615, 391, 1981, "passenger's side rear arch", require('../../smart-damage2/PS_RearArch.png')],
    [0, 1242, 381, 1615, "passenger's side rear door", require('../../smart-damage2/PS_RearDoor.png')],
    [9, 792, 391, 1247, "passenger's side front door", require('../../smart-damage2/PS_Door.png')],
    [18, 199, 306, 586, "passenger's side front arch", require('../../smart-damage2/PS_FrontArch.png')],
    [0, 607, 321, 790, "passenger's side wing mirror", require('../../smart-damage2/PS_WingMirror.png')],
    [60, 0, 294, 181, "passenger's side front bumper corner", require('../../smart-damage2/PS_FrontBumperCorner.png')],
    [0, 1981, 252, 2194, "passenger's side rear bumper corner", require('../../smart-damage2/PS_RearBumperCorner.png')],
    [393, 1684, 882, 2032, 'boot', require('../../smart-damage2/Boot.png')],
    [396, 882, 881, 1452, 'roof', require('../../smart-damage2/Roof.png')],
    [342, 199, 945, 616, 'bonnet', require('../../smart-damage2/Bonnet.png')],
    [321, 2007, 971, 2242, 'rear', require('../../smart-damage2/Rear.png')],
    [324, 0, 969, 196, 'front', require('../../smart-damage2/Front.png')]
  ]
  // This does not work on desktop version
  const handlePress = (e: GestureResponderEvent) => {
    const x = (e.nativeEvent.locationX || e.nativeEvent.offsetX) * scaleFactor
    const y = (e.nativeEvent.locationY || e.nativeEvent.offsetY) * scaleFactor

    for (let i = 0; i < hitTargets.length; i++) {
      const ht = hitTargets[i]
      if (x >= ht[0] && y >= ht[1] && x <= ht[2] && y < ht[3]) {
        updateLocation(ht[4])
        return
      }
    }
    updateLocation(null)
  }

  const updateLocation = (location: ApiDamageLocation | null) => {
	  onLocationChange(location)
	  local_location = location
	  for (let i = 0; i < hitTargets.length; i++) {
		const ht = hitTargets[i]
		if (ht[4] === location) {
		  selectedImage = ht[5]
		}
	  }
  }

  useEffect(() => {
    selectedImage = require('../../smart-damage2/Normal.png')
	local_location = location
	updateLocation(local_location)
	console.log(local_location)
  }, [])

  return (
  <View>
    <Pressable onPress={handlePress} style={styles.container}>
      <View style={styles.backgroundContainer}>
        <Image source={selectedImage} resizeMode="contain" style={styles.backdrop} />
      </View>
    </Pressable>
	<VerticalSpace height={15} />
		{local_location == null ? null : <Para bold={true} centerAlign={true}>Selected location: {local_location}</Para>}
	<VerticalSpace height={15} />
  </View>
  )
}

export default SmartDamageMap

const styles = StyleSheet.create({
  backdrop: {
    height: displayImageHeight,
    width: displayImageWidth,
  },
  backgroundContainer: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  container: {
    height: displayImageHeight,
    width: displayImageWidth,
	margin: 'auto',
	alignSelf: 'center',
  }
})
