import { View, StyleSheet, Text, Dimensions, Image, Platform } from 'react-native';
import React, { useState, useEffect } from 'react'
import Bottom from './components/Bottom';
import DefaultButton from './components/DefaultButton';
import Heading from './components/Heading';
import Para from './components/Para';
import styles from './../constants/Styles'
import VerticalSpace from './components/VerticalSpace'

import imageSource from './welcome_pack_banner.png';
import imageSourceMobile from './welcome_pack_banner_mobile.png';

function Welcome(props) {
	const [windowWidth, SetWindowWidth] = useState(Dimensions.get('screen').width)
	const [imageHeight, SetImageHeight] = useState(Dimensions.get('screen').width / 5.86)
	const [imageHeightMobile, SetImageHeightMobile] = useState(Dimensions.get('screen').width / 1.106122448979592)
	 const handleResize = () => {
		SetWindowWidth(Dimensions.get('screen').width)
		SetImageHeight(Dimensions.get('screen').width / 5.86)
		SetImageHeightMobile(Dimensions.get('screen').width / 1.106122448979592)
	  }
	useEffect(() => {
		if (Platform.OS == "web")
		{
			//window.addEventListener("resize", handleResize, false);
		}

	}, [])

  return(
  <View>
  <View>
  {windowWidth > 400 ?
	<Image source={imageSource} style={{height: imageHeight, width: windowWidth}} resizeMode={'contain'} />
	:
	<Image source={imageSourceMobile} style={{height: imageHeightMobile, width: windowWidth}} resizeMode={'contain'} />
  }
  </View>
    <View style={styles.container}>
      <Heading>Hello</Heading>
      <Para>Welcome to the AutoProtect App.</Para>
	    <Text style={styles.bullet}>&#8226; Make a claim</Text>
	    <Text style={styles.bullet}>&#8226; Amend your personal details</Text>
	    <Text style={styles.bullet}>&#8226; Cancel an agreement</Text>
	    <Text style={styles.bullet}>&#8226; View your policy documents</Text>
        <VerticalSpace height={15}/>

      <Bottom>
        <DefaultButton text="CONTINUE" onClick={props.onContinue}/>
      </Bottom>
    </View>
    </View>
  );
}

export default Welcome;
