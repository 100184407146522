import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Heading from '../../components/Heading'
import Styles from '../../../constants/Styles'

class GapIntro extends Component {
  render() {
    return (
      <View>
        <ScreenHeading text="GAP Claim" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Heading>Welcome to GAP Claims.</Heading>
			<Para>
			  We're sorry you're without your vehicle at this time.
			</Para>
			<Para>
			  To be able to process your claim quickly and easily we require some documents from you. However, for the moment let's start with some basic questions.
			</Para>
			<Para>
			  Then we'll provide a web link for you to upload the information we need.
			</Para>
			<Bottom>
			  <DefaultButton text="CONTINUE" onClick={this.props.onContinue}/>
			</Bottom>
		  </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default GapIntro;
