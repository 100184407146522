import React, { Component } from 'react';
import ProductInfo from '../../ProductInfo';
import CancellationIntro from './CancellationIntro';
import CancellationProduct from './CancellationProduct';
import CancellationContactDealer from './CancellationContactDealer';
import CancellationDate from './CancellationDate';
import CancellationReason from './CancellationReason';
import CancellationAmountConfirmation from './CancellationAmountConfirmation';
import CancellationFailure from './CancellationFailure';
import CancellationBankDetails from './CancellationBankDetails';
import CancellationSuccessPremium from './CancellationSuccessPremium';
import CancellationAnother from './CancellationAnother';
import CancellationThanks from './CancellationThanks';
import CancellationTFA from './CancellationTFA';
import CancellationPremiumSure from './CancellationPremiumSure';

class Cancellation extends Component {
  constructor(props) {
    super(props);

    this.state = this.freshState();

    this.handleChosenPolicy = this.handleChosenPolicy.bind(this);
    this.handleChosenDate = this.handleChosenDate.bind(this);
    this.handleChosenReason = this.handleChosenReason.bind(this);
  }

  freshState() {
    return {
      stage: 'chooseProduct',
      date: new Date(),
	  policies: this.props.policies,
      policy: null,
      reason: null,
      cancellationId: null
    };
  }

  handleChosenPolicy(policy) {
    let nextStage;
	const component = this;

    if (this.shouldContactDealer(policy)) {
      nextStage = 'contactDealer';
    } else {
		if (policy.isPremiumFunded) {
			nextStage = 'premiumSure';
		} else {
		  nextStage = 'intro';
		}
      
    }
    this.setState({stage: nextStage, policy: policy});
  }
  
  submitPremiumFunded()
  {
	  const component = this;
	this.props.api.cancellation(
	  this.state.policy,
	  this.formatDate(new Date()),
	  'Premium funded',
	  function done(data) {
		component.setState({stage: 'success'});
	  },
	  function fail(xhr) {
		console.log(xhr);
	  }
	)
	let nextStage = 'success';
    this.setState({stage: nextStage});
  }
  
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }


  handleChosenDate(date) {
    var nextState = Object.assign({}, this.state, {stage: 'chooseReason', date: date});
    this.setState(nextState);
  }

  handleChosenReason(reason) {
    var nextState = Object.assign({}, this.state, {stage: 'fee', reason: reason});
	this.state.reason = reason;
	console.log(reason);
	this.proceedToPayment();
    this.setState(nextState);
  }

  proceedToPayment() {
    const component = this;
	component.setState({stage: 'fee'});
  }

  handlePaymentSuccess() {
    const component = this;
console.log(this.state.policy);
    this.props.api.cancellation(
      this.state.policy,
      this.state.date,
      this.state.reason,
      function done(data) {
		component.markPending(component.state.policy.id);
        component.setState({stage: 'fee', cancellationId: data.cancellation_id});
		if (component.state.policy.isPremiumFunded) {
		  component.setState({stage: 'success'});
		} else {
		  component.setState({stage: 'bankDetails'});
		}
      },
      function fail(xhr) {
        console.log(xhr);
      }
    )


  }

  // Marks a policy with policy section id of id as pending cancellation.
  markPending(id) {
    var policies = this.state.policies;
    for (var i = 0; i < policies.length; i++) {
      if (policies[i].id === id) {
        policies[i].isPendingCancellation = true;
        this.props.onUpdatePolicies(policies);
      }
    }
  }

  handlePaymentFailure() {
    this.setState({stage: 'failure'});
  }

  handleBankDetailsSuccess() {
    this.anotherOrThanks();
  }

  anotherOrThanks() {
    const productInfo = new ProductInfo(this.state.policies);
    if (productInfo.anyCancellablePolicies(false)) {
      this.setState({stage: 'another'});
    } else {
      this.setState({stage: 'thanks'});
    }
  }

  shouldContactDealer(policy) {
    let d = new Date();
    let thirtyDaysAgo = new Date(d.setDate(d.getDate() - 30));
    return (Date.parse(policy.coverStartDate) >= thirtyDaysAgo);
  }

  startAgain() {
    var state = this.freshState();
    state.stage = 'chooseProduct';
    this.setState(state);
  }

  render() {
    if (this.state.stage === 'intro') {
      return <CancellationIntro policy={this.state.policy} onContinue={() => this.setState({stage: 'chooseDate'})} goBack={() => this.setState({stage: 'chooseProduct'})}/>;
    } else if (this.state.stage === 'tfa') {
      return <CancellationTFA api={this.props.api} onDone={() => this.setState({stage: 'chooseProduct'})} goBack={() => this.props.onDone()} />;
    } else if (this.state.stage === 'chooseProduct') {
      return <CancellationProduct policies={this.state.policies} onChosen={this.handleChosenPolicy} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'premiumSure') {
      return <CancellationPremiumSure onDone={() => this.submitPremiumFunded()} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'contactDealer') {
      return <CancellationContactDealer onDone={this.props.onDone} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'chooseDate') {
      return <CancellationDate policy={this.state.policy} date={this.state.date} onSubmit={this.handleChosenDate} goBack={() => this.setState({stage: 'intro'})}/>;
    } else if (this.state.stage === 'chooseReason') {
      return <CancellationReason onChosen={this.handleChosenReason} goBack={() => this.setState({stage: 'chooseDate'})}/>;
    } else if (this.state.stage === 'fee') {
      return <CancellationAmountConfirmation policy={this.state.policy} date={this.state.date} api={this.props.api} onSubmit={() => this.handlePaymentSuccess()} onDone={() => this.props.onDone()} goBack={() => this.setState({stage: 'chooseDate'})}/>
    } else if (this.state.stage === 'failure'){
      return <CancellationFailure onDone={() => this.props.onDone()} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'bankDetails') {
      return <CancellationBankDetails policy={this.state.policy} api={this.props.api} cancellationId={this.state.cancellationId} onSuccess={() => this.handleBankDetailsSuccess()} goBack={() => this.setState({stage: 'fee'})}/>;
    } else if (this.state.stage === 'success') {
      return <CancellationSuccessPremium onContinue={() => this.props.onDone()} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'another') {
      return <CancellationAnother onYes={() => this.startAgain()} onNo={() => this.setState({stage: 'thanks'})} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'thanks') {
      return <CancellationThanks onDone={() => this.props.onDone()} goBack={() => this.props.onDone()}/>;
    }
  }
}

export default Cancellation;
