import React from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';
import PhoneButton from '../../components/PhoneButton';
import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

function CancellationSuccessPremium(props) {
  return(
    <View>
      <ScreenHeading text="Cancellation" goBack={props.goBack}/>
	  <View style={styles.container}>
		  <Para>
		  Thank you for submitting your cancellation request. As your policy has been paid in instalments, your refund will need to be manually calculated. We will review the information you have sent to us within the next two working days. If there is anything else we need, we'll be in touch. There is no need to contact us further.</Para>

		  <Bottom>
			<DefaultButton text="Return to main menu" onClick={props.onContinue}/>
		  </Bottom>
		</View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default CancellationSuccessPremium;
