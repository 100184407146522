import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import { Provider } from 'react-redux'
import store from './redux/Store'
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer } from '@react-navigation/native'

import MainApp from './src/MainApp'

export default function App() {
	const Stack = createStackNavigator()
	
return (
    <Provider store={store}>
      <NavigationContainer>
		<Stack.Navigator
          screenOptions={{
            cardStyle: {
              backgroundColor: '#ffffff'
            },
            headerShown: false
          }}>
          <Stack.Screen name="AutoProtect | claims app, Asset Protection, SMART, Tyre, Alloy" component={MainApp} />
        </Stack.Navigator>
      </NavigationContainer>
    </Provider>
  )
};

