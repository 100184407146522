import React, { Component } from 'react';
import { StyleSheet, View, Platform } from 'react-native'
import Bottom from './Bottom';
import ButtonList from './ButtonList';
import ConditionalButton from './ConditionalButton'
import DefaultButton from './DefaultButton';
//import PhotoUpload from './PhotoUpload';
import PhotoPreviewWithDeleteNew from './PhotoPreviewWithDeleteNew';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Styles from '../../constants/Styles'
import NavigationBar from './NavigationBar'
import ScreenHeading from './ScreenHeading'
import Para from './Para'
import VerticalSpace from './VerticalSpace'
import * as ImagePicker from 'expo-image-picker'

class MultiplePhotoUploadTogether extends Component {
  constructor(props) {
    super();

    this.state = {
      photos: props.photos,
      addingPhoto: props.photos.length === 0,
	  errorDisplay: 'none',
	  heading: props.heading,
    };

    this.removePhoto = this.removePhoto.bind(this);
	this.pickImage = this.pickImage.bind(this);
	this.isValidImage = this.isValidImage.bind(this);
	this.setErrorDisplay = this.setErrorDisplay.bind(this);
  }
  
  setErrorDisplay(val)
  {
		this.setState({errorDisplay: val});
  }

  onPhotoUploaded(data) {
    var photos = this.state.photos.slice();
    photos.push(data);
    this.setState({photos: photos, addingPhoto: false});
  }

  addPhoto() {
    this.setState({addingPhoto: true});
  }

  cancelAddPhoto() {
    this.setState({addingPhoto: false});
  }

  removePhoto(index) {
	  console.log('2')
    let photos = this.state.photos.slice();
    photos.splice(index, 1);
    this.setState({photos: photos});
  }

  onDone() {
    this.props.onDone(this.state.photos);
  }

  render() {
      return this.multiplePhotoUploadTogether();
  }
  
  async pickImage() {
    const result : any = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      base64: true,
      aspect: [4, 3],
      quality: 1
    })
    if (!result.cancelled) {
		if (this.isValidImage(result.assets[0].uri))
		{
			if (Platform.OS != "web")
			{
				this.onPhotoUploaded('data:image/jpeg;base64,' + result.assets[0].base64)
				console.log(result.assets[0]);
			}
			else
			{
				this.onPhotoUploaded(result.assets[0].uri)
				console.log(result.assets[0]);
			}
			this.setErrorDisplay('none')
		}
		else {
			this.setErrorDisplay('flex')
		}
    }
  }

  isValidImage(dataURI){
	  return true;
	  var retVal = false;
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	
	if (mimeString == 'image/jpeg' || mimeString == 'image/png')
	{
		retVal = true;
	}
	return retVal;
  }

  multiplePhotoUploadTogether() {
    const photos = this.state.photos;
	console.log(photos.length)
    var previews = [];
    for (var i = 0; i < photos.length; i ++) {
      previews.push(
        <PhotoPreviewWithDeleteNew data={photos[i]} index={i} selected="1" onDelete={this.removePhoto}/>
      );
    }
    return(
    <View>
	  <ScreenHeading text={this.state.heading}/>
      <View style={styles.container}>
        <Para>
          You can select an image to expand it, or press the cross to delete it.
        </Para>
		<Para>
		{previews}
		</Para>
		  <VerticalSpace height={15}/>
		  <ConditionalButton text="Add Photo" condition={() => this.state.photos.length < 2} onClick={this.pickImage}/>
		  <Para bold={false} color={'red'} display={this.state.errorDisplay} centerAlign={true} fontSize={12}>Invalid file type, please upload a jpg or png file</Para>
		  <VerticalSpace height={15}/>
		  <DefaultButton text="Return" onClick={() => this.onDone()}/>
          <VerticalSpace height={15}/>
      </View>
    </View>	
	
	);
  }
}


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})

export default MultiplePhotoUploadTogether;
