import React from 'react';
import ScreenHeading from './ScreenHeading';
import { StyleSheet, View, Text } from 'react-native'
import Para from './Para'
import Heading from './Heading'
import Styles from '../../constants/Styles'

function Submitting(props) {
  return (
    <View className="Submitting">
	  <ScreenHeading text={props.heading}/>
		<View style={styles.container}>
		  <Heading>Submitting...</Heading>
		  <Para>
			Please wait while your claim is being submitted.
		  </Para>
		  {props.content}
		</View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default Submitting;
