import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class Gapresent extends Component {

  constructor(props) {
    super(props);
  }


  render() {
return (
      <View>
        <ScreenHeading text="GAP Claim"/>
		<View style={styles.container}>
			<Para>
			  Thanks, your email has been scheduled to be resent. You should receive it within the next 10 minutes.
			</Para>
		  <Bottom>
			<DefaultButton text="Return to main menu" onClick={this.props.onDone}/>
		  </Bottom>
		</View>
	  </View>
    );
 }
 
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default Gapresent;
