import React from 'react';
import Another from '../../components/Another';

function AlloyAnother(props) {
  return (
    <Another
      heading="Alloy Wheel Claim"
      doAnother="submit another alloy wheel claim"
      onYes={props.onYes}
      goBack={props.goBack}
      onNo={props.onNo}/>
  );
}

export default AlloyAnother;
