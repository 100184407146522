import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ButtonList from '../../components/ButtonList';
import PhoneButton from '../../components/PhoneButton';
import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'


class CancellationRefundAmount extends Component {
  constructor(props) {
    super();
    this.state = {
      refundAmount: null,
	  refundFee: null,
	  refundAfterFee: null,
	  submitPressed: false
    };
    let component = this;
    props.api.refundAmount(
      props.policy, props.date,
      function done(amount) {
		  console.log(amount);
        component.setState({refundAmount: amount, refundAfterFee: (amount - 35).toFixed(2)});
		//component.setState({refundAmount: 100, refundAfterFee: (100 - 35).toFixed(2)});
      },
      function fail(xhr) {
        console.log(xhr);
      }
    );
  }

  render() {
    if (this.props.policy.isProRataRefundable) {
      return this.amount();
    } else {
      return null;
    }
  }

  onCancel() {
    this.setState({submitPressed: false});
  }

  handleSubmit() {
    this.setState({submitPressed: true});
   
  }

  amount() {
    if (this.state.refundAmount) {
      if (parseFloat(this.state.refundAmount) <= 35.00) {
		 return (
		<View style={styles.container}>
		<Para>
          Unfortunately it has been calculated that you are not due a refund. 
        </Para>
			<View className="refundTable">
				<Para className="refundTableRow">
					<Text className="refundTableColumnLeft">Refund:</Text>
					<Text className="refundTableColumnRight">&pound;{this.state.refundAmount}</Text>
				</Para>
				<Para className="refundTableRow">
					<Text className="refundTableColumnLeft">Fee:</Text>
					<Text className="refundTableColumnRight">-&pound;35.00</Text>
				</Para>
				<Para className="refundTableRow refundTableLastRow" >
					<Text className="refundTableColumnLeft">Amount due:</Text>
					<Text className="refundTableColumnRight">&pound;0.00</Text>
				</Para>
				<View className="refundTableRow"></View>
			</View>
		<Para>If you would still like to cancel your agreement, please call our claims team on the number shown below.</Para>
		<View className="officeHours"><Text>Office opening hours:</Text>
		<Text>Mon - Fri 9am to 5pm</Text>
		<Text>Sat - 9am to 1pm</Text>
		<Text>Closed Sundays and Bank Holidays</Text>
		</View>
        <Bottom>
    <View className="PhoneButton">
      <Text className="DefaultButton PhoneButton-link" href="tel:+441279456500">
        01279 456500
      </Text>
    </View>
	<View>&nbsp;</View>
			<DefaultButton text="Return to main menu" onClick={this.props.onDone}/>
        </Bottom>
		</View>
		);
      /*} else if (this.state.refundAmount === '0.00') {
        return null;*/
      } 
	  else if (this.state.submitPressed == true) {
		  return (
			<View style={styles.container}>
				<Para color={'#ff0000'}>
				  You are about to cancel your agreement. Please note, once this is done we will not able to reverse this. Are you sure you wish to proceed?
				</Para>
				<Bottom>
					<ButtonList>
						<View style={Styles.listButton}>
						  <DefaultButton text="Yes, Proceed" onClick={this.props.onSubmit}/>
						</View>
						<View style={Styles.listButton}>
						  <DefaultButton text="No, Go Back" onClick={() => this.onCancel()}/>
						</View>
					</ButtonList>
				</Bottom>
			</View>
		  
		  );
	  }
	  else if (this.props.policy.currencyCode == 'GBP') {
        return (
		<View style={styles.container}>
			<Para>
			  As per your terms and conditions, there is a cancellation fee of &pound;35 for this product.
			</Para>
			<Para>
			  This fee will be taken out of your pro-rata refund. The amount due will then be paid directly into your bank account.
			</Para>
			<View style={Styles.refundTable}>
				<Para style={Styles.refundTableRow}>
					<Text style={Styles.refundTableColumnLeft}>Refund: </Text>
					<Text style={Styles.refundTableColumnRight}>&pound;{this.state.refundAmount}</Text>
				</Para>
				<Para style={Styles.refundTableRow}>
					<Text style={Styles.refundTableColumnLeft}>Fee: </Text>
					<Text style={Styles.refundTableColumnRight}>-&pound;35.00</Text>
				</Para>
				<Para bold={true} className="refundTableRow refundTableLastRow" >
					<Text style={Styles.refundTableColumnLeft}>Amount due: </Text>
					<Text style={Styles.refundTableColumnRight}>&pound;{this.state.refundAfterFee}</Text>
				</Para>
				<View style={Styles.refundTableRow}></View>
			</View>
			<Bottom>
			  <DefaultButton text="Submit" onClick={() => this.handleSubmit()}/>
			</Bottom>
		</View>
		
        );
      } else {
        return (
		<View style={styles.container}>
			<Para>
			  As per your terms and conditions, there is a cancellation fee of &euro;35 for this product.
			</Para>
			<Para>
			  This fee will be taken out of your pro-rata refund. The amount due will then be paid directly into your bank account.
			</Para>
			<View style={Styles.refundTable}>
				<Para style={Styles.refundTableRow}>
					<Text style={Styles.refundTableColumnLeft}>Refund:</Text>
					<Text style={Styles.refundTableColumnRight}>&euro;{this.state.refundAmount}</Text>
				</Para>
				<Para style={Styles.refundTableRow}>
					<Text style={Styles.refundTableColumnLeft}>Fee:</Text>
					<Text style={Styles.refundTableColumnRight}>-&euro;35.00</Text>
				</Para>
				<Para bold={true} className="refundTableRow refundTableLastRow" >
					<Text style={Styles.refundTableColumnLeft}>Amount due:</Text>
					<Text style={Styles.refundTableColumnRight}>&euro;{this.state.refundAfterFee}</Text>
				</Para>
			</View>
			<Bottom>
			  <DefaultButton text="Submit" onClick={() => this.handleSubmit()}/>
			</Bottom>
			</View>
        );
      }
    } else {
      return(
	  <View style={styles.container}>
        <Para>
          Please wait while we calculate your refund amount...
        </Para>
	</View>
      );
    }
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default CancellationRefundAmount;
