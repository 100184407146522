import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import ScreenHeading from '../../components/ScreenHeading';
import SmartDamageMap from './SmartDamageMap';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class SmartDamageLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: props.damageLocation
    };
  }

  handleLocationChange(location) {
    this.setState({location: location});
  }

  render() {
    return (
      <View>
        <ScreenHeading text="SMART Claim" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  Please tap to indicate the location of the damage.
			</Para>
			<SmartDamageMap location={this.props.damageLocation} onLocationChange={(location) => this.handleLocationChange(location)}/>
			<Bottom>
			  <ConditionalButton text="Continue" condition={() => this.canContinue()} onClick={() => this.props.onChosen(this.state.location)}/>
			</Bottom>
		  </View>
      </View>
    );
  }

  canContinue() {
    return this.state.location;
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default SmartDamageLocation;
