import React, { Component } from 'react';
import AlloySplash from './AlloySplash';
import AlloyIntro from './AlloyIntro';
import AlloyDate from './AlloyDate';
import AlloyDamageDescription from './AlloyDamageDescription';
import AlloyDamageType from './AlloyDamageType';
import AlloyWheelType from './AlloyWheelType';
import AlloyChooseWheel from './AlloyChooseWheel';
import AlloyPhotos from './AlloyPhotos';
import AlloySubmitting from './AlloySubmitting';
import AlloyAnother from './AlloyAnother';
import AlloyDifferent from './AlloyDifferent';
import AlloyThanks from './AlloyThanks';
import AlloyFail from './AlloyFail';
import AlloyVehicleLocationStatement from './AlloyVehicleLocationStatement';
import AlloyLocation from './AlloyLocation';
import AlloyRepairDate from './AlloyRepairDate';
import AlloyNoAppointment from './AlloyNoAppointment';
import AlloyDifferentReferred from './AlloyDifferentReferred';


class Alloy extends Component {
  constructor() {
    super();

    this.state = this.freshState();

    this.handleIntroContinue = this.handleIntroContinue.bind(this);
    this.handleChosenDate = this.handleChosenDate.bind(this);
    this.handleDamageDescriptionContinue = this.handleDamageDescriptionContinue.bind(this);
    this.handleChosenDamageType = this.handleChosenDamageType.bind(this);
    this.handleChosenWheelType = this.handleChosenWheelType.bind(this);
    this.handleChosenWheel = this.handleChosenWheel.bind(this);
    this.handlePhotoUploads = this.handlePhotoUploads.bind(this);
    this.handleLocationContinue = this.handleLocationContinue.bind(this);
    this.handleRepairDateContinue = this.handleRepairDateContinue.bind(this);
    this.handleNoAppointment = this.handleNoAppointment.bind(this);
    this.uploadProgress = this.uploadProgress.bind(this);
    this.retryPhotos = this.retryPhotos.bind(this);

    this.apiFail = this.apiFail.bind(this);
  };

  freshState() {
    return {
      stage: 'splash',
      description: '',
      damageType: null,
      chosenWheel: '',
      fullWheelPhotos: [],
      closeUpPhotos: [],
      meetsShineBusinessRules: 'false',
      date: new Date(),
      wheelType: null,
      claimId: null,
	  fullWheelPhotoUploadProgress: 0,
	  closeUpPhotoUploadProgress: 0,
	  photoUploadFailed: false,
    };
  }

  handleIntroContinue() {
    this.setState({stage: 'chooseDate'});
  }

  handleChosenDate(date) {
    this.setState({stage: 'damageDescription', date: date});
  }

  handleDamageDescriptionContinue(description) {
    var nextState = Object.assign({}, this.state, {stage: 'chooseDamageType', description: description});
    this.setState(nextState);
  }

  handleChosenDamageType(damageType) {
    this.setState({stage: 'chooseWheelType', damageType: damageType});
  }

  handleChosenWheelType(wheelType) {
    const nextState = Object.assign(
      {}, this.state, {stage: 'chooseWheel', wheelType: wheelType}
    );
    this.setState(nextState);
  }

  handleChosenWheel(whichWheel) {
    var nextState = Object.assign({}, this.state, {stage: 'photos', chosenWheel: whichWheel});
    this.setState(nextState);
  }

  handlePhotoUploads(fullWheelPhotos, closeUpPhotos) {
    const nextState = Object.assign(
      {}, this.state, {
        stage: 'submitting',
        fullWheelPhotos: fullWheelPhotos,
        closeUpPhotos: closeUpPhotos
      }
    );
    this.setState(nextState);
    this.submitClaim(nextState);
  }
  
  handleLocationContinue(postcode, address) {
    this.setState({vehiclePostcode: postcode});
    this.setState({vehicleAddress: address});
    this.setState({stage: 'chooseRepairDate'});
  }

  handleRepairDateContinue(repair_date, booking_Key) {
	  console.log(booking_Key);
    const component = this;
    this.props.api.alloyRepairDate(
      this.state.vehiclePostcode,
      this.state.vehicleAddress,
      repair_date,
      booking_Key,
      function done() {
      },
      component.apiFail
    );
	  
    this.setState({stage: 'different'});
  }

  handleNoAppointment() {
    this.setState({stage: 'noAppointment'});
  }
  
  retryPhotos()
  {
	  const nextState = Object.assign(
      {}, this.state, {
        photoUploadFailed: false
      }
    );
	  
	  this.setState(nextState)
      this.submitClaim(nextState);
  }

  startAgain() {
	  console.log('start');
    var state = this.freshState();
    state.stage = 'intro';
    this.setState(state);
  }

  render() {
    if (this.state.stage === 'splash') {
      return <AlloySplash onContinue={() => this.setState({stage: 'intro'})} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'intro') {
      return <AlloyIntro onContinue={this.handleIntroContinue} goBack={() => this.setState({stage: 'splash'})}/>;
    } else if (this.state.stage === 'chooseDate') {
      return <AlloyDate onSubmit={this.handleChosenDate} date={this.state.date} goBack={() => this.setState({stage: 'intro'})}/>;
    } else if (this.state.stage === 'damageDescription') {
      return <AlloyDamageDescription onContinue={this.handleDamageDescriptionContinue} description={this.state.description} goBack={() => this.setState({stage: 'chooseDate'})}/>;
    } else if (this.state.stage === 'chooseDamageType') {
      return <AlloyDamageType onChosen={this.handleChosenDamageType} goBack={() => this.setState({stage: 'damageDescription'})}/>;
    } else if (this.state.stage === 'chooseWheelType') {
      return <AlloyWheelType onChosen={this.handleChosenWheelType} goBack={() => this.setState({stage: 'chooseDamageType'})}/>;
    } else if (this.state.stage === 'chooseWheel') {
      return <AlloyChooseWheel onChosen={this.handleChosenWheel} goBack={() => this.setState({stage: 'chooseWheelType'})}/>;
    } else if (this.state.stage === 'photos') {
      return <AlloyPhotos chosenWheel={this.state.chosenWheel} onContinue={this.handlePhotoUploads} goBack={() => this.setState({stage: 'chooseWheel'})}/>;
    } else if (this.state.stage === 'submitting') {
      return <AlloySubmitting fullWheelPhotoUploadProgress={this.state.fullWheelPhotoUploadProgress} closeUpPhotoUploadProgress={this.state.closeUpPhotoUploadProgress} photoUploadFailed={this.state.photoUploadFailed} onRetry={this.retryPhotos} goBack={() => this.setState({stage: 'photos'})} />;
    } else if (this.state.stage === 'another') {
      return <AlloyAnother onYes={() => this.startAgain()} onNo={() => this.setState({stage: 'shinedirectrules'})} goBack={() => this.setState({stage: 'photos'})}/>;
    } else if (this.state.stage === 'shinedirectrules') {
		console.log(this.state);
      if (this.state.meetsShineBusinessRules === 'true') {
		  return <AlloyVehicleLocationStatement onNo={() => this.setState({stage: 'thanks'})} onContinue={() => this.setState({stage: 'vehiclelocation'})} goBack={() => this.setState({stage: 'another'})}/>;
	  }
	  else {
		  this.setState({stage: 'differentReferred'})
		  return null;
	  }
    } else if (this.state.stage === 'vehiclelocation') {
	return <AlloyLocation api={this.props.api}  onContinue={this.handleLocationContinue} goBack={() => this.setState({stage: 'shinedirectrules'})} />;
    } else if (this.state.stage === 'chooseRepairDate') {
	return <AlloyRepairDate api={this.props.api} vehiclePostcode={this.state.vehiclePostcode} onNoAppointment={this.handleNoAppointment} onContinue={this.handleRepairDateContinue} goBack={() => this.setState({stage: 'vehiclelocation'})} />;
    } else if (this.state.stage === 'noAppointment') {
	return <AlloyNoAppointment onDone={() => this.props.onDone()} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'different') {
      return <AlloyDifferent onYes={() => this.props.onDone()} onNo={() => this.props.onDoneNoMoreClaims()} />;
    } else if (this.state.stage === 'differentReferred') {
      return <AlloyDifferentReferred onYes={() => this.props.onDone()} onNo={() => this.props.onDoneNoMoreClaims()} />;
    } else if (this.state.stage === 'thanks') {
      return <AlloyThanks onDone={() => this.props.onDone()} />;
    } else if (this.state.stage === 'fail') {
      return <AlloyFail onRetry={() => this.setState({'stage': 'intro'})} />;
    }
	else
	{
		return <View>{this.state.stage}</View>
	}
  }

  submitClaim(state) {
    const component = this;
    this.props.api.alloyClaim(
      this.props.policy,
      state.description,
      state.damageType,
      state.chosenWheel,
      state.date,
      state.wheelType,
      function done(data) {
        state.claimId = data.claim_id;
		state.meetsShineBusinessRules = data.meetsShineBusinessRules;
        component.setState(state);
        component.submitPhotos(state);
      },
      component.apiFail
    );
  }

  apiFail(xhr) {
    this.setState({photoUploadFailed: true});
  }

  submitPhotos(state) {
    const component = this;
    const photos =
      state.fullWheelPhotos.map(function(p) { return {type: 'full wheel', data: p}; }).concat(
        state.closeUpPhotos.map(function(p) { return {type: 'close up', data: p}; })
      );
    component.props.api.uploadPhotos(
      component.props.api.alloyPhoto,
      state.claimId,
      photos,
      function done() { component.setState({stage: 'another'}) },
      component.apiFail,
	  component.uploadProgress
    );
  }
  
  uploadProgress(photoType, loaded, total)
  {
	  let pc = loaded / total
	  if (photoType == "full wheel")
	  {
		  this.setState({fullWheelPhotoUploadProgress: pc})
	  }
	  else
	  {
		  this.setState({closeUpPhotoUploadProgress: pc})
	  }
  }
}

export default Alloy;
