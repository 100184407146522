import React from 'react';
import Different from '../../components/Different';

function AlloyDifferent(props) {
  return (
    <Different
      heading="Alloy Wheel Claim"
      onYes={props.onYes}
      goBack={props.goBack}
      onNo={props.onNo}/>
  );
}

export default AlloyDifferent;
