import React from 'react'
import { StyleSheet, View } from 'react-native'
import DefaultButton from '../components/DefaultButton'
import NavigationBar from '../components/NavigationBar'
import Para from '../components/Para'
import ScreenHeading from '../components/ScreenHeading'
import Styles from '../../constants/Styles'
import VerticalSpace from '../components/VerticalSpace'

interface NotPolicyHolderProps {
  navigation: any
  onDone: Function
  goHome: Function
  goBack: Function
}

export default function NotPolicyHolder ({ navigation, onDone, goHome, goBack }: NotPolicyHolderProps) {
  return (
    <View>
      <ScreenHeading text="Sorry" goBack={goBack}/>
      <View style={styles.container}>
        <Para>
          We’re really sorry but under data protection regulation, as you’re not the agreement holder we’re unable to process your request. Please obtain approval from the agreement holder to process the repair request.
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onClick={onDone} text="Return to Main Menu"/>
        <VerticalSpace height={15}/>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
