import React, {Component} from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';
import VerticalSpace from '../../components/VerticalSpace'

import { GestureResponderEvent, Pressable, StyleSheet, Text, View } from 'react-native'

import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class GapConfirmation extends Component {
  render() {
    const details = this.props.details;
    return(
      <View>
        <ScreenHeading text="GAP Claim" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  We just need you to confirm the information below and we'll then email you with a link to a web page where you can upload all the documents we need to complete your claim and achieve a settlement as quickly as possible.
			</Para>
			<View>
				<Para><Text style={{fontWeight: 'bold'}}>Name: </Text>{details.firstName} {details.surname}</Para>
				<Para><Text style={{fontWeight: 'bold'}}>Contact number: </Text>{details.mobileNumber}</Para>
				<Para><Text style={{fontWeight: 'bold'}}>Email: </Text>{details.email}</Para>
			</View>
			<VerticalSpace height={15} />
			<View>
				<Para><Text style={{fontWeight: 'bold'}}>Mileage: </Text>{this.props.mileage}</Para>
				<Para><Text style={{fontWeight: 'bold'}}>Incident date: </Text>{this.props.incidentDate.toLocaleDateString()}</Para>
				<Para><Text style={{fontWeight: 'bold'}}>Type of incident: </Text>{this.props.incident}</Para>
			</View>
			<VerticalSpace height={15} />
			<View>
				<Para><Text style={{fontWeight: 'bold'}}>Insurers total loss date: </Text>{this.props.insurerDate.toLocaleDateString()}</Para>
				<Para><Text style={{fontWeight: 'bold'}}>Vehicle financed? </Text>{this.yesOrNo(this.props.financed)}</Para>
				<Para><Text style={{fontWeight: 'bold'}}>Settlement figure accepted?: </Text>{this.yesOrNo(this.props.settlementAccepted)}</Para>
			</View>
			<VerticalSpace height={15} />
			<Pressable style={styles.defaultButton} onPress={this.props.onEditInsurer}>
			  <Text style={styles.buttonText}>edit</Text>
			</Pressable>
			<Bottom>
			  <DefaultButton text="Confirm and Submit" onClick={this.props.onSubmit}/>
			</Bottom>
		  </View>
      </View>
    );
  }

  yesOrNo(bool) {
    if (bool) {
      return 'Yes';
    } else {
      return 'No';
    }
  }
}

const styles = StyleSheet.create({
  buttonText: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  defaultButton: {
    backgroundColor: '#312e49',
    borderRadius: 4,
    padding: 10
  },
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default GapConfirmation;
