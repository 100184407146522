import React from 'react';
import Different from '../../components/Different';

function TyreDifferent(props) {
  return (
    <Different
      heading="Tyre Claim"
      onYes={props.onYes}
	  goBack={props.goBack}
      onNo={props.onNo}/>
  );
}

export default TyreDifferent;
