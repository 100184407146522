import React, { Component } from 'react';
import ButtonList from '../../components/ButtonList';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class TyreDamageType extends Component {
  constructor() {
    super();
    this.handleChosenType = this.handleChosenType.bind(this);
  }

  render() {
    return (
      <View>
        <ScreenHeading text="Tyre Claim" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  Please select the type of damage.
			</Para>
			<ButtonList>
			  <View style={Styles.optionsButton}><DefaultButton text="Punctured" onClick={() => this.handleChosenType('Punctured')}/></View>
			  <View style={Styles.optionsButton}><DefaultButton text="Damaged" onClick={() => this.handleChosenType('Damaged')}/></View>
			  <View style={Styles.optionsButton}><DefaultButton text="Don't Know" onClick={() => this.handleChosenType('Dont Know')}/></View>
			</ButtonList>
		</View>
      </View>
    );
  }

  handleChosenType(value) {
    this.props.onChosen(value);
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default TyreDamageType;
