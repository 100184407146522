import React, { Component } from 'react';
import { StyleSheet, View, Image, Text, TouchableOpacity } from 'react-native'
import ButtonList from '../components/ButtonList';
import ConditionalButton from '../components/ConditionalButton';
import PhoneButton from '../components/PhoneButton';
import DefaultButton from '../components/DefaultButton';
import LabeledTextField from '../components/LabeledTextField';
import ScreenHeading from '../components/ScreenHeading';
import ProductInfo from '../ProductInfo';
import Para from '../components/Para'
import Styles from '../../constants/Styles'
import waitingimg from '../loading-png-gif.gif';
import Bottom from '../components/Bottom';
import VerticalSpace from '../components/VerticalSpace'
import moment from 'moment'

class SignInTFA extends Component {
  constructor(props) {
    super();

    this.state = {
      code: '',
	  contact_mechanism: '',
	  contact_details: '',
      submitting: false,
	  loading: true,
	  error: false,
	  token_expiry_time: null,
	  new_request: false,
	  buttonText: 'Submit',
    };
	
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.submitCode = this.submitCode.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
    this.onFail = this.onFail.bind(this);
    this.SendTFACode = this.SendTFACode.bind(this);
    this.ReSendTFACode = this.ReSendTFACode.bind(this);
	
	this.SendTFACode(props);
  }
  
  SendTFACode(props) {
	  let theApp = this;
	  this.setState({loading: true, error: false, contact_mechanism: '', contact_details: '', submitting: false, code: ''});
	props.api.sendTFACode(function done(data) {
		let startTime = moment().format('HH:mm');
		var expTime = moment(startTime ,'HH:mm').add(30,'minutes').format('HH:mm');
		
		theApp.setState({ loading: false, contact_mechanism: data.contact_mechanism, contact_details: data.contact_details, token_expiry_time: expTime});
    }, function fail(xhr) {
      console.log(xhr);
      onFail();
    });	 
 }
 
  ReSendTFACode(props) {
	  this.setState({new_request: true});
	  this.SendTFACode(props);
 }

 submitCode(value)
 {
	 this.setState({buttonText: 'Please Wait...'});

	  let theApp = this;
	 if (value == null)
	 {
		this.props.api.checkTFACode(this.state.code, function done() {
			theApp.props.onDone()
		}, function fail() {
			theApp.setState({buttonText: 'Submit'});
		  theApp.onFail();
		});	 
	 }
	 else
	 {
		this.props.api.checkTFACode(value, function done() {
			theApp.props.onDone()
		}, function fail() {
			theApp.setState({buttonText: 'Submit'});
		  theApp.onFail();
		});	 
	 }
 }
 
 onFail()
 {
	this.setState({ error: true})
 }
  
  handleCodeChange(value) {
    this.setState({code: value});
	/*if (value.length == 6)
	{
		this.submitCode(value)
	}*/
  }


  render() {
		if (this.state.loading == true)
		{
			return (
			  <View>
				<ScreenHeading text="Authenticate" goBack={this.props.goBack}/>
				<View style={styles.container}>
					<Para>Please wait...</Para>
					<View style={styles.waitingimagewrapper}>
						<Image style={styles.waitingimage} source={waitingimg} alt="Waiting..."></Image>
					</View>
				  </View>
			  </View>
			);
		}
		else if (this.state.error == true)
		{
			return (
			  <View>
				<ScreenHeading text="Authenticate" goBack={this.props.goBack}/>
				<View style={styles.container}>
					<Para>There was a problem with your code.</Para>
					<VerticalSpace height={15}/>
					  <Bottom>
						<DefaultButton text="Request a new code" onClick={() => this.SendTFACode(this.props)}/>
					  </Bottom>
				  </View>
			  </View>
			);
		}
		else
		{
			return (
			  <View>
				<ScreenHeading text="Authenticate" goBack={this.props.goBack}/>
					{this.state.contact_mechanism == "none" ? 
				  <View style={styles.container}>
					  <Para>
						Hmmm, for some reason we are unable to process your request through the app. Please contact the team on the number below and they'll get the issue resolved.
					  </Para>
					  <PhoneButton/>
					  <VerticalSpace height={15}/>
				  </View>
					:
				  <View style={styles.container}>
					<Para>
					{ this.state.contact_mechanism == "mobile" ? 
					  <Text>We have sent a code by SMS to your number ending {this.state.contact_details}. Please allow a few minutes for it to arrive.</Text>
					  :
					  <Text>We have sent a code by email to your email address ending {this.state.contact_details}. Please allow a few minutes for it to arrive.</Text>
					}
					</Para>
					<Para>This code expires at {this.state.token_expiry_time}.</Para>
					<LabeledTextField label="Enter code." id="code" onChange={this.handleCodeChange} value={this.state.code}/>
					<VerticalSpace height={15}/>
					<Bottom>
						<ConditionalButton text={this.state.buttonText} condition={(e) => this.canSubmit()} onClick={(e) => this.submitCode()}/>
					</Bottom>
					{ this.state.new_request == false ?
					<Para>Not received a code? <TouchableOpacity onPress={(e) => this.ReSendTFACode(this.props)}><Text style={Styles.linkstyle}>Click here to resend</Text></TouchableOpacity></Para>
					:
					<Para>We've received your request. A new code is on the way.</Para>
					}
					<Text>Having trouble receiving your authentication codes?</Text>
					<PhoneButton />
					  <VerticalSpace height={15}/>
				  </View>
					}
			  </View>
			);
		}
  }

  canSubmit() {
	return this.state.code != '' && this.state.buttonText === 'Submit'
	//return true;
  }
  
}


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  waitingimage: {
    width: 200,
    height: 200,
	resizeMode: 'contain'
  },
  waitingimagewrapper: {
	  width: '100%'
  }
})

export default SignInTFA;
