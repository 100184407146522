import React, { Component } from 'react';


import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'


class AlloyWheelChoice extends Component {
  render() {
    return (
          <TouchableOpacity onPress={(e) => this.props.onChosen(this.props.label)} style={styles.rowInnerContainer}>
            <View style={styles.imageContainer}>
              <Image style={styles.rowImage} source={require('../../alloy-wheel.png')}></Image>
            </View>
			<Para centerAlign={true}>{this.props.label}</Para>
          </TouchableOpacity>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  imageContainer: {
    width: 150,
	height: 150
  },
  containedImage: {
    width: '100%',
    height: '100%'
  },
  imageOverlay: {
    position: 'relative',
    top: -35,
    width: '90%'
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
	width: '100%'
  },
  rowImage: {
    height: 140,
    resizeMode: 'cover',
    width: 140
  },
  rowInnerContainer: {
    width: '45%',
	display: 'flex',
	alignItems: 'center',
	textAlignVertical: 'top',
  }
})


export default AlloyWheelChoice;
