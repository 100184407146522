class ProductInfo {
  constructor(policies) {
    this.policies = policies;
  }

  policyIncluding(productName) {
    for (var p = 0; p < this.policies.length; p ++) {
      let policy = this.policies[p];
      for (var l = 0; l < policy.LossTypes.length; l ++) {
        if (policy.LossTypes[l].Name.toLowerCase().indexOf(productName) !== -1  && !policy.isPendingCancellation) {
          return policy;
        }
      }
    }
    return null;
  }

  // Set includePending to true if you want to include policies that are pending
  // cancellation.
  anyCancellablePolicies(includePending) {
    return (this.cancellablePolicies(includePending).length > 0);
  }

  // Set includePending to true if you want to include policies that are pending
  // cancellation.
  cancellablePolicies(includePending) {
    var cancellable = [];
    for (var i = 0; i < this.policies.length; i ++) {
      if (this.policies[i].isCancellable) {
        if (includePending || !this.policies[i].isPendingCancellation) {
          cancellable.push(this.policies[i]);
        }
      }
    }
    return cancellable;
  }

  // Set hasExistingClaim to true if you want to include policies that are pending
  // cancellation.
  hasExistingClaim() {
    var retVal = false;
    for (var i = 0; i < this.policies.length; i ++) {
      if (this.policies[i].hasExistingClaim) {
        retVal = true;
      }
    }
    return retVal;
  }

  // Set hasExistingClaim to true if you want to include policies that are pending
  // cancellation.
  existingClaimEmail() {
    var retVal = "";
    for (var i = 0; i < this.policies.length; i ++) {
      if (this.policies[i].hasExistingClaim) {
        retVal = this.policies[i].existingClaimEmailAddress;
      }
    }
    return retVal;
  }
  existingClaimId() {
    var retVal = "";
    for (var i = 0; i < this.policies.length; i ++) {
      if (this.policies[i].hasExistingClaim) {
        retVal = this.policies[i].existingClaimId;
      }
    }
    return retVal;
  }
}

export default ProductInfo;
