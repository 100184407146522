import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import ScreenHeading from '../../components/ScreenHeading';
import LabeledTextField from '../../components/LabeledTextField';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class AlloyLocation extends Component {
  constructor() {
    super();
    this.state = {
      lookupPostcode: '',
      vehiclePostcode: '',
      vehicleHouseNumber: '',
      vehicleStreetName: '',
      vehicleTown: '',
	  lookupOptions: '',
	  lookupFailedMessage: '',
	  items: [],
		addresses: null

    };
	this.onLookupClick = this.onLookupClick.bind(this);
	this.lookupPostcodeChanged = this.lookupPostcodeChanged.bind(this);
	this.vehiclePostcodeChanged = this.vehiclePostcodeChanged.bind(this);
	this.vehicleHouseNumberChanged = this.vehicleHouseNumberChanged.bind(this);
	this.vehicleStreetNameChanged = this.vehicleStreetNameChanged.bind(this);
	this.vehicleTownChanged = this.vehicleTownChanged.bind(this);
	this.selectchange = this.selectchange.bind(this);
  }

  lookupPostcodeChanged(fieldId, value) {
    this.setState({lookupPostcode: value});
  }

  vehiclePostcodeChanged(fieldId, value) {
    this.setState({vehiclePostcode: value});
  }

  vehicleHouseNumberChanged(fieldId, value) {
    this.setState({vehicleHouseNumber: value});
  }

  vehicleStreetNameChanged(fieldId, value) {
    this.setState({vehicleStreetName: value});
  }

  vehicleTownChanged(fieldId, value) {
    this.setState({vehicleTown: value});
  }
  
  selectchange(e)
  {
	  console.log(e.target.value);
	  
	  let address = this.state.addresses[e.target.value];
	  
	  var visible_address = address.building_number;
	  if (visible_address.length == 0)
	  {
		  visible_address = address.building_name;
	  }
	  
      this.setState({vehicleHouseNumber: visible_address});
      this.setState({vehicleStreetName: address.thoroughfare});
      this.setState({vehicleTown: address.post_town});
      this.setState({vehiclePostcode: address.postcode});

  }

  canSubmit() {
    return ((this.state.vehiclePostcode.length > 3) && (this.state.vehicleHouseNumber.length > 0) && (this.state.vehicleStreetName.length > 3) && (this.state.vehicleTown.length > 3));
  }
  
  canSubmitLookup() {
	  return true;
    //return (this.state.lookupPostcode.length > 3);
  }
  
  createSelectItems() {
	  let theApp = this;
	this.props.api.postcodeLookup(this.state.lookupPostcode, function done(addresses) {
      if (addresses) {
		  console.log(addresses);
        const nextState = Object.assign({}, theApp.state, {addresses: addresses});
        theApp.setState(nextState);
		theApp.state.items.push(<option key='0' value='0'>-- Select address --</option>);   
		for (var p = 0; p < theApp.state.addresses.length; p ++) {
		  let address = theApp.state.addresses[p];
		  
		  var visible_address = address.building_name;
		  if (visible_address.length > 0)
		  {
			  visible_address = visible_address + ", ";
		  }
		  
		  visible_address = visible_address + address.building_number;
		  if (address.building_number > 0)
		  {
			  visible_address = visible_address + ", ";
		  }

		  visible_address = visible_address + address.thoroughfare;
		  theApp.state.items.push(<option key={p} value={p}>{visible_address}</option>);   
		}
			theApp.setState({lookupFailedMessage : ''});
			theApp.setState({lookupOptions : <select onChange={(e) => theApp.selectchange(e)}>{theApp.state.items}</select>});
			console.log(theApp.state.lookupOptions);
      }
	  else
	  {
		  theApp.setState({lookupFailedMessage : <span><br />We're sorry we cannot find an address using that postcode, please enter the repair address manually.</span>});
	  }
    }, function notFound() {
		theApp.setState({lookupFailedMessage : <span><br />We're sorry we cannot find an address using that postcode, please enter the repair address manually.</span>});
    }, function fail(xhr) {
      console.log(xhr);
      theApp.setState({lookupFailedMessage : <span><br />We're sorry we cannot find an address using that postcode, please enter the repair address manually.</span>});
    });	 
 }  
  
  onDropdownSelected(e) {
    console.log("THE VAL", e.target.value);
    //here you will see the current selected value of the select input
}

	onLookupClick(){
		this.setState({lookupOptions: ""});
		this.setState({addresses: []});
		this.setState({items: []});
		
		this.createSelectItems();
	}

  render() {
    return (
      <View>
        <ScreenHeading text="Alloy Claim" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  Please provide the postcode of your preferred repair location.
			</Para>
			<LabeledTextField label="Postcode" id="lookupPostcode" onChange={this.lookupPostcodeChanged} value={this.state.lookupPostcode}/>
			<ConditionalButton text="LOOK UP" condition={() => this.canSubmitLookup()} onClick={this.onLookupClick}>
			LOOK UP
			</ConditionalButton>
			<View id='lookup-select'>
			{ this.state.lookupOptions }
			</View>
			<Para>Or provide the address here:</Para>
			<View id='lookup-message'>
			{ this.state.lookupFailedMessage }
			</View>
			<LabeledTextField label="House number/name" id="houseNumber" onChange={this.vehicleHouseNumberChanged} value={this.state.vehicleHouseNumber}/>
			<LabeledTextField label="Street name" id="streetName" onChange={this.vehicleStreetNameChanged} value={this.state.vehicleStreetName}/>
			<LabeledTextField label="Town/City" id="towncity" onChange={this.vehicleTownChanged} value={this.state.vehicleTown}/>
			<LabeledTextField label="Postcode" id="postcode" onChange={this.vehiclePostcodeChanged} value={this.state.vehiclePostcode}/>
			<Bottom>
			  <ConditionalButton text="Submit" condition={() => this.canSubmit()} onClick={() => this.props.onContinue(this.state.vehiclePostcode, this.state.vehicleHouseNumber + ', ' + this.state.vehicleStreetName)}/>
			</Bottom>
		</View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default AlloyLocation;
