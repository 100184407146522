import React from 'react';
import Another from '../../components/Another';

function CancellationAnother(props) {
  return (
    <Another
      heading="Cancellation"
      doAnother="cancel another product"
      onYes={props.onYes}
	  goBack={props.goBack}
      onNo={props.onNo}/>
  );
}

export default CancellationAnother;
