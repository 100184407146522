import React, { useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Para from '../components/Para'
import Styles from '../../constants/Styles'
import VerticalSpace from '../components/VerticalSpace'
import ScreenHeading from '../components/ScreenHeading'
import LabeledTextField from '../components/LabeledTextField'
import store, { RootState } from '../../redux/Store'
import { setPostcode, setRegistrationNumber, setSurname } from '../../redux/DetailsSlice'
import ConditionalButton from '../components/ConditionalButton'

interface StandardSignInProps {
  navigation: any,
  onContinue:Function,
  goHome: Function,
  goBack: Function,
}

export default function StandardSignIn ({ navigation, onContinue, goHome, goBack }: StandardSignInProps) {
  const [buttonText, setButtonText] = useState('Continue')
  const details = useSelector((state: RootState) => state.details.value)

	useEffect(() => {
		AsyncStorage.getItem('details.surname').then((value) => {
			if (value != null)
			updateSurname(value)
		})
		AsyncStorage.getItem('details.registrationNumber').then((value) => {
			if (value != null)
			updateRegistrationNumber(value)
		})
		AsyncStorage.getItem('details.postcode').then((value) => {
			if (value != null)
			updatePostcode(value)
		})

	}, [])

  function canContinue () {
    return details.registrationNumber !== '' && details.surname !== '' && buttonText === 'Continue'
  }
  
  function doContinue() {
    setButtonText('Please Wait...');
    onContinue(details.registrationNumber, details.postcode, details.surname, () => onFail());
  }
  
  function onFail() {
    setButtonText('Continue');
  }

  async function updateSurname (text: string) {
    store.dispatch(setSurname(text))
    await AsyncStorage.setItem('details.surname', text)
  }

  async function updateRegistrationNumber (text: string) {
    store.dispatch(setRegistrationNumber(text))
    await AsyncStorage.setItem('details.registrationNumber', text)
  }

  async function updatePostcode (text: string) {
    store.dispatch(setPostcode(text))
    await AsyncStorage.setItem('details.postcode', text)
  }

  return (
    <View>
      <ScreenHeading text="Agreement Search" goBack={goBack}/>
      <VerticalSpace height={15} />
      <View style={styles.container}>
	    <Para>Let's find your agreement(s).</Para>
        <Para>
          Please complete your information below. 
        </Para>
        <LabeledTextField placeholder="Required" label="Registration number*" value={details.registrationNumber} onChange={updateRegistrationNumber}/>
        <LabeledTextField placeholder="Required" label="Postcode*" value={details.postcode} onChange={updatePostcode}/>
        <LabeledTextField placeholder="Required" label="Surname*" value={details.surname} onChange={updateSurname}/>
	    <Para>* mandatory</Para>
        <VerticalSpace height={15} />
        <ConditionalButton onClick={doContinue} text={buttonText} condition={canContinue}/>
        <VerticalSpace height={15}/>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
