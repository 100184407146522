import React from 'react';
import ButtonList from '../../components/ButtonList';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

function AlloyDifferentReferred(props) {
  return (
    <View>
      <ScreenHeading text="Alloy Claim" goBack={props.goBack}/>
		<View style={styles.container}>
			  <Para>
				Thank you for submitting your claim/claims, you will shortly receive an automated email confirming the information supplied
			  </Para>
			  <Para>
			  There is no need to call us as we will review the information and images you have sent within the next two working days and if we require anything further will contact you directly.
			</Para>

			  <Para>
				Finally, do you wish to submit a claim for another product?
			  </Para>

			  <ButtonList>
				<View style={Styles.optionsButton}><DefaultButton text="Yes" onClick={() => props.onYes()}/></View>
				<View style={Styles.optionsButton}><DefaultButton text="No" onClick={() => props.onNo()}/></View>
			  </ButtonList>
		</View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default AlloyDifferentReferred;
