import React from 'react';
import DefaultButton from './DefaultButton';
import PhotoPreviewManage from './PhotoPreviewManage';
import PhotosBadgeSelected from './PhotosBadgeSelected';
import { Image, StyleSheet, View, TouchableOpacity } from 'react-native'

import popupclosebutton from '../icon-close2.png'

function PhotoPreviewWithDeleteNew(props) {
  return (
    
    <View className="PhotoPreviewWithDeleteNew" style={{marginBottom: 1, fontSize: 8, width: '50%', marginBottom: 8, textAlign: 'center', marginTop: 40}}>
		<TouchableOpacity onPress={() => props.onDelete(props.index)} style={{ position: 'absolute', zIndex: 99, top: -35, left: 0, height: 70, width: 70, paddingTop: 0, marginTop: 40}}>
			<Image className="popupclosebuttonnew" source={popupclosebutton} alt="Delete" style={{ zIndex: 99, height: 70, width: 70 }} />
		</TouchableOpacity>
		<PhotoPreviewManage data={props.data}/>
    </View>
  );
}

export default PhotoPreviewWithDeleteNew;
