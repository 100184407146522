import React, { useEffect, useState } from 'react'
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import * as ImagePicker from 'expo-image-picker'
import OptionsMenu from "react-native-options-menu";
import store, { RootState } from '../../../redux/Store'
import { addPhoto, clearPhotos } from '../../../redux/SmartClaimSlice'
import NavigationBar from '../../components/NavigationBar'
import Bottom from '../../components/Bottom'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import Styles from '../../../constants/Styles'
import ConditionalButton from '../../components/ConditionalButton'
import MultiplePhotoUploadTogether from '../../components/MultiplePhotoUploadTogether';
import { useSelector } from 'react-redux'


interface SmartPhotosProps {
  navigation: any
  onContinue: Function
  goHome: Function
  goBack: Function
}

export default function SmartPhotos ({ navigation, onContinue, goHome, goBack }: SmartPhotosProps) {
  const images = useSelector((state: RootState) => state.smartClaim.value.allPhotos)
  const [managingPhotos, setManagingPhotos] = useState(false)
  const [errordisplay, setErrorDisplay] = useState('none')

  const emptyFunction = () => {}
  const canContinue = () => images.length > 1
  const canUpload = () => {
    images.length < 2
      ? pickImage()
      : alert('You have already uploaded 2 photos. Select Manage Photos to view or remove uploaded photos.')
  }
  const canTake = () => {
    images.length < 2
      ? takePhoto()
      : alert('You have already uploaded 2 photos. Select Manage Photos to view or remove uploaded photos.')
  }
  
  function doContinue()
  {
	  onContinue(images)
  }

  function addPhotoWithFile(acceptedFiles) {
	for (var i = 0; i < acceptedFiles.length; i++) {
		onDoneDraggingPhoto(acceptedFiles[i]);
	}
  }

  function onDoneDraggingPhoto(photo) {
		const reader = new FileReader()	
		reader.onload = () => {
      // Do whatever you want with the file contents
        const binaryStr = reader.result
		store.dispatch(addPhoto(binaryStr))
      }

      reader.readAsDataURL(photo);
	  
}

  function onDoneAddingPhotos(photo) {
	store.dispatch(addPhoto(photo))
  }

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!')
        }
		else
		{
			const camstatus = await ImagePicker.requestCameraPermissionsAsync()
			if (camstatus.status !== 'granted') {
			  alert('Sorry, we need camera roll permissions to make this work!')
			}
		}
      }
		if (Platform.OS == "web")
		{
			var imagedropped = document.getElementById("imagedropped");
			
			if (imagedropped != null)
			{
				imagedropped.addEventListener('drop', function(e) {
				  e.preventDefault();
				  const dt = e.dataTransfer;
				  addPhotoWithFile(dt.files)
				  imagedropped.style.border = 'none';
				})
				imagedropped.addEventListener('dragover', function(e) {
				  e.preventDefault();
				  imagedropped.style.border = '4px dotted green';
				})
				imagedropped.addEventListener('dragleave', function(e) {
				  e.preventDefault();
				  imagedropped.style.border = 'none';
				})
			}
		}
    })()
  }, [managingPhotos])
  
  
  useEffect(() => {
    (async () => {
	  store.dispatch(clearPhotos())

    })()
  }, [])

  const pickImage = async () => {
    const result : any = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      base64: true,
      aspect: [4, 3],
      quality: 1
    })
    if (!result.cancelled) {
		if (isValidImage(result.assets[0].uri))
		{
      // check file type! (validation method)
			if (Platform.OS != "web")
			{
				store.dispatch(addPhoto('data:image/jpeg;base64,' + result.assets[0].base64))
				//store.dispatch(addPhoto(result.assets[0].uri))
				//console.log(result);
			}
			else
			{
				store.dispatch(addPhoto(result.assets[0].uri))
			}
			setErrorDisplay('none')
		}
		else {
	  setErrorDisplay('flex')
		}
    }
  }
  
  const takePhoto = async () => {
    const result : any = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      base64: true,
      aspect: [4, 3],
      quality: 1
    })
    if (!result.cancelled) {
		//console.log(result);
		if (isValidImage(result.uri))
		{
      // check file type! (validation method)
			if (Platform.OS != "web")
			{
				store.dispatch(addPhoto('data:image/jpeg;base64,' + result.assets[0].base64))
				//console.log(result.base64);
			}
			else
			{
				store.dispatch(addPhoto(result.assets[0].uri))
				//console.log(result.uri);
			}
			setErrorDisplay('none')
		}
		else {
	  setErrorDisplay('flex')
		}
    }
  }
  
  function isValidImage(dataURI){
	  return true;
	  var retVal = false;
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	
	if (mimeString == 'image/jpeg' || mimeString == 'image/png')
	{
		retVal = true;
	}
	return retVal;
  }
  
  const managePhotos = () => {
		//if (Platform.OS == "web")
		//{
			setManagingPhotos(true)
		//}
  }

  const managePhotosDone = (photos) => {
	  setManagingPhotos(false)
	  store.dispatch(clearPhotos())
		for (var i = 0; i < photos.length; i ++) {
		  store.dispatch(addPhoto(photos[i]))
		}
  }
  

    if (managingPhotos) {
      return(
        <MultiplePhotoUploadTogether heading="SMART Claim" photos={images} onDone={managePhotosDone}/>
      );
    } else if (1 == 2) {
      return (
        <View>
          <ScreenHeading text={this.props.heading} goBack={goBack}/>
		  <View style={styles.container}>
			  <Para>Please upload a {this.props.photo} photo.</Para>
			  <PhotoUpload data={null} onUpload={(data) => this.onPhotoUploaded(data)} onCancel={() => this.cancelAddPhoto()}/>
			</View>
        </View>
      );
    } else {
      return (
        <View className="SmartPhotos">
          <ScreenHeading text="SMART Claim" goBack={goBack}/>
		  <View style={styles.container}>
			  <Para>Nearly there, we just need you to upload some photos showing your vehicle's damage.</Para>
			  <Para bold={true}>WE REQUIRE <Text style={{color: '#ca011e'}}>2 PHOTOS</Text> TO SUCCESSFULLY PROCESS YOUR CLAIM.</Para>

				<View style={styles.rowContainer}>
				  <View style={styles.rowInnerContainer}>
					<View style={styles.imageContainer}>
					  <Image style={styles.rowImage} source={require('../../example-full-smart2.png')}></Image>
					</View>
					<Para centerAlign={true}>1 Close-Up</Para>
				  </View>
				 
				  <View style={styles.rowInnerContainer}>
					<View style={styles.imageContainer}>
					  <Image style={styles.rowImage} source={require('../../example-close-up-smart2.png')}></Image>
					</View>
					<Para centerAlign={true}>1 Further Away</Para>
				  </View>
				</View>


			<VerticalSpace height={15}/>
			
				  { Platform.OS == "web" ?
			
			<View style={styles.rowContainer}>
			  <TouchableOpacity onPress={canUpload} style={styles.rowInnerContainer} >
			  <div id="imagedropped">
				<View style={styles.imageContainer}>
					<Image style={styles.rowImage} source={require('../../take-photo2.png')}></Image>
				</View>
				<Para centerAlign={true}>UPLOAD/TAKE PHOTO</Para>
				<Para bold={false} color={'red'} display={errordisplay} centerAlign={true} fontSize={12}>Invalid file type, please upload a jpg or png file</Para>
			  </div>
			  </TouchableOpacity>
			 
			  <TouchableOpacity onPress={managePhotos} style={styles.rowInnerContainer}>
				<View style={styles.imageContainer}>
				  <Image style={styles.rowImage} source={require('../../manage-photos2.png')}></Image>
				  {/* extract this to own component? */}
				  <View style={{ alignSelf: 'flex-end', backgroundColor: canContinue() ? 'green' : 'red', borderRadius: 17, height: 34, justifyContent: 'center', right: 15, position: 'absolute', top: 20, width: 34 }}>
					<Para bold={true} centerAlign={true} color={ 'white' }>{images.length}</Para>
				  </View>
				</View>
				<Para centerAlign={true}>Manage photos</Para>
			  </TouchableOpacity>
			</View>
				  :
			<View style={styles.rowContainer}>
			  <TouchableOpacity onPress={pickImage} style={styles.rowInnerContainer} >
				<View style={styles.imageContainer}>
				   <OptionsMenu
					  button={require('../../take-photo2.png')}
					  buttonStyle={styles.rowImage}
					  destructiveIndex={1}
					  options={["Upload", "Take photo", "Cancel"]}
					actions={[canUpload, canTake, emptyFunction]}/>
				</View>
				<Para centerAlign={true}>UPLOAD/TAKE PHOTO</Para>
				<Para bold={false} color={'red'} display={errordisplay} centerAlign={true} fontSize={12}>Invalid file type, please upload a jpg or png file</Para>
			  </TouchableOpacity>
			 
			  <TouchableOpacity onPress={managePhotos} style={styles.rowInnerContainer}>
				<View style={styles.imageContainer}>
				  <Image style={styles.rowImage} source={require('../../manage-photos2.png')}></Image>
				  {/* extract this to own component? */}
				  <View style={{ alignSelf: 'flex-end', backgroundColor: canContinue() ? 'green' : 'red', borderRadius: 17, height: 34, justifyContent: 'center', right: 15, position: 'absolute', top: 20, width: 34 }}>
					<Para bold={true} centerAlign={true} color={ 'white' }>{images.length}</Para>
				  </View>
				</View>
				<Para centerAlign={true}>Manage photos</Para>
			  </TouchableOpacity>
			</View>

				  }				
				
			  <Bottom>
				<ConditionalButton text="Submit" condition={canContinue} onClick={doContinue}/>
			  </Bottom>
			</View>
        </View>
      );
    }
  }
/* componentDidMount () {
	if (Platform.OS == "web")
	{
		var imagedropped = document.getElementById("imagedropped");
		
		if (imagedropped != null)
		{
			const the_app = this;
			
			imagedropped.addEventListener('drop', function(e) {
			  e.preventDefault();
			  const dt = e.dataTransfer;
			  the_app.addPhotoWithFile(dt.files)
			  imagedropped.style.border = 'none';
			  imagedropped.style.width = '50%';
			})
			imagedropped.addEventListener('dragover', function(e) {
			  e.preventDefault();
			  imagedropped.style.border = '4px dotted green';
			  imagedropped.style.width = '49%';
			})
			imagedropped.addEventListener('dragleave', function(e) {
			  e.preventDefault();
			  imagedropped.style.border = 'none';
			  imagedropped.style.width = '50%';
			})
		}
	}
 }

 componentDidUpdate () {
	if (Platform.OS == "web")
	{
		var imagedropped = document.getElementById("imagedropped");
		
		if (imagedropped != null)
		{
			const the_app = this;
			
			imagedropped.addEventListener('drop', function(e) {
			  e.preventDefault();
			  const dt = e.dataTransfer;
			  the_app.addPhotoWithFile(dt.files)
			  imagedropped.style.border = 'none';
			  imagedropped.style.width = '50%';
			})
			imagedropped.addEventListener('dragover', function(e) {
			  e.preventDefault();
			  imagedropped.style.border = '4px dotted green';
			  imagedropped.style.width = '49%';
			})
			imagedropped.addEventListener('dragleave', function(e) {
			  e.preventDefault();
			  imagedropped.style.border = 'none';
			  imagedropped.style.width = '50%';
			})
		}
	}
 }*/

  function isValidImage(dataURI){
	  return true;
	  var retVal = false;
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	
	if (mimeString == 'image/jpeg' || mimeString == 'image/png')
	{
		retVal = true;
	}
	return retVal;
  }

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  imageContainer: {
    width: 150,
	height: 150,
	alignItems: 'center',
  },
  containedImage: {
    width: '100%',
    height: '100%'
  },
  imageOverlay: {
    position: 'relative',
    top: -35,
    width: '90%'
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
	width: '100%',
	paddingTop: 20,
  },
  rowImage: {
    height: 140,
    resizeMode: 'cover',
    width: 140
  },
  rowInnerContainer: {
    width: '45%',
	display: 'flex',
	alignItems: 'center',
	textAlignVertical: 'top',
  }
})

