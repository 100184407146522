import React, { Component } from 'react';
import ButtonList from '../../components/ButtonList';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class SmartDamageType extends Component {
  constructor() {
    super();
    this.handleChosenType = this.handleChosenType.bind(this);
  }

  render() {
    return (
      <View>
        <ScreenHeading text="SMART Claim" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  Please select the type of damage.
			</Para>
			<ButtonList>
			  <View style={Styles.optionsButton}><DefaultButton text="Scuff" onClick={() => this.handleChosenType('Scuff')}/></View>
			  <View style={Styles.optionsButton}><DefaultButton text="Chip" onClick={() => this.handleChosenType('Chip')}/></View>
			  <View style={Styles.optionsButton}><DefaultButton text="Dent" onClick={() => this.handleChosenType('Dent')}/></View>
			  <View style={Styles.optionsButton}><DefaultButton text="Dent with Broken Paint" onClick={() => this.handleChosenType('Dent with Broken Paint')}/></View>
			  <View style={Styles.optionsButton}><DefaultButton text="Scratch" onClick={() => this.handleChosenType('Scratch')}/></View>
			</ButtonList>
		  </View>
      </View>
    );
  }

  handleChosenType(value) {
    this.props.onChosen(value);
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default SmartDamageType;
