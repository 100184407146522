import React from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

function SmartNoAppointment(props) {
  return (
    <View>
        <ScreenHeading text="SMART Claim" goBack={props.goBack}/>
		<View style={styles.container}>
		  <Para>
			We are Sorry. 
		  </Para>
		  <Para>
			At this current time, we're unable to find you a suitable appointment.
		  </Para>
		  <Para>
			We have received your claim and a call back request has been sent to our repairer.
		  </Para>
		  <Para>
			You DO NOT need to contact us. A Shine! representative will call you within two working days.
		  </Para>

		  <Bottom>
			<DefaultButton text="RETURN TO MAIN SCREEN" onClick={props.onDone}/>
		  </Bottom>
		</View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default SmartNoAppointment;
