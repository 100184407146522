import React, { Component } from 'react';
import ButtonList from '../../components/ButtonList';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class AlloyWheelType extends Component {
  constructor() {
    super();
    this.handleChosenType = this.handleChosenType.bind(this);
  }

  render() {
    return (
      <View>
        <ScreenHeading text="Alloy Wheel Claim" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  Please select the type of wheel.
			</Para>
			<ButtonList>
			  <View style={Styles.optionsButton}><DefaultButton text="Diamond cut" onClick={() => this.handleChosenType('Diamond cut')}/></View>
			  <View style={Styles.optionsButton}><DefaultButton text="Painted" onClick={() => this.handleChosenType('Painted')}/></View>
			</ButtonList>
		</View>
      </View>
    );
  }

  handleChosenType(value) {
    this.props.onChosen(value);
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default AlloyWheelType;
