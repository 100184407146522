import React, { Component } from 'react';
import { StyleSheet, View, TextInput, Pressable, Text } from 'react-native'
//import { TextInput } from 'react-native-gesture-handler'
import Para from './Para'


class LabeledEditTextField extends Component {
  constructor(props) {
    super();
    this.state = {
      label: props.label,
      placeholder: props.placeholder,
	  value: props.value,
	  editing: false
    };
	this.textInputRef = React.createRef();	
	
    this.onEditClick = this.onEditClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  
  onChange(value)
  {
	  this.setState({value: value});
	  this.props.onChange(value);
  }

  render() {
	  return (
		<View>
		  <Para>{this.state.label}</Para>
		  <View style={{ flexDirection: "row"}}>
			  <View style={{ width: "70%", height: 40}}>
				<TextInput value={this.state.value} placeholder={this.state.placeholder} ref={this.textInputRef} style={styles.textInput} disabled={!this.state.editing} editable={this.state.editing} selectTextOnFocus={true} onChangeText={(value) => this.onChange(value)}/>
			  </View>
			  <View style={{ width: "30%", height: 40}}>
					  { this.state.editing ?
				  <Pressable style={styles.saveButton} onPress={this.onSaveClick}>
					  <Text style={styles.buttonText}>Save</Text>
				  </Pressable>
				  :
				  <Pressable style={styles.editButton} onPress={this.onEditClick}>
					  <Text style={styles.buttonText}>Edit</Text>
				  </Pressable>
					  }
			  </View>
		  </View>
		</View>
	  );
  }
  
  onEditClick()
  {
	  this.setState({ editing: true }, function() {
		//this.textInputRef.current.focus();
		//this.refs.textInputRef.focus();
	  });
  }
  
  onSaveClick(){
	  this.setState( {editing: false} );
	  this.props.onSave(this.props.value);
  };
  
}


const styles = StyleSheet.create({
  textInput: {
    borderWidth: 1,
    fontSize: 16,
    height: 35,
    paddingHorizontal: 4
  },
  buttonText: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  saveButton: {
    backgroundColor: '#27ad7a',
    borderRadius: 4,
    marginLeft: 10,
	height: 35,
	paddingTop: 5
  },
  editButton: {
    backgroundColor: '#312e49',
    borderRadius: 4,
    marginLeft: 10,
	height: 35,
	paddingTop: 5
  }
})

export default LabeledEditTextField;
