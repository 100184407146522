import React, { Component } from 'react';
import { View } from 'react-native'
import Options from './Options';
import TrustPilot from './TrustPilot';

import Warranty from '../apps/Warranty/Warranty';
import Alloy from '../apps/Alloy/Alloy';
import Gap from '../apps/Gap/Gap';
import Gapclaimed from '../apps/Gapclaimed/Gapclaimed';
import Smart from '../apps/Smart/Smart';
import Tyre from '../apps/Tyre/Tyre';
import Cancellation from '../apps/Cancellation/Cancellation';
import PersonalDetails from '../apps/PersonalDetails/PersonalDetails';
import VehicleDetails from '../apps/VehicleDetails/VehicleDetails';
import PolicyDocumentation from '../apps/PolicyDocumentation/PolicyDocumentation';

class MainMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentApp: '',
      policy: null,
	  policies: this.props.policies.policies,
	  existingClaimEmail: '',
	  existingClaimId: ''
    };

    this.handleStartApp = this.handleStartApp.bind(this);
  }

  handleStartApp(whichApp, policy = null, existingClaimEmail = '', existingClaimId = '') {
    this.setState({ currentApp: whichApp, policy: policy, existingClaimEmail: existingClaimEmail, existingClaimId: existingClaimId });
  }

  returnToMainMenu() {
    this.setState({currentApp: ''});
  }

  returnToWelcome() {
    this.setState({currentApp: 'trustpilot'});
  }

  render() {
    return (
      <View className="MainMenu">
        {this.screen()}
      </View>
    );
  }

  screen() {
    if (this.state.currentApp === "trustpilot") {
      return (<TrustPilot goBack={() => this.props.onDone()} />);
    } else if (this.state.currentApp === "smart") {
      return (<Smart api={this.props.api} policy={this.state.policy} onDoneNoMoreClaims={() => this.returnToWelcome()} onDone={() => this.returnToMainMenu()}/>);
    } else if (this.state.currentApp === "warranty") {
      return (<Warranty api={this.props.api} policy={this.state.policy} onDone={() => this.returnToMainMenu()}/>);
    } else if (this.state.currentApp === "alloy") {
      return (<Alloy api={this.props.api} policy={this.state.policy} onDoneNoMoreClaims={() => this.returnToWelcome()} onDone={() => this.returnToMainMenu()}/>);
    } else if (this.state.currentApp === "tyre") {
      return (<Tyre api={this.props.api} policy={this.state.policy} onDoneReturnToWelcome={() => this.returnToWelcome()} onDone={() => this.returnToMainMenu()}/>);
    } else if (this.state.currentApp === "gap") {
      return (<Gap details={this.props.details} api={this.props.api} policy={this.state.policy} onDone={() => this.returnToMainMenu()}/>);
    } else if (this.state.currentApp === "gapclaimed") {
      return (
	  <Gapclaimed details={this.props.details} api={this.props.api} policy={this.state.policy} existingClaimEmail={this.state.existingClaimEmail} existingClaimId={this.state.existingClaimId} onDone={() => this.returnToMainMenu()}/>
	  );
    } else if (this.state.currentApp === "cancellation") {
      return (<Cancellation policies={this.state.policies} onUpdatePolicies={this.props.onUpdatePolicies} api={this.props.api} onDone={() => this.returnToMainMenu()}/>);
    } else if (this.state.currentApp === "personalDetails") {
      return (<PersonalDetails api={this.props.api} policies={this.props.policies} onDone={() => this.returnToMainMenu()}/>);
    } else if (this.state.currentApp === "vehicleDetails") {
      return (<VehicleDetails api={this.props.api} policies={this.props.policies} onDone={() => this.returnToMainMenu()}/>);
    } else if (this.state.currentApp === "policyDocumentation") {
      return (<PolicyDocumentation api={this.props.api} policies={this.props.policies} onDone={() => this.returnToMainMenu()}/>);
    } else {
      return (<Options policies={this.state.policies} onStartApp={this.handleStartApp} goBack={() => this.props.goBack()}/>);
    }
  }
}

export default MainMenu;
