import React from 'react';
import Another from '../../components/Another';

function TyreAnother(props) {
  return (
    <Another
      heading="Tyre Claim"
      doAnother="submit another tyre claim"
      onYes={props.onYes}
	  goBack={props.goBack}
      onNo={props.onNo}/>
  );
}

export default TyreAnother;
