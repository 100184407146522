import React from 'react'
import { Text } from 'react-native'

type HeadingProps = {
  children: string
}

const Heading = ({ children }: HeadingProps) => {
  return <Text style={{ color: '#312e49', fontSize: 28, fontWeight: 'bold', marginBottom: 5, marginTop: 15 }}>{children}</Text>
}

export default Heading
