import React, { Component } from 'react';
import { StyleSheet, View, Text, Platform } from 'react-native'
import Para from '../components/Para'
import Styles from '../../constants/Styles'
import DateTimePickerModal from "react-native-modal-datetime-picker";
import DefaultButton from '../components/DefaultButton';
import VerticalSpace from '../components/VerticalSpace'

class DatePicker extends Component {
  constructor(props) {
    super();
    this.state = {
      date: props.date,
      formattedDate: props.date.toISOString().split('T')[0],
	  pickerVisible: false
    };
    this.setDayOfMonth = this.setDayOfMonth.bind(this);
    this.setMonth = this.setMonth.bind(this);
    this.setFullYear = this.setFullYear.bind(this);

    this.handleConfirm = this.handleConfirm.bind(this);
    this.hideDatePicker = this.hideDatePicker.bind(this);
    this.showDatePicker = this.showDatePicker.bind(this);
  }

  render() {
    return (
		<View>
		  <View className="DatePicker">
		  
		  <Para bold={true} style={{fontSize: 16, bold: true}}>Currently selected date: {this.state.date == null ? <Text>Select date</Text> : <Text>{this.state.date.getDate()} {(this.getMonthName())} {this.state.date.getFullYear()}</Text>}</Para>
		  <VerticalSpace height={15}/>
			  { Platform.OS == "web" ?
			  <View>
			  <input style={{borderWidth: 1, fontSize: 16, height: 35, paddingHorizontal: 4}} type='date' value={this.state.formattedDate} onChange={(e) => this.handlewebConfirm(e)} />
			  <VerticalSpace height={15}/>
			  </View>
			  :
			<View>
			  <DefaultButton onClick={this.showDatePicker} text={'Select new date'} />
			  <VerticalSpace height={15}/>
				<DateTimePickerModal
					isVisible={this.state.pickerVisible}
					mode="date"
					onConfirm={this.handleConfirm}
					onCancel={this.hideDatePicker}
				/>
			</View>
			  }
		  </View>
		</View>
    );
  }
  
  getMonthName()
  {
	  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec" ];
	  
	  return monthNames[this.state.date.getMonth()]
  }
  
	handleConfirm(newdate){
		this.setState( {pickerVisible: false, date: newdate} );
		this.props.onChange(newdate);
  };
  
  	handlewebConfirm(e){
		var parts = e.target.value.split('-');
		// Please pay attention to the month (parts[1]); JavaScript counts months from 0:
		// January - 0, February - 1, etc.
		var mydate = new Date(parts[0], parts[1] - 1, parts[2]);
		
		this.setState( {date: mydate, formattedDate: mydate.toISOString().split('T')[0]} );
		this.props.onChange(mydate);
  };


	hideDatePicker(){
    this.setState( {pickerVisible: false} );
  };

	showDatePicker(){
    this.setState( {pickerVisible: true} );
  };

  setDayOfMonth(event) {
    const oldDate = this.state.date;
    let date = new Date(
        oldDate.getFullYear(), oldDate.getMonth(), event.target.value
    );
    const nextState = Object.assign({}, this.state, {date: date});
    this.setState(nextState);

    this.props.onChange(date);
  }

  setMonth(event) {
    const oldDate = this.state.date;
    let date = new Date(
        oldDate.getFullYear(), event.target.value, oldDate.getDate()
    );
    const nextState = Object.assign({}, this.state, {date: date});
    this.setState(nextState);

    this.props.onChange(date);
  }

  setFullYear(event) {
    const oldDate = this.state.date;
    let date = new Date(
        event.target.value, oldDate.getMonth(), oldDate.getDate()
    );
    const nextState = Object.assign({}, this.state, {date: date});
    this.setState(nextState);

    this.props.onChange(date);
  }
}

const styles = StyleSheet.create({
  textInput: {
    borderWidth: 1,
    fontSize: 16,
    height: 35,
    paddingHorizontal: 4
  }
})

export default DatePicker;
