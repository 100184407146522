import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class AlloyIntro extends Component {
  render() {
    return (
      <View>
        <ScreenHeading text="Alloy Wheel Claim" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  Before submitting your claim please check your terms and conditions to confirm the damage fits within the limits described. These were supplied when you purchased the product/s.
			</Para>
			<Para>
				Then click continue.
			</Para>
			<Bottom>
			  <DefaultButton text="Continue" onClick={this.props.onContinue}/>
			</Bottom>
		</View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default AlloyIntro;
