import React from 'react';
import AlloyFail from './AlloyFail';
import Submitting from '../../components/Submitting';
import Para from '../../components/Para';
import Heading from '../../components/Heading';
import ScreenHeading from '../../components/ScreenHeading';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Styles from '../../../constants/Styles'
import * as Progress from 'react-native-progress';

function AlloySubmitting(props) {
  return (
    <View className="Submitting">
	  <ScreenHeading text={"Alloy Wheel Claim"} goBack={props.goBack}/>
		<View style={styles.container}>
		  <Heading>Submitting...</Heading>
		  <Para>
			Please wait while your claim is being submitted.
		  </Para>
			<Para bold={true}>Upload progress:</Para>
			<Para>Full wheel photo: ({Math.floor(props.fullWheelPhotoUploadProgress*100).toString()}%)</Para>
			<Progress.Bar progress={props.fullWheelPhotoUploadProgress} width={200} />
			<Para>Close up photo: ({Math.floor(props.closeUpPhotoUploadProgress*100).toString()}%)</Para>
			<Progress.Bar progress={props.closeUpPhotoUploadProgress} width={200} />
				{props.photoUploadFailed == false ? null :
					<AlloyFail onRetry={props.onRetry} />
				}
		</View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})


export default AlloySubmitting;
