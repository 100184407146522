import React from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

function SmartIntro(props) {
  return (
    <View>
      <ScreenHeading text="SMART Claim" goBack={props.goBack}/>
	  <View style={styles.container}>
			<Para>
			  I confirm that all the details provided within this claim are in-line with my policy terms and conditions 
			</Para>
			<Para>
			  Note, providing inaccurate information may result in a delay in your claim.
			</Para>
			<Para>
			  By continuing you promise the information provided is true and correct.
			</Para>
		  <Para>
			Before submitting your claim please check your terms and conditions to confirm the damage fits within the size (cms) and limits described. These were supplied when you purchased the product/s.
		  </Para>
		  <Para>
			Then click continue.
		  </Para>
		  <Bottom>
			<DefaultButton text="Continue" onClick={props.onContinue}/>
		  </Bottom>
		</View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default SmartIntro;
