import React, {Component} from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Heading from '../../components/Heading'
import Styles from '../../../constants/Styles'

class SmartFail extends Component {
  render() {
    return(
      <View>
			<Heading>Error</Heading>
			<Para>
			  Unfortunately there was an error
			  submitting your claim.
			</Para>
			<Bottom>
			  <DefaultButton text="Try Again" onClick={this.props.onRetry}/>
			</Bottom>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default SmartFail;
