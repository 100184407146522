import React, { Component } from 'react';
import TyreSplash from './TyreSplash';
import TyreIntro from './TyreIntro';
import TyreDate from './TyreDate';
import TyreDamageType from './TyreDamageType';
import TyreChoose from './TyreChoose';
import TyrePhotos from './TyrePhotos';
import TyreSubmitting from './TyreSubmitting';
import TyreAnother from './TyreAnother';
import TyreDifferent from './TyreDifferent';
import TyreThanks from './TyreThanks';
import TyreFail from './TyreFail';
import TyreDrivable from './TyreDrivable';

class Tyre extends Component {
  constructor() {
    super();

    this.state = this.freshState();

    this.handleIntroContinue = this.handleIntroContinue.bind(this);
    this.handleChosenDate = this.handleChosenDate.bind(this);
    this.handleChosenDamageType = this.handleChosenDamageType.bind(this);
    this.handleChosenIsDrivable = this.handleChosenIsDrivable.bind(this);
    this.handleChosenTyre = this.handleChosenTyre.bind(this);
    this.handlePhotoUploads = this.handlePhotoUploads.bind(this);
    this.uploadProgress = this.uploadProgress.bind(this);
    this.retryPhotos = this.retryPhotos.bind(this);
    this.apiFail = this.apiFail.bind(this);
  }

  freshState() {
    return {
      stage: 'splash',
      chosenTyre: '',
      isDrivable: '',
      date: new Date(),
      damageType: null,
      fullWheelPhotos: [],
      closeUpPhotos: [],
      treadPhotos: [],
      claimId: null,
	  fullWheelPhotoUploadProgress: 0,
	  closeUpPhotoUploadProgress: 0,
	  treadPhotoUploadProgress: 0,
	  photoUploadFailed: false,
    };
  }

  handleIntroContinue() {
    this.setState({stage: 'chooseDate'});
  }

  handleChosenDate(date) {
    this.setState({stage: 'chooseDamageType', date: date});
  }

  handleChosenDamageType(damageType) {
    this.setState({stage: 'chooseTyre', damageType: damageType});
  }

  handleChosenIsDrivable(isDrivable) {
    var nextState = Object.assign({}, this.state, {stage: 'submitting', isDrivable: isDrivable});
    this.setState(nextState);

    this.submitClaim(nextState);
  }

  handleChosenTyre(whichTyre) {
    var nextState = Object.assign({}, this.state, {stage: 'photos', chosenTyre: whichTyre});
    this.setState(nextState);
  }

  handlePhotoUploads(fullWheelPhotos, closeUpPhotos, treadPhotos) {
    const nextState = Object.assign(
      {}, this.state, {
        stage: 'isdrivable',
        fullWheelPhotos: fullWheelPhotos,
        closeUpPhotos: closeUpPhotos,
        treadPhotos: treadPhotos
      }
    );
    this.setState(nextState);
  }

  startAgain() {
    var state = this.freshState();
    state.stage = 'intro';
    this.setState(state);
  }

  retryPhotos()
  {
	  //this.setState({'photoUploadFailed': false, 'stage': 'photos'})
	  const nextState = Object.assign(
      {}, this.state, {
        photoUploadFailed: false
      }
    );
	  
	  this.setState(nextState)
      this.submitClaim(nextState);
  }

  render() {
    if (this.state.stage === 'splash') {
      return <TyreSplash onContinue={() => this.setState({stage: 'intro'})} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'intro') {
      return <TyreIntro onContinue={this.handleIntroContinue} goBack={() => this.setState({stage: 'splash'})}/>;
    } else if (this.state.stage === 'chooseDate') {
	return <TyreDate onSubmit={this.handleChosenDate} date={this.state.date} goBack={() => this.setState({stage: 'intro'})}/>;
    } else if (this.state.stage === 'chooseDamageType') {
      return <TyreDamageType onChosen={this.handleChosenDamageType} goBack={() => this.setState({stage: 'chooseDate'})}/>;
    } else if (this.state.stage === 'chooseTyre') {
      return <TyreChoose onChosen={this.handleChosenTyre} goBack={() => this.setState({stage: 'chooseDamageType'})}/>;
    } else if (this.state.stage === 'photos') {
      return <TyrePhotos chosenTyre={this.state.chosenTyre} onContinue={this.handlePhotoUploads} goBack={() => this.setState({stage: 'chooseTyre'})}/>;
    } else if (this.state.stage === 'isdrivable') {
      return <TyreDrivable onContinue={this.handleChosenIsDrivable} goBack={() => this.setState({stage: 'photos'})}/>;
    } else if (this.state.stage === 'submitting') {
      return <TyreSubmitting fullWheelPhotoUploadProgress={this.state.fullWheelPhotoUploadProgress} closeUpPhotoUploadProgress={this.state.closeUpPhotoUploadProgress}  treadPhotoUploadProgress={this.state.treadPhotoUploadProgress} photoUploadFailed={this.state.photoUploadFailed} onRetry={this.retryPhotos} />;
    } else if (this.state.stage === 'another') {
      return <TyreAnother onYes={() => this.startAgain()} onNo={() => this.setState({stage: 'different'})} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'different') {
      return <TyreDifferent onYes={() => this.props.onDone()} onNo={() => this.props.onDoneReturnToWelcome()} goBack={() => this.props.onDone()}/>;
    /*} else if (this.state.stage === 'different') {
      return <TyreDifferent onYes={() => this.props.onDone()} onNo={() => this.setState({stage: 'thanks'})}/>;
    } else if (this.state.stage === 'thanks') {
      return <TyreThanks onDone={() => this.props.onDone()}/>;*/
    } else if (this.state.stage === 'fail') {
      return <TyreFail onRetry={() => this.setState({'stage': 'intro'})} goBack={() => this.props.onDone()}/>;
    }
  }

  submitClaim(state) {
    const component = this;
    this.props.api.tyreClaim(
      this.props.policy,
      state.damageType,
      state.chosenTyre,
      state.isDrivable,
      state.date,
      function done(data) {
        state.claimId = data.claim_id;
        component.setState(state);
        component.submitPhotos(state);
      },
      component.apiFail
    );
  }

  apiFail(xhr) {
    this.setState({photoUploadFailed: true});
  }

  submitPhotos(state) {
    const component = this;
    const photos =
      state.fullWheelPhotos.map(function(p) { return {type: 'full wheel', data: p}; }).concat(
        state.closeUpPhotos.map(function(p) { return {type: 'close up', data: p}; }).concat(
          state.treadPhotos.map(function(p) { return {type: 'tread', data: p}; })
        )
      );
    component.props.api.uploadPhotos(
      component.props.api.tyrePhoto,
      state.claimId,
      photos,
      function done() { component.setState({stage: 'another'}) },
      component.apiFail,
	  component.uploadProgress
    );
  }

  uploadProgress(photoType, loaded, total)
  {
	  let pc = loaded / total
	  if (photoType == "full wheel")
	  {
		  this.setState({fullWheelPhotoUploadProgress: pc})
	  }
	  else if (photoType == "tread")
	  {
		  this.setState({treadPhotoUploadProgress: pc})
	  }
	  else
	  {
		  this.setState({closeUpPhotoUploadProgress: pc})
	  }
  }
}

export default Tyre;
