import React, { Component } from 'react';
import Bottom from './Bottom';
import ButtonList from './ButtonList';
import ConditionalButton from './ConditionalButton';
import DefaultButton from './DefaultButton';
import PhotoPreview from './PhotoPreview';
import Para from './Para'
import * as ImagePicker from 'expo-image-picker'
import $ from 'jquery';
import { StyleSheet, View, Text, Pressable, Platform } from 'react-native'
import Styles from '../../constants/Styles'
import OptionsMenu from "react-native-options-menu";
import VerticalSpace from './VerticalSpace'

class PhotoUpload extends Component {
  constructor(props) {
    super();

    this.state = {
      data: props.data,
	  errordisplay: 'none'
    };
	
	this.pickImage = this.pickImage.bind(this);
	this.takePhoto = this.takePhoto.bind(this);
  }

  fileChanged(event) {
	var ext = document.getElementById('fileUpload').value.split('.').pop().toLowerCase();
	if($.inArray(ext, ['png','jpg','jpeg']) == -1) {
            document.getElementById('fileTypeError').style.display = "block";
			this.state.data = null;
            this.value='';
			return;
	}
	
    let component = this;
    $.each(event.target.files, function(index, file) {
      var reader = new FileReader();
      reader.onload = function(event) {
        component.setState({data: event.target.result});
      };
      reader.readAsDataURL(file);
    });
  }
  
  async pickImage() {
	let component = this;
    const result : any = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      base64: true,
      aspect: [4, 3],
      quality: 1
    })
    if (!result.cancelled) {
		if (isValidImage(result.assets[0].uri))
		{
      // check file type! (validation method)
			//if (Platform.OS != "web")
			{
				//store.dispatch(addPhoto('data:image/jpeg;base64,' + result.base64))
				component.setState({data: 'data:image/jpeg;base64,' + result.assets[0].base64});
			}
			/*else
			{
//				store.dispatch(addPhoto(result.uri))
				component.setState({data: result.assets[0].uri});

			}*/
			component.setErrorDisplay('none')
		}
		else {
	  component.setErrorDisplay('flex')
		}
    }
  }
  
  setErrorDisplay(value)
  {
	  this.setState({errordisplay: value});
  }
  
  async takePhoto() {
	let component = this;
    const result : any = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      base64: true,
      aspect: [4, 3],
      quality: 1
    })
    if (!result.cancelled) {
		//console.log(result);
		if (isValidImage(result.assets[0].uri))
		{
      // check file type! (validation method)
			if (Platform.OS != "web")
			{
				component.setState({data: 'data:image/jpeg;base64,' + result.assets[0].base64});
				//store.dispatch(addPhoto('data:image/jpeg;base64,' + result.base64))
				//console.log(result.base64);
			}
			else
			{
				component.setState({data: result.assets[0].uri});
				//store.dispatch(addPhoto(result.uri))
				//console.log(result.uri);
			}
			component.setErrorDisplay('none')
		}
		else {
	  component.setErrorDisplay('flex')
		}
    }
  }
  
  emptyFunction()
  {
	  
  }
  
  render() {
	const uploadButton = ( <View style={styles.defaultButton} ><Text style={styles.buttonText}>{'Select File'}</Text></View>)
    return(
      <View className="PhotoUpload">
	  { Platform.OS == "web" ?
		<Pressable style={styles.defaultButton} onPress={this.pickImage}>
		  <Text style={styles.buttonText}>{'Select File'}</Text>
		</Pressable>
	  :
        <View style={Styles.optionsButton}>
               <OptionsMenu
				  customButton={uploadButton}
				  buttonStyle={styles.defaultButton}
				  destructiveIndex={1}
				  options={["Upload", "Take photo", "Cancel"]}
				  actions={[this.pickImage, this.takePhoto, this.emptyFunction]}/>
        </View>
	  }
		<Para bold={false} color={'red'} display={this.state.errordisplay} centerAlign={true} fontSize={12}>Only jpg or png files can be uploaded</Para>
        <View style={Styles.rowContainer}>
			<PhotoPreview data={this.state.data}/>
        </View>
		<VerticalSpace height={30} />
        <Bottom>
          <ButtonList>
            <View style={Styles.optionsButton}>
              <ConditionalButton text="Use Photo" condition={() => this.state.data} onClick={() => this.props.onUpload(this.state.data)}/>
            </View>
            <View style={Styles.optionsButton}>
              <DefaultButton text="Cancel" onClick={() => this.props.onCancel()}/>
            </View>
          </ButtonList>
        </Bottom>
      </View>
    );
  }
}

  function isValidImage(dataURI){
	  return true;
	  var retVal = false;
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	
	if (mimeString == 'image/jpeg' || mimeString == 'image/png')
	{
		retVal = true;
	}
	return retVal;
  }


const styles = StyleSheet.create({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
	width: '100%'
  },
  buttonText: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  defaultButton: {
    backgroundColor: '#312e49',
    borderRadius: 4,
    padding: 10
  }
})

export default PhotoUpload;
