import React, { Component } from 'react';
import ConditionalButton from '../../components/ConditionalButton';
import LabeledTextField from '../../components/LabeledTextField';
import LabeledCheckboxField from '../../components/LabeledCheckboxField';
import ScreenHeading from '../../components/ScreenHeading';
import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class CancellationBankDetails extends Component {
  constructor(props) {
    super();

    this.state = {
      accountName: '',
      sortCode: '',
      accountNumber: '',
	  confirmed: false,
      submitting: false
    };
	
    this.handleAccountNameChange = this.handleAccountNameChange.bind(this);
    this.handleSortCodeChange = this.handleSortCodeChange.bind(this);
    this.handleAccountNumberChange = this.handleAccountNumberChange.bind(this);
  }

  handleAccountNameChange(value) {
    var nextState = Object.assign({}, this.state);
    nextState.accountName = value;
    this.setState(nextState);
  }

  handleSortCodeChange(value) {
    var nextState = Object.assign({}, this.state);
    nextState.sortCode = value;
    this.setState(nextState);
  }

  handleAccountNumberChange(value) {
    var nextState = Object.assign({}, this.state);
    nextState.accountNumber = value;
    this.setState(nextState);
  }

  toggleCheckbox(event) {
    let newValue = (this.state.confirmed === "true" || this.state.confirmed === true) ? false : true;
    this.setState({
      confirmed: newValue
    });
  }

  render() {
	  if (this.props.policy.currencyCode == 'GBP') {
    return (
      <View>
        <ScreenHeading text="Cancellation" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  Please enter your bank details to allow us to process your refund.
			</Para>
			<Para>
			We will contact you within two working days if we require further information. Otherwise, your refund will be processed in three to five working days.
			</Para>
			<LabeledTextField label="Account name" id="accountName" onChange={this.handleAccountNameChange} value={this.state.accountName}/>
			<LabeledTextField label="Sort code" id="sortCode" onChange={this.handleSortCodeChange} value={this.state.sortCode}/>
			<LabeledTextField label="Account number" id="accountNumber" onChange={this.handleAccountNumberChange} value={this.state.accountNumber}/>
			<LabeledCheckboxField label="I confirm that the bank details are correct and my refund should be paid to this account." id="confirmed" onChange={this.toggleCheckbox.bind(this)} value={this.state.confirmed} />
			<ConditionalButton text={this.submitText()} condition={(e) => this.canSubmit()} onClick={(e) => this.submitBankDetails()}/>
		</View>
      </View>
    );
	}
	else
	{
    return (
      <View>
        <ScreenHeading text="Cancellation" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  Please enter your bank details to allow us to process your refund.
			</Para>
			<Para>
			We will contact you within two working days if we require further information. Otherwise, your refund will be processed in three to five working days.
			</Para>
			<LabeledTextField label="Account name" id="accountName" onChange={this.handleAccountNameChange} value={this.state.accountName}/>
			<LabeledTextField label="BIC" id="sortCode" onChange={this.handleSortCodeChange} value={this.state.sortCode}/>
			<LabeledTextField label="IBAN" id="accountNumber" onChange={this.handleAccountNumberChange} value={this.state.accountNumber}/>
			<LabeledCheckboxField label="I confirm that the bank details are correct and my refund should be paid to this account." id="confirmed" onChange={this.toggleCheckbox.bind(this)} value={this.state.confirmed} />
			<ConditionalButton text={this.submitText()} condition={(e) => this.canSubmit()} onClick={(e) => this.submitBankDetails()}/>
		</View>
      </View>
    );
	}
  }

  canSubmit() {
    return (this.state.accountName && this.state.sortCode && this.state.accountNumber && this.state.confirmed && !this.state.submitting);
	//return true;
  }

  submitBankDetails() {
    const component = this;
	
	this.setState({submitting: true});

    this.props.api.cancellationBankDetails(
      this.props.cancellationId,
      this.state.accountName,
      this.state.sortCode,
      this.state.accountNumber,
      function done() {
        component.props.onSuccess();
      },
      function fail(xhr) {
        console.log(xhr);
      }
    );
  }

  submitText() {
    if (this.state.submitting) {
      return 'Please Wait...';
    } else {
      return 'Submit';
    }
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})

export default CancellationBankDetails;
