import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native'
import Bottom from '../components/Bottom';
import ButtonList from '../components/ButtonList';
import ConditionalButton from '../components/ConditionalButton';
import DefaultButton from '../components/DefaultButton';
import Para from '../components/Para'
import LabeledTextField from '../components/LabeledTextField';
import ScreenHeading from '../components/ScreenHeading';
import VerticalSpace from '../components/VerticalSpace'
import Styles from '../../constants/Styles'

class PolicySignIn extends Component {
  constructor(props) {
    super();
    this.state = {
      buttonText: 'Continue',
      policyNumber: props.policyNumber || ''
    }
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.canContinue = this.canContinue.bind(this);
    this.continue = this.continue.bind(this);
  };

  handleFieldChange(value) {
    this.setState({policyNumber: value});
  }

  render() {
    return (
      <View>
		<ScreenHeading text="Sorry" goBack={this.props.goBack}/>
		<VerticalSpace height={15} />
		<View style={styles.container}>
			<Para>
			  Hmmm; we don't seem to have a record on file against the details you've given, but don't worry. Please enter your agreement number (just the numbers – no letters) in the box below and tap 'Continue'. If you don't have that number, please tap 'No Agreement Number'.
			</Para>
			<LabeledTextField label="Policy number" id="policyNumber" onChange={this.handleFieldChange} value={this.state.policyNumber}/>
			<Bottom>
			  <ButtonList>
				<View style={Styles.optionsButton}><ConditionalButton text={this.state.buttonText} condition={this.canContinue} onClick={this.continue}/></View>
				<View style={Styles.optionsButton}><DefaultButton text="No Agreement Number" onClick={() => this.props.onNoAgreementNumber()}/></View>
			  </ButtonList>
			</Bottom>
		  </View>
      </View>
    );
  }

  canContinue() {
    return this.state.policyNumber && this.state.buttonText === 'Continue';
  }

  continue() {
    this.setState({buttonText: 'Please Wait...'});
    this.props.onContinue(this.state.policyNumber, () => this.onFail());
  }

  onFail() {
    this.setState({buttonText: 'Continue'});
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default PolicySignIn;
