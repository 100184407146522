import React, { Component } from 'react';
import ScreenHeading from '../../components/ScreenHeading';
import CancellationRefundAmount from './CancellationRefundAmount';
import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class CancellationAmountConfirmation extends Component {
  render() {
	  
		return (
		  <View>
		  <ScreenHeading text="Cancellation" goBack={this.props.goBack}/>
			<CancellationRefundAmount policy={this.props.policy} date={this.props.date} api={this.props.api} onDone={() => this.props.onDone()} onSubmit={() => this.props.onSubmit()}/>
		  </View>
		);
  }
}

export default CancellationAmountConfirmation;
