import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';
import ButtonList from '../../components/ButtonList';
import waitingimg from '../../loading-png-gif.gif';

import { StyleSheet, View, Text, Image } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class AlloyVehicleLocationStatement extends Component {
  constructor(props) {
    super();
    this.state = {
	  loading: true,
    };
	
	let theApp = this;
    setTimeout(function () {
		
		theApp.setState({loading: false});


    }, 5000);  }
  
  
  render() {
		if (this.state.loading == true)
		{
			return (
			  <View>
				<ScreenHeading text="Alloy Claim" goBack={this.props.goBack}/>
				<View style={styles.container}>
					<Para>
					  Please wait... 
					</Para>
					<View id='waiting-image'>
						<Image source={waitingimg} alt="Waiting..."></Image>
					</View>
				</View>
			  </View>
			);
		}
		else
		{
			  return (
				<View>
					<ScreenHeading text="Alloy Claim" goBack={this.props.goBack}/>
					<View style={styles.container}>
					  <Para>
						Thank you. 
					  </Para>
					  <Para>
						Your claim has been approved subject to the information you have provided.
					  </Para>
					  <Para>
						In order for us to safely carry out your repair we will require:
					  </Para>
					  <View>
						<Text style={Styles.bullet}>&#8226; Home or work address (not the vehicle retailer site).</Text>
						<Text style={Styles.bullet}>&#8226; A safe location i.e. driveway or private off-street parking.</Text>
						<Text style={Styles.bullet}>&#8226; Space for your vehicle and the technicians medium-sized van.</Text>
						<Text style={Styles.bullet}>&#8226; Supply parking permits where required.</Text>
					  </View>
					  <Para>
						Does your location meet the requirements above?
					  </Para>
					<ButtonList>
					  <View style={Styles.optionsButton}><DefaultButton text="Yes" onClick={this.props.onContinue} /></View>
					  <View style={Styles.optionsButton}><DefaultButton text="No" onClick={this.props.onNo} /></View>
					</ButtonList>
				  </View>
				</View>
				);
		}
	}
}

export default AlloyVehicleLocationStatement;
