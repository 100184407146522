import React from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import PhoneButton from '../../components/PhoneButton';
import ScreenHeading from '../../components/ScreenHeading';
import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

function CancellationFailure(props) {
  return (
	<View>
      <Para>
        Hmmm, for some reason, your payment didn't go through but there's no need to worry. Please contact the Cancellations Team on the number below and they'll get the issue resolved.
      </Para>
      <PhoneButton/>
      <Bottom>
        <DefaultButton text="Return to Main Menu" onClick={props.onDone}/>
      </Bottom>
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})


export default CancellationFailure;
