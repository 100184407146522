import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import DatePicker from '../../components/DatePicker';
import ScreenHeading from '../../components/ScreenHeading';
import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class CancellationDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.date
    };
  }

  render() {
    return (
      <View>
        <ScreenHeading text="Cancellation" goBack={this.props.goBack}/>
		  <View style={styles.container}>
			<Para>
			  Please choose the date from when you wish to cancel
			</Para>
			<DatePicker date={this.state.date} onChange={(date) => this.setDate(date)}/>
				{ !this.canSubmit() ? <Text style={{color: '#FF0000'}}>Cancellation date cannot be in the past</Text> : null }
			<Bottom>
			  <ConditionalButton text="Submit" condition={(e) => this.canSubmit()} onClick={(e) => this.props.onSubmit(this.state.date)} />
			</Bottom>
		  </View>
      </View>
    );
  }

  setDate(newDate) {
    const nextState = Object.assign({}, this.state, {date: newDate});
    this.setState(nextState);
  }

  canSubmit() {
    var beginningOfDay = new Date();
    beginningOfDay.setHours(0, 0, 0, 0);

    return this.state.date >= beginningOfDay &&
      this.state.date <= Date.parse(this.props.policy.expiryDate);
  }
}


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default CancellationDate;
