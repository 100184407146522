import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import ScreenHeading from '../../components/ScreenHeading';
import { StyleSheet, View, Text, TextInput } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'

class WarrantyLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleLocation: props.vehicleLocation
    };
  }

  vehicleLocationChanged(value) {
    this.setState({vehicleLocation: value});
  }

  canSubmit() {
    return this.state.vehicleLocation.length > 3;
  }

  render() {
    return (
      <View>
        <ScreenHeading text="Warranty" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  What is the current location of your vehicle?
			</Para>
				<TextInput multiline={true} numberOfLines={4} style={styles.textInput} onChangeText={(e) => this.vehicleLocationChanged(e)} value={this.state.vehicleLocation}>
				</TextInput>
			<Para>Please enter a minimum of 3 characters</Para>
			{this.keepTyping()}
			<Bottom>
			  <ConditionalButton text="Submit" condition={() => this.canSubmit()} onClick={() => this.props.onContinue(this.state.vehicleLocation)}/>
			</Bottom>
		</View>
      </View>
    );
  }

  keepTyping() {
    const d = this.state.vehicleLocation;
    if (d.length === 0 || d.length > 3) {
      return null;
    } else if (d.length === 3) {
      return (<Para bold={true}>Keep typing (1 character to go)</Para>);
    } else {
      return (<Para bold={true}>Keep typing ({3 - d.length} characters to go)</Para>);
    }
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
textInput: {
    borderWidth: 1,
    fontSize: 16,
    height: 140,
    padding: 5,
	paddingBottom: 15,
	textAlignVertical: 'top'
  }
})

export default WarrantyLocation;
