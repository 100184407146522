import React, { Component } from 'react';
import { StyleSheet, View, Image, Text } from 'react-native'
import ButtonList from '../../components/ButtonList';
import ConditionalButton from '../../components/ConditionalButton';
import PhoneButton from '../../components/PhoneButton';
import DefaultButton from '../../components/DefaultButton';
import LabeledTextField from '../../components/LabeledTextField';
import ScreenHeading from '../../components/ScreenHeading';
import ProductInfo from '../../ProductInfo';
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'
import waitingimg from '../../loading-png-gif.gif';
import Bottom from '../../components/Bottom';
import VerticalSpace from '../../components/VerticalSpace'

class CancellationTFA extends Component {
  constructor(props) {
    super();

    this.state = {
      code: '',
	  contact_mechanism: '',
	  contact_details: '',
      submitting: false,
	  loading: true,
	  error: false,
    };
	
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.submitCode = this.submitCode.bind(this);
    this.onFail = this.onFail.bind(this);
	
	this.SendTFACode(props);
  }
  
  SendTFACode(props) {
	  let theApp = this;
	  this.setState({suggested_dates:[], loading: true, error: false, contact_mechanism: '', contact_details: '', submitting: false, code: ''});
	props.api.sendTFACode(function done(data) {
		theApp.setState({ loading: false, contact_mechanism: data.contact_mechanism, contact_details: data.contact_details})
    }, function fail(xhr) {
      console.log(xhr);
      //onFail();
    });	 
 }
 
 submitCode(value)
 {
	  let theApp = this;
	 if (value == null)
	 {
		this.props.api.checkTFACode(this.state.code, function done() {
			theApp.props.onDone()
		}, function fail() {
		  theApp.onFail();
		});	 
	 }
	 else
	 {
		this.props.api.checkTFACode(value, function done() {
			theApp.props.onDone()
		}, function fail() {
		  theApp.onFail();
		});	 
	 }
 }
 
 onFail()
 {
	this.setState({ error: true})
 }
  
  handleCodeChange(value) {
    this.setState({code: value});
	if (value.length == 6)
	{
		this.submitCode(value)
	}
  }


  render() {
		if (this.state.loading == true)
		{
			return (
			  <View>
				<ScreenHeading text="Cancellation" goBack={this.props.goBack}/>
				<View style={styles.container}>
					<Para>Please wait...</Para>
					<View style={styles.waitingimagewrapper}>
						<Image style={styles.waitingimage} source={waitingimg} alt="Waiting..."></Image>
					</View>
				  </View>
			  </View>
			);
		}
		else if (this.state.error == true)
		{
			return (
			  <View>
				<ScreenHeading text="Cancellation" goBack={this.props.goBack}/>
				<View style={styles.container}>
					<Para>There was a problem with your code.</Para>
					<VerticalSpace height={15}/>
					  <Bottom>
						<DefaultButton text="Request a new code" onClick={() => this.SendTFACode(this.props)}/>
					  </Bottom>
				  </View>
			  </View>
			);
		}
		else
		{
			return (
			  <View>
				<ScreenHeading text="Cancellation" goBack={this.props.goBack}/>
					{this.state.contact_mechanism == "none" ? 
				  <View style={styles.container}>
					  <Para>
						Hmmm, for some reason we are unable to process your cancellation through the app. Please contact the Cancellations Team on the number below and they'll get the issue resolved.
					  </Para>
					  <PhoneButton/>
					  <VerticalSpace height={15}/>
					  <Bottom>
						<DefaultButton text="Return to Main Menu" onClick={this.props.onDone}/>
					  </Bottom>
				  </View>
					:
				  <View style={styles.container}>
					<Para>
					{this.state.contact_mechanism == "mobile" ? 
					  <Text>We have sent an SMS to the number we have for you, ending {this.state.contact_details}.</Text>
					  :
					  <Text>We have sent an email to the address we have for you, ending {this.state.contact_details}.</Text>
					}
					</Para>
					<Para>
						Enter the code below.
					</Para>
					<LabeledTextField label="Code" id="code" onChange={this.handleCodeChange} value={this.state.code}/>
					<VerticalSpace height={15}/>
					<Bottom>
						<ConditionalButton text={"Submit"} condition={(e) => this.canSubmit()} onClick={(e) => this.submitCode()}/>
					</Bottom>
					<Para>Not received a code? <Text onClick={(e) => this.SendTFACode(this.props)} style={Styles.linkstyle}>Click here to resend</Text></Para>
				  </View>
					}
			  </View>
			);
		}
  }

  canSubmit() {
    return (this.state.code != '');
	//return true;
  }
  
}


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  waitingimage: {
    width: 200,
    height: 200,
	resizeMode: 'contain'
  },
  waitingimagewrapper: {
	  width: '100%'
  }
})

export default CancellationTFA;
