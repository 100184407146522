import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import DatePicker from '../../components/DatePicker';
import ScreenHeading from '../../components/ScreenHeading';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class SmartDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.date
    };
  }

  render() {
    return (
      <View>
        <ScreenHeading text="SMART Claim" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  When did you notice the damage?
			</Para>
			<DatePicker date={this.state.date} onChange={(date) => this.setDate(date)}/>
			<Bottom>
			  <ConditionalButton text="Continue" condition={(e) => this.canSubmit()} onClick={(e) => this.props.onSubmit(this.state.date)} />
			</Bottom>
		  </View>
      </View>
    );
  }

  setDate(newDate) {
    this.setState({date: newDate});
  }

  canSubmit() {
    return this.state.date <= new Date();
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default SmartDate;
