import React from 'react';
import DefaultButton from './DefaultButton';
import DisabledButton from './DisabledButton';

function ConditionalButton(props) {
  if (props.condition()) {
    return <DefaultButton text={props.text} onClick={props.onClick}/>;
  } else {
    return <DisabledButton text={props.text}/>;
  }
}

export default ConditionalButton;
