import React, { Component } from 'react';
import ScreenHeading from '../../components/ScreenHeading';
import AlloyWheelChoice from '../Alloy/AlloyWheelChoice';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class TyreChoose extends Component {
  constructor() {
    super();

    this.state = {
      stage: 'intro',
      chosenTyre: ''
    };
  }

  render() {
    return (
      <View>
        <ScreenHeading text="Tyre Claim" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  Please tap an image to indicate which tyre you're claiming for.
			</Para>
			<View style={styles.rowContainer}>
				<AlloyWheelChoice label="Front passenger's side" onChosen={this.props.onChosen}/>
				<AlloyWheelChoice label="Front driver's side" onChosen={this.props.onChosen}/>
			</View>
			<View style={styles.rowContainer}>
				<AlloyWheelChoice label="Rear passenger's side" onChosen={this.props.onChosen}/>
				<AlloyWheelChoice label="Rear driver's side" onChosen={this.props.onChosen}/>
			</View>
			<View style={styles.rowContainer}>
				<AlloyWheelChoice label="Spare" onChosen={this.props.onChosen}/>
				<View style={styles.rowInnerContainer}>
				</View>
			</View>
		</View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  imageContainer: {
    width: 150,
	height: 150
  },
  containedImage: {
    width: '100%',
    height: '100%'
  },
  imageOverlay: {
    position: 'relative',
    top: -35,
    width: '90%'
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
	width: '100%',
	paddingTop: 20,
  },
  rowImage: {
    height: 140,
    resizeMode: 'cover',
    width: 140
  },
  rowInnerContainer: {
    width: '45%',
	display: 'flex',
	alignItems: 'center',
	textAlignVertical: 'top',
  }
})

export default TyreChoose;
