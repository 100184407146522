import React, { FunctionComponent } from 'react'
import { GestureResponderEvent, Pressable, StyleSheet, Text } from 'react-native'
import Colors from '../../constants/Colors'

type DisabledButtonProps = {
  text: string,
}

const DisabledButton: FunctionComponent<DisabledButtonProps> = (props: DisabledButtonProps) => {
  return (
    <Pressable style={styles.disabledButton} >
      <Text style={styles.buttonText}>{props.text}</Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  buttonText: {
    color: '#ddd',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  disabledButton: {
    backgroundColor: '#888',
    borderRadius: 4,
    padding: 10
  }
})

export default DisabledButton
