import React, { Component } from 'react';
import GapIntro from './GapIntro';
import GapInsurer from './GapInsurer';
import GapIncident from './GapIncident';
import GapConfirmation from './GapConfirmation';
import GapSubmitting from './GapSubmitting';
import GapSuccess from './GapSuccess';
import GapFail from './GapFail';

class Gap extends Component {
  constructor() {
    super();

    this.state = {
      stage: 'intro',
      insurerDate: new Date(),
      financed: null,
      settlementAccepted: null,
      lessthan12months: null,
      newforold: null,
      mileage: '',
      incidentDate: new Date(),
      incident: null
    };

    this.handleInsurerDetails = this.handleInsurerDetails.bind(this);
    this.handleIncidentDetails = this.handleIncidentDetails.bind(this);
  };

  afterIntro() {
    this.setState(Object.assign({}, this.state, {stage: 'insurer'}));
  }

  handleInsurerDetails(date, financed, settlementAccepted, newforold, lessthan12months) {
	  console.log(newforold);
    this.setState(Object.assign(
      {}, this.state, {
        stage: 'incident',
        insurerDate: date,
        financed: financed,
        newforold: newforold,
		lessthan12months: lessthan12months,
        settlementAccepted: settlementAccepted
      }
    ));
  }

  handleIncidentDetails(mileage, date, incident) {
    this.setState(Object.assign(
      {}, this.state, {
        stage: 'confirmation',
        mileage: mileage,
        incidentDate: date,
        incident: incident
      }
    ));
  }

  render() {
    if (this.state.stage === 'intro') {
      return(
        <GapIntro onContinue={() => this.afterIntro()} goBack={() => this.props.onDone()}/>
      );
    } else if (this.state.stage === 'insurer') {
      return(
        <GapInsurer date={this.state.insurerDate} financed={this.state.financed} settlementAccepted={this.state.settlementAccepted} lessthan12months={this.state.lessthan12months} newforold={this.state.newforold} policy={this.props.policy} onSubmit={this.handleInsurerDetails} goBack={() => this.setState({stage: 'intro'})}/>
      );
    } else if (this.state.stage === 'incident') {
      return(
        <GapIncident date={this.state.incidentDate} mileage={this.state.mileage} incident={this.state.incident} onSubmit={this.handleIncidentDetails} goBack={() => this.setState({stage: 'insurer'})}/>
      );
    } else if (this.state.stage === 'confirmation') {
      return(
        <GapConfirmation
          details={this.props.details}
          insurerDate={this.state.insurerDate}
          financed={this.state.financed}
          settlementAccepted={this.state.settlementAccepted}
		  newforold={this.state.newforold}
          mileage={this.state.mileage}
          incidentDate={this.state.incidentDate}
          incident={this.state.incident}
          onSubmit={() => this.submitClaim()}
          onEditIncident={() => this.setState({stage: 'incident'})}
          onEditInsurer={() => this.setState({stage: 'insurer'})}
		  goBack={() => this.setState({stage: 'incident'})}
        />
      );
    } else if (this.state.stage === 'submitting') {
      return(
        <GapSubmitting goBack={() => this.setState({stage: 'confirmation'})}/>
      );
    } else if (this.state.stage === 'success') {
      return(
        <GapSuccess onDone={this.props.onDone} goBack={() => this.props.onDone()}/>
      );
    } else if (this.state.stage === 'fail') {
      return(
        <GapFail onRetry={() => this.setState({'stage': 'intro'})} goBack={() => this.props.onDone()}/>
      );
    }
  }

  submitClaim() {
    this.setState({stage: 'submitting'});
    const component = this;
    this.props.api.gapClaim(
      this.props.policy,
      this.state.insurerDate,
      this.state.financed,
      this.state.settlementAccepted,
      this.state.mileage,
      this.state.incidentDate,
      this.state.incident,
      this.state.newforold,
      function done(data) {
        component.setState({stage: 'success'});
		component.props.policy.hasExistingClaim = true;
      },
      function fail(xhr) {
        console.log(xhr);
        component.setState({stage: 'fail'});
      }
    );
  }
}

export default Gap;
