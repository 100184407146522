import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native'
import ButtonList from '../components/ButtonList';
import DefaultButton from '../components/DefaultButton';
import ScreenHeading from '../components/ScreenHeading';
import ProductInfo from '../ProductInfo';
import Para from '../components/Para'
import Styles from '../../constants/Styles'

class Options extends Component {
  constructor(props) {
    super();
    this.productInfo = new ProductInfo(props.policies);
  }

  render() {
    return (
      <View>
        <ScreenHeading text="Your Options" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  Please select.
			</Para>
			<ButtonList>
			  {this.personalDetails()}
			  {this.vehicleDetails()}
			  {this.alloy()}
			  {this.gap()}
			  {this.smart()}
			  {this.tyre()}
			  {this.cancellation()}
			</ButtonList>
		</View>
      </View>
    );
  }

  personalDetails() {
      return (
        <View style={Styles.optionsButton}>
          <DefaultButton
            text="Personal Details"
            onClick={(e) => this.props.onStartApp("personalDetails", this.productInfo)}
          />
        </View>
      )
  }

  vehicleDetails() {
      return (
        <View style={Styles.optionsButton}>
          <DefaultButton
            text="Vehicle Details"
            onClick={(e) => this.props.onStartApp("vehicleDetails", this.productInfo)}
          />
        </View>
      )
  }

  policyDocumentation() {
      return (
        <View style={Styles.optionsButton}>
          <DefaultButton
            text="Policy Documentation"
            onClick={(e) => this.props.onStartApp("policyDocumentation", this.productInfo)}
          />
        </View>
      )
  }

  warranty() {
    const policy = this.productInfo.policyIncluding("warrantyx");
    if (policy) {
      return (
        <View style={Styles.optionsButton}>
          <DefaultButton
            text="Warranty"
            onClick={(e) => this.props.onStartApp("warranty", policy)}
          />
        </View>
      )
    }
  }

  smart() {
    const policy = this.productInfo.policyIncluding("smart");
    if (policy) {
      return (
        <View style={Styles.optionsButton}>
          <DefaultButton
            text="SMART Claim"
            onClick={(e) => this.props.onStartApp("smart", policy)}
          />
        </View>
      )
    }
  }

  alloy() {
    const policy = this.productInfo.policyIncluding("alloy");
    if (policy) {
      return (
        <View style={Styles.optionsButton}>
          <DefaultButton
            text="Alloy Claim"
            onClick={(e) => this.props.onStartApp("alloy", policy)}
          />
        </View>
      )
    }
  }

  tyre() {
    const policy = this.productInfo.policyIncluding("tyre");
    if (policy) {
      return (
        <View style={Styles.optionsButton}>
          <DefaultButton
            text="Tyre Claim"
            onClick={(e) => this.props.onStartApp("tyre", policy)}
          />
        </View>
      )
    }
  }

  cancellation() {
    if (this.productInfo.anyCancellablePolicies(true)) {
      return (
        <View style={Styles.optionsButton}><DefaultButton text="Cancellation" onClick={(e) => this.props.onStartApp("cancellation")} /></View>
      )
    }
  }

  gap() {
    const policy = this.productInfo.policyIncluding("gap") || this.productInfo.policyIncluding("vri");
    if (policy) {
		if (this.productInfo.hasExistingClaim())
		{
			return (
		<View style={Styles.optionsButton}>
          <DefaultButton
            text="GAP Claim"
            onClick={(e) => this.props.onStartApp("gapclaimed", policy, this.productInfo.existingClaimEmail(), this.productInfo.existingClaimId())}
          />
        </View>
		)
		}
		else
		{
      return (
        <View style={Styles.optionsButton}>
          <DefaultButton
            text="GAP Claim"
            onClick={(e) => this.props.onStartApp("gap", policy)}
          />
        </View>
      )
		}
    }
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})


export default Options;
