import React, { Component } from 'react';
import { StyleSheet, View, Image, Text } from 'react-native'
import ButtonList from '../components/ButtonList';
import ConditionalButton from '../components/ConditionalButton';
import PhoneButton from '../components/PhoneButton';
import DefaultButton from '../components/DefaultButton';
import LabeledTextField from '../components/LabeledTextField';
import ScreenHeading from '../components/ScreenHeading';
import ProductInfo from '../ProductInfo';
import Para from '../components/Para'
import Styles from '../../constants/Styles'
import waitingimg from '../loading-png-gif.gif';
import Bottom from '../components/Bottom';
import VerticalSpace from '../components/VerticalSpace'

class Waiting extends Component {

  render() {
			return (
			  <View>
				<ScreenHeading text="Authenticate" goBack={this.props.goBack}/>
				<View style={styles.container}>
					<Para>Please wait...</Para>
					<View style={styles.waitingimagewrapper}>
						<Image style={styles.waitingimage} source={waitingimg} alt="Waiting..."></Image>
					</View>
				  </View>
			  </View>
			);
  } 
}


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  waitingimage: {
    width: 200,
    height: 200,
	resizeMode: 'contain'
  },
  waitingimagewrapper: {
	  width: '100%'
  }
})

export default Waiting;
