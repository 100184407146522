import React, { Component } from 'react';
import ButtonList from '../../components/ButtonList';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class TyreDrivable extends Component {
  constructor() {
    super();
    this.handleChosen = this.handleChosen.bind(this);
  }

  render() {
    return (
      <View>
        <ScreenHeading text="Tyre Claim" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  Thank you for providing your tyre claim details.
			</Para>
			<Para>
			  Please answer the following question and you will be provided with detailed instructions on what you should do next:
			</Para>
			<Para bold={true}>
				IS YOUR VEHICLE CURRENTLY DRIVABLE?
			</Para>
			<ButtonList>
			  <View style={Styles.optionsButton}><DefaultButton text="Yes" onClick={() => this.handleChosen('Yes')}/></View>
			  <View style={Styles.optionsButton}><DefaultButton text="No" onClick={() => this.handleChosen('No')}/></View>
			</ButtonList>
		  </View>
      </View>
    );
  }

  handleChosen(value) {
    this.props.onContinue(value);
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default TyreDrivable;
