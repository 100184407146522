import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ConditionalButton from '../../components/ConditionalButton';
import ScreenHeading from '../../components/ScreenHeading';
import LabeledTextField from '../../components/LabeledTextField'
import LabeledEditTextField from '../../components/LabeledEditTextField'
import LabeledEditTextFieldWithRedirect from '../../components/LabeledEditTextFieldWithRedirect'
import LabeledEditTextFieldEmail from '../../components/LabeledEditTextFieldEmail'
import AddressSelectOptions from '../../components/AddressSelectOptions'
import FileUploadSingle from '../../components/FileUploadSingle';
import VerticalSpace from '../../components/VerticalSpace'
import Waiting from '../../SignIn/Waiting';
import * as Progress from 'react-native-progress';

import { StyleSheet, View, Text } from 'react-native'
import AwesomeAlert from 'react-native-awesome-alerts';

import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class PersonalDetails extends Component {

  constructor(props) {
    super(props);
	
	this.state = {
      original_email: "",
	  original_firstName: "",
	  original_surname: "",
      email: "",
	  firstName: "",
	  surname: "",
	  fullName: "",
	  original_addressLine1: "",
	  original_addressLine2: "",
	  original_addressLine3: "",
	  original_addressLine4: "",
	  original_postcode: "",
	  addressLine1: "",
	  addressLine2: "",
	  addressLine3: "",
	  addressLine4: "",
	  postcode: "",
	  new_postcode: "",
	  fullAddress: "",
	  loading: true,
	  editingName: false,
	  editingAddress: false,
	  namePhoto: null,
	  namePhotoUploadProgress: 0,
    };
	this.updateEmail = this.updateEmail.bind(this);
	this.updateFirstName = this.updateFirstName.bind(this);
	this.updateSurname = this.updateSurname.bind(this);
	this.updateAddressLine1 = this.updateAddressLine1.bind(this);
	this.updateAddressLine2 = this.updateAddressLine2.bind(this);
	this.updateAddressLine3 = this.updateAddressLine3.bind(this);
	this.updateAddressLine4 = this.updateAddressLine4.bind(this);
	this.updatePostcode = this.updatePostcode.bind(this);
	this.updateNewPostcode = this.updateNewPostcode.bind(this);
	this.saveDetails = this.saveDetails.bind(this);
	this.editName = this.editName.bind(this);
	this.editAddress = this.editAddress.bind(this);
	this.canSubmitNameUpdate = this.canSubmitNameUpdate.bind(this);
	this.canSubmitAddressUpdate = this.canSubmitAddressUpdate.bind(this);
	this.onEditNameDone = this.onEditNameDone.bind(this);
	this.onEditAddressDone = this.onEditAddressDone.bind(this);
	this.onPhotoUploaded = this.onPhotoUploaded.bind(this);
	this.cancelAddPhoto = this.cancelAddPhoto.bind(this);
	this.photoUploadProgress = this.photoUploadProgress.bind(this);
	this.findAddresses = this.findAddresses.bind(this);
	this.setSelectedAddressFn = this.setSelectedAddressFn.bind(this);
	
	
	
	let theApp = this;
	this.props.api.getContactDetails(props.policies.id, function done(details) {
	  if (details) {
		  console.log(details);
		  if (details.Forename == null)
		  {
			  details.Forename = "";
		  }
		  if (details.Surname == null)
		  {
			  details.Surname = "";
		  }
		  if (details.EmailAddress == null)
		  {
			  details.EmailAddress = "";
		  }
		  if (details.AddressLine1 == null)
		  {
			  details.AddressLine1 = "";
		  }
		  if (details.AddressLine2 == null)
		  {
			  details.AddressLine2 = "";
		  }
		  if (details.AddressLine3 == null)
		  {
			  details.AddressLine3 = "";
		  }
		  if (details.AddressLine4 == null)
		  {
			  details.AddressLine4 = "";
		  }
		  if (details.Postcode == null)
		  {
			  details.Postcode = "";
		  }
		  var fullName = details.Forename + " " + details.Surname;
		  var fullAddress = details.AddressLine1;
		  if (details.AddressLine2 != "")
		  {
			  fullAddress += ", " + details.AddressLine2;
		  }
		  if (details.AddressLine3 != "")
		  {
			  fullAddress += ", " + details.AddressLine3;
		  }
		  if (details.AddressLine4 != "")
		  {
			  fullAddress += ", " + details.AddressLine4;
		  }
		  fullAddress += ", " + details.Postcode;
			theApp.setState({firstName: details.Forename, surname: details.Surname, email: details.EmailAddress, original_firstName: details.Forename, original_surname: details.Surname, original_email: details.EmailAddress, addressLine1: details.AddressLine1, original_addressLine1: details.AddressLine1, addressLine2: details.AddressLine2, original_addressLine2: details.AddressLine2, addressLine3: details.AddressLine3, original_addressLine3: details.AddressLine3, addressLine4: details.AddressLine4, original_addressLine4: details.AddressLine4, postcode: details.Postcode, original_postcode: details.Postcode, loading: false, fullName: fullName, fullAddress: fullAddress});
	  }
	}, function notFound() {
		theApp.setState({loading: false});
	}, function fail(xhr) {
		console.log(xhr);
		theApp.setState({loading: false});
	});
  }
  
  photoUploadProgress(loaded, total)
  {
	  let pc = loaded / total
	  this.setState({namePhotoUploadProgress: pc})
  }

  
  onPhotoUploaded(data)
  {
	  console.log(data);
	  this.setState({namePhoto: data});
  }
  cancelAddPhoto()
  {
  }

  updateEmail(value: string) {
    var nextState = Object.assign({}, this.state);
    nextState.email = value;
    this.setState(nextState);
	}
	
	editName() {
    var nextState = Object.assign({}, this.state);
    nextState.editingName = true;
    this.setState(nextState);
		
	}

	editAddress() {
    var nextState = Object.assign({}, this.state);
    nextState.editingAddress = true;
    this.setState(nextState);
		
	}

  saveDetails(value: string) {
	  if(this.state.email != this.state.original_email)
	  {
	let theApp = this;
		this.props.api.setContactDetails(this.props.policies.id, this.state.email, this.state.firstName, this.state.surname, function done(details) {
				theApp.setState({showEmailAlert: true, original_email: theApp.state.email});
		}, function notFound() {
			theApp.setState({loading: false});
		}, function fail(xhr) {
			console.log(xhr);
			theApp.setState({loading: false});
		});
	  }
	}
  
  onEditNameDone() {
	let theApp = this;
		this.props.api.updateName(this.props.policies.id, this.state.original_firstName, this.state.firstName, this.state.original_surname, this.state.surname, this.state.namePhoto, function done(details) {

				theApp.setState({showNameAlert: true});

				
				//theApp.setState({original_firstName: theApp.state.firstName, original_surname: theApp.state.surname, original_email: theApp.state.email});
		}, function fail(xhr) {
			console.log(xhr);
			theApp.setState({loading: false});
		},
		this.photoUploadProgress);
	}
	
  onEditAddressDone() {
	let theApp = this;

		this.props.api.updateAddress(this.props.policies.id, this.state.addressLine1, this.state.addressLine2, this.state.addressLine3, this.state.addressLine4, this.state.postcode, function done(details) {
			
			console.log(theApp.state);

			  var fullAddress = theApp.state.addressLine1;
			  if (theApp.state.addressLine2 != "")
			  {
				  fullAddress += ", " + theApp.state.addressLine2;
			  }
			  if (theApp.state.addressLine3 != "")
			  {
				  fullAddress += ", " + theApp.state.addressLine3;
			  }
			  if (theApp.state.addressLine4 != "")
			  {
				  fullAddress += ", " + theApp.state.addressLine4;
			  }
			  fullAddress += ", " + theApp.state.postcode;
				theApp.setState({showAddressAlert: true, fullAddress: fullAddress});

				
				//theApp.setState({original_firstName: theApp.state.firstName, original_surname: theApp.state.surname, original_email: theApp.state.email});
		}, function notfound(xhr) {
			console.log(xhr);
			theApp.setState({loading: false});
		}, function fail(xhr) {
			console.log(xhr);
			theApp.setState({loading: false});
		});
	}

  updateSurname(value: string) {
    var nextState = Object.assign({}, this.state);
    nextState.surname = value;
    this.setState(nextState);
	}

  updateFirstName(value: string) {
    var nextState = Object.assign({}, this.state);
    nextState.firstName = value;
    this.setState(nextState);
	}

  updateAddressLine1(value: string) {
    var nextState = Object.assign({}, this.state);
    nextState.addressLine1 = value;
    this.setState(nextState);
	}

  updateAddressLine2(value: string) {
    var nextState = Object.assign({}, this.state);
    nextState.addressLine2 = value;
    this.setState(nextState);
	}

  updateAddressLine3(value: string) {
    var nextState = Object.assign({}, this.state);
    nextState.addressLine3 = value;
    this.setState(nextState);
	}

  updateAddressLine4(value: string) {
    var nextState = Object.assign({}, this.state);
    nextState.addressLine4 = value;
    this.setState(nextState);
	}

  updatePostcode(value: string) {
    var nextState = Object.assign({}, this.state);
    nextState.postcode = value;
    this.setState(nextState);
	}
	
	updateNewPostcode(value: string) {
    var nextState = Object.assign({}, this.state);
    nextState.new_postcode = value;
    this.setState(nextState);
	}

  canSubmitNameUpdate() {
    return ((this.state.original_firstName != this.state.firstName || this.state.original_surname != this.state.surname) && this.state.namePhoto != null);
	}

  canSubmitAddressUpdate() {
	  console.log(this.state);
    return (this.state.original_addressLine1 != this.state.addressLine1 || this.state.original_addressLine2 != this.state.addressLine2 || this.state.original_addressLine3 != this.state.addressLine3 || this.state.original_addressLine4 != this.state.addressLine4 || this.state.original_postcode != this.state.postcode);
	}


  findAddresses() {
    const done = (data : any) => {
		this.setState({addresses: data});
    }
    const fail = () => {
		this.setState({addresses: ""});
    }
    this.props.api.postcodeLookup(this.state.new_postcode, done, fail)
  }
  
  formatAddress(address) {
    let formattedAddress = ''
    if (address.building_name) formattedAddress += `${address.building_name}`
    if (address.building_number) formattedAddress += ` ${address.building_number},`
    if (address.thoroughfare) formattedAddress += ` ${address.thoroughfare}`
    return formattedAddress
  }
  
  setSelectedAddressFn(address){
	this.setState({addressLine1: (address.building_number ? address.building_number : address.building_name).concat(' ').concat(address.thoroughfare), addressLine2: address.post_town, addressLine3: '', addressLine4: '', postcode: address.postcode});


  }

  
  render() {
	  let theApp = this;
	  if (this.state.loading)
	  {
			return (
			  <Waiting />
			);
	  }
	  else if (this.state.editingName)
	  {
		  return (
		  <View>
			<ScreenHeading text="Personal Details" goBack={this.props.onDone}/>
			<View style={styles.container}>
				<Para>
				  Please enter your new name and upload photographic ID or a relevant certificate.
				</Para>
				<LabeledTextField label="First name" value={this.state.firstName} onChange={this.updateFirstName} />
				<LabeledTextField label="Last name" value={this.state.surname} onChange={this.updateSurname} />
				  <Para>Please upload a photo of either photographic ID or a relevant certificate. (jpg, png or pdf)</Para>
				  <FileUploadSingle data={null} onUpload={(data) => this.onPhotoUploaded(data)} onCancel={() => this.cancelAddPhoto()}/>
					  {this.state.namePhotoUploadProgress > 0 ? <Progress.Bar progress={this.state.namePhotoUploadProgress} width={200} /> : null }
			  <Bottom>
				<ConditionalButton text="Submit" condition={this.canSubmitNameUpdate} onClick={this.onEditNameDone}/>
			  </Bottom>
			  </View>
			<AwesomeAlert
					  show={this.state.showNameAlert}
					  showProgress={false}
					  title="Message"
					  message="Your updated name details have been submitted for review. Changes will not appear in the app until your request has been reviewed and approved."
					  closeOnTouchOutside={true}
					  closeOnHardwareBackPress={false}
					  showCancelButton={false}
					  showConfirmButton={true}
					  confirmText="OK"
					  confirmButtonColor="#DD6B55"
					  onCancelPressed={() => {
						theApp.setState({showNameAlert: false, editingName: false});
					  }}
					  onConfirmPressed={() => {
						theApp.setState({showNameAlert: false, editingName: false});
					  }}
					/>
		  </View>
		  );
	  }
	  else if (this.state.editingAddress == true)
	  {
		  if (this.state.checkinUK != true)
		  {
			  return (
			  <View>
				<ScreenHeading text="Personal Details" goBack={this.props.onDone}/>
				<View style={styles.container}>
					<Para>
					  Please confirm if your new address is in the UK.
					</Para>
					<VerticalSpace height={10} />
					<DefaultButton onClick={() => this.setState({ checkinUK : true, isinUK: true })} text="Yes, my new address is in the UK"/>
					<VerticalSpace height={10} />
					<DefaultButton onClick={() => this.setState({ checkinUK : true, isinUK: false })} text="No, my new address is outside the UK"/>
				  </View>
			  </View>
			  );
		  }
		  else if (this.state.isinUK == false)
		  {
			  return (
			  <View>
				<ScreenHeading text="Personal Details" goBack={this.props.onDone}/>
				<View style={styles.container}>
					<Para>
					  Please call us on 01279 456 500. 
					</Para>
				  <Bottom>
					<DefaultButton text="Return to menu" onClick={this.props.onDone}/>
				  </Bottom>
				  </View>
			  </View>
			  );
		  }
		  else
		  {
			  return (
			  <View>
				<ScreenHeading text="Personal Details" goBack={this.props.onDone}/>
				<View style={styles.container}>
					<Para>
					  Please provide the postcode of your new address.
					</Para>
					<LabeledTextField label='Postcode' onChange={this.updateNewPostcode} value={this.state.new_postcode}/>
					<VerticalSpace height={10} />
					<DefaultButton onClick={() => this.findAddresses()} text="Look up"/>
					<VerticalSpace height={10} />
					<AddressSelectOptions addresses={this.state.addresses} formatAddress={this.formatAddress} onChange={this.setSelectedAddressFn} />
					<Para>Or provide the address here:</Para>
					<LabeledTextField label="Address line 1" value={this.state.addressLine1} onChange={this.updateAddressLine1} />
					<LabeledTextField label="Address line 2" value={this.state.addressLine2} onChange={this.updateAddressLine2} />
					<LabeledTextField label="Address line 3" value={this.state.addressLine3} onChange={this.updateAddressLine3} />
					<LabeledTextField label="Address line 4" value={this.state.addressLine4} onChange={this.updateAddressLine4} />
					<LabeledTextField label="Postcode" value={this.state.postcode} onChange={this.updatePostcode} />
				  <Bottom>
					<ConditionalButton text="Submit" condition={this.canSubmitAddressUpdate} onClick={this.onEditAddressDone}/>
				  </Bottom>
				  </View>
				<AwesomeAlert
						  show={this.state.showAddressAlert}
						  showProgress={false}
						  title="Message"
						  message="Your request has been actioned, please allow 5 mins for your policy documentation to update."
						  closeOnTouchOutside={true}
						  closeOnHardwareBackPress={false}
						  showCancelButton={false}
						  showConfirmButton={true}
						  confirmText="OK"
						  confirmButtonColor="#DD6B55"
						  onCancelPressed={() => {
							theApp.setState({showAddressAlert: false, editingAddress: false});
						  }}
						  onConfirmPressed={() => {
							theApp.setState({showAddressAlert: false, editingAddress: false});
						  }}
						/>
			  </View>
			  );
		  }
	  }
	  else
	  {
	return (
      <View>
        <ScreenHeading text="Personal Details" goBack={this.props.onDone}/>
		<View style={styles.container}>
			<Para>
			  The details we hold about you are below. Please click the edit buttons to submit updated details.
			</Para>
			<LabeledEditTextFieldWithRedirect label="Name" value={this.state.fullName} onEditClick={this.editName} />
			<LabeledEditTextFieldWithRedirect label="Address" value={this.state.fullAddress} onEditClick={this.editAddress} />
			<LabeledEditTextFieldEmail label="Email" value={this.state.email} onChange={this.updateEmail} onSave={this.saveDetails} />
		  <Bottom>
			<DefaultButton text="Return to main menu" onClick={this.props.onDone}/>
		  </Bottom>
		  </View>
			<AwesomeAlert
					  show={this.state.showEmailAlert}
					  showProgress={false}
					  title="Message"
					  message="Your email address details have been submitted."
					  closeOnTouchOutside={true}
					  closeOnHardwareBackPress={false}
					  showCancelButton={false}
					  showConfirmButton={true}
					  confirmText="OK"
					  confirmButtonColor="#DD6B55"
					  onCancelPressed={() => {
						theApp.setState({showEmailAlert: false});
					  }}
					  onConfirmPressed={() => {
						theApp.setState({showEmailAlert: false});
					  }}
					/>
      </View>
    );
	  }
 }
 
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default PersonalDetails;
