import React, { Component } from 'react';
import Bottom from '../components/Bottom';
import DefaultButton from '../components/DefaultButton';
import ScreenHeading from '../components/ScreenHeading';

import { StyleSheet, View, Text, Linking } from 'react-native'
import Para from '../components/Para'
import Styles from '../../constants/Styles'

class TrustPilot extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <View>
        <ScreenHeading text={"Thank you"} goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  Thank you for using the customer portal
			</Para>
			<Para>
			  We would be grateful if you would leave feedback in order that we can continuously improve our processes based on your input.
			</Para>
			<Bottom>
			  <DefaultButton text="Click to review" onClick={() => Linking.openURL(`https://uk.trustpilot.com/evaluate/autoprotect.co.uk`)}/>
			</Bottom>
		  </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default TrustPilot;
