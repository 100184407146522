import React, { Component } from 'react';
import { StyleSheet, View, Image, Platform } from 'react-native'
//import IdleTimer from 'react-idle-timer';
import API from './API';
import Styles from './../constants/Styles'
import faq from './faq.png';
import home from './home.svg';
import logo from './logo.png';
import Welcome from './Welcome';
import CreditCardWarning from './CreditCardWarning';
import SignIn from './SignIn/SignIn';
import MainMenu from './MainMenu/MainMenu';
//import CancellationBankDetailsReminder from './apps/Cancellation/CancellationBankDetailsReminder';
import NavigationBar from './components/NavigationBar'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import {
  useSearchParams
} from "react-router-dom";

class MainApp extends Component {
  constructor() {
    super();
	
	if (Platform.OS == "web")
	{
		const query = new URLSearchParams(window.location.search);
		
		AsyncStorage.setItem('submittedBy', '')
		this.submittedBy = query.get('aps');
		if(this.submittedBy != null)
		{
			AsyncStorage.setItem('details.firstName', '')
			AsyncStorage.setItem('details.surname', '')
			AsyncStorage.setItem('details.registrationNumber', '')
			AsyncStorage.setItem('details.postcode', '')
			AsyncStorage.setItem('details.mobileNumber', '')
			AsyncStorage.setItem('details.email', '')
			AsyncStorage.setItem('details.repeatEmail', '')
			AsyncStorage.setItem('details.mileage', '')
			AsyncStorage.setItem('firstName', '')
			AsyncStorage.setItem('surname', '')
			AsyncStorage.setItem('mobileNumber', '')
			AsyncStorage.setItem('email', '')
			AsyncStorage.setItem('registrationNumber', '')
			AsyncStorage.setItem('postcode', '')
			AsyncStorage.setItem('policyNumber', '')
			AsyncStorage.setItem('repeatEmail', '')
			AsyncStorage.setItem('mileage', '')
			AsyncStorage.setItem('alloyDamageDescription', '')
			
			AsyncStorage.setItem('submittedBy', this.submittedBy)
		}
	}

    this.api = new API();
    this.idleTimeout = 30 * 60 * 1000;

    this.state = {
      authenticated: false,
      welcomed: false,
      creditCardWarned: false,
	  cancel_thanks: false,
	  backToEnd: false,
      details: {
        registrationNumber: AsyncStorage.getItem('registrationNumber') || '',
        postcode: AsyncStorage.getItem('postcode') || '',
        policyNumber: AsyncStorage.getItem('policyNumber') || '',
        firstName: AsyncStorage.getItem('firstName') || '',
        surname: AsyncStorage.getItem('surname') || '',
        mobileNumber: AsyncStorage.getItem('mobileNumber') || '',
        email: AsyncStorage.getItem('email') || ''
      },
      policies: []
    };

    this.onAuthenticated = this.onAuthenticated.bind(this);
    this.onPreviouslyAuthenticated = this.onPreviouslyAuthenticated.bind(this);
    this.updatePolicies = this.updatePolicies.bind(this);
	this.startAgain = this.startAgain.bind(this);
	this.logout = this.logout.bind(this);
  }

  onAuthenticated(details, policies) {
	  console.log(details);
	AsyncStorage.setItem('registrationNumber', details.registrationNumber)
	AsyncStorage.setItem('postcode', details.postcode)
	AsyncStorage.setItem('policyNumber', details.postcode)
    this.setState(
      Object.assign({}, this.state, {
        authenticated: true, details: details, policies: policies
      })
    );
  }

  onPreviouslyAuthenticated(policies) {
    this.setState(
      Object.assign({}, this.state, {
        authenticated: true, policies: policies
      })
    );
  }

  updatePolicies(policies) {
    this.setState({policies: policies});
  }

  onWelcomed() {
    this.setState({welcomed: true});
  }

  onCreditCardWarned() {
    this.setState({creditCardWarned: true});
  }
  
  startAgain() {
    this.setState({welcomed: false, authenticated: false, backToEnd: false});
  }

  logout() {
	AsyncStorage.setItem('ast', '', () => {
		this.setState({welcomed: false, authenticated: false, backToEnd: false})
	});
  }

  render() {
    return (
		<KeyboardAwareScrollView style={Styles.outerContainer}>
			  <View style={Styles.AppHeader}>
				<NavigationBar goHome={() => this.startAgain()} loggedin={this.state.authenticated} logout={() => this.logout()}></NavigationBar>
			  </View>
			  <View style={Styles.AppBody}>
				{this.screen()}
			  </View>
		</KeyboardAwareScrollView>
    );
  }

  screen() {
	//let { searchParams } = useSearchParams()
	const bd = null//searchParams.get("bd")//getParameterByName("bd")
	if (bd != null)
	{
		//return <CancellationBankDetailsReminder bd={bd} api={this.api} />;
	}
    else if (!this.state.welcomed) {
      return <Welcome onContinue={() => this.onWelcomed()}/>;
    } else if (this.state.authenticated) {
      return (<MainMenu api={this.api} policies={this.state.policies} onUpdatePolicies={this.updatePolicies} details={this.state.details} goBack={() => this.setState({authenticated: false, backToEnd: true})} onDone={() => this.startAgain()}/>);
    } else {
      return (<SignIn api={this.api} policies={this.state.policies} registrationNumber={this.state.details.registrationNumber} policyNumber={typeof this.state.details.policyNumber === 'string' ? this.state.details.policyNumber : ''} postcode={this.state.details.postcode} onAuthenticated={this.onAuthenticated} onPreviouslyAuthenticated={this.onPreviouslyAuthenticated} backToEnd={this.state.backToEnd} goHome={() => this.startAgain()}/>);
    }
  }
}

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default MainApp;
