import React from 'react'
import { StyleSheet, View } from 'react-native'
import DefaultButton from '../components/DefaultButton'
import NavigationBar from '../components/NavigationBar'
import Para from '../components/Para'
import ScreenHeading from '../components/ScreenHeading'
import VerticalSpace from '../components/VerticalSpace'
import Styles from '../../constants/Styles'
import PhoneButton from '../components/PhoneButton';
import Bottom from '../components/Bottom';

interface FailedSignInProps {
  navigation: any
  onTryAgain: Function
  onRequestCallback: Function
  goHome: Function
  goBack: Function
}

export default function FailedSignIn ({ navigation, onTryAgain, onRequestCallback, goHome, goBack }: FailedSignInProps) {
  const emptyTestFunction = () => {}

  return (
    <View>
      <ScreenHeading text="Sorry" goBack={goBack}/>
      <View style={styles.container}>
        <Para>
          Ok, no need to worry. You can either tap the button below to try again or request a callback and one of the team will investigate and get the issue resolved.
        </Para>
        <Para>
          Remember we only need the digits from your agreement number, not the letters.
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onClick={onTryAgain} text="Try Again"/>
        <VerticalSpace height={15}/>
        <Bottom>
          <PhoneButton/>
        </Bottom>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
