import React, { Component } from 'react';
import popupclosebutton from '../icon-close.png'
import { StyleSheet, View, Text, Pressable, Image } from 'react-native'
import Styles from '../../constants/Styles'

class PhotoPreview extends Component {
	
	constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);

    // Set the state directly. Use props if necessary.
    this.state = {
      isOpen: false
    }
	  const handleShowDialog = () => {
		this.setState({ isOpen: !this.state.isOpen });
	  };
  }

  
  
  render() {
    if (this.props.data) {
      return(
            <View style={styles.rowInnerContainer}>
				<View style={styles.imageContainer}>
				  <Image style={styles.rowImage} source={{uri:this.props.data}} alt={'Preview'}></Image>
				</View>
            </View>
      );
    } else {
      return null;
    }
  }
}

const styles = StyleSheet.create({
  imageContainer: {
    width: 150,
	height: 150
  },
  containedImage: {
    width: '100%',
    height: '100%'
  },
  imageOverlay: {
    position: 'relative',
    top: -35,
    width: '90%'
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
	width: '100%'
  },
  rowImage: {
    height: 140,
    resizeMode: 'cover',
    width: 140
  },
  rowInnerContainer: {
    width: '100%',
	display: 'flex',
	alignItems: 'center',
	textAlignVertical: 'top',
  }
})

export default PhotoPreview;
