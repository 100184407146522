import React, { Component } from 'react';
import SmartSplash from './SmartSplash';
import SmartIntro from './SmartIntro';
import SmartDate from './SmartDate';
import SmartDamageType from './SmartDamageType';
import SmartDamageLocation from './SmartDamageLocation';
import SmartPhotos from './SmartPhotos';
import SmartSubmitting from './SmartSubmitting';
import SmartAnother from './SmartAnother';
import SmartDifferent from './SmartDifferent';
import SmartDifferentReferred from './SmartDifferentReferred';
import SmartThanks from './SmartThanks';
import SmartFail from './SmartFail';
import SmartVehicleLocationStatement from './SmartVehicleLocationStatement';
import SmartLocation from './SmartLocation';
import SmartRepairDate from './SmartRepairDate';
import SmartNoAppointment from './SmartNoAppointment';


class Smart extends Component {
  constructor() {
    super();

    this.state = this.freshState();

    this.handleSplashContinue = this.handleSplashContinue.bind(this);
    this.handleIntroContinue = this.handleIntroContinue.bind(this);
    this.handleLocationContinue = this.handleLocationContinue.bind(this);
    this.handleRepairDateContinue = this.handleRepairDateContinue.bind(this);
    this.handleChosenDate = this.handleChosenDate.bind(this);
    this.handleChosenDamageType = this.handleChosenDamageType.bind(this);
    this.handleChosenDamageLocation = this.handleChosenDamageLocation.bind(this);
    this.handlePhotoUploads = this.handlePhotoUploads.bind(this);
    this.handleNoAppointment = this.handleNoAppointment.bind(this);
    this.uploadProgress = this.uploadProgress.bind(this);
    this.retryPhotos = this.retryPhotos.bind(this);
	
    this.apiFail = this.apiFail.bind(this);
    this.apiDone = this.apiDone.bind(this);
  }

  freshState() {
    return {
      stage: 'intro',
      date: new Date(),
      damageType: null,
      damageLocation: null,
      allPhotos: [],
      claimId: null,
      meetsShineBusinessRules: 'false',
      vehiclePostcode: '',
	  vehicleAddress: '',
	  photo1UploadProgress: 0,
	  photo2UploadProgress: 0,
	  photoUploadFailed: false,
    };
  }

  handleSplashContinue() {
    this.setState({stage: 'chooseDamageLocation'});
  }

  handleIntroContinue() {
    this.setState({stage: 'chooseDamageLocation'});
  }

  handleLocationContinue(postcode, address) {
    this.setState({vehiclePostcode: postcode});
    this.setState({vehicleAddress: address});
    this.setState({stage: 'chooseRepairDate'});
  }

  handleRepairDateContinue(repair_date, booking_Key) {
	  console.log(booking_Key);
    const component = this;
    this.props.api.smartRepairDate(
      this.state.vehiclePostcode,
      this.state.vehicleAddress,
      repair_date,
      booking_Key,
      function done() {
		component.setState({stage: 'different'});
      },
      function fail(data) {
		component.setState({stage: 'noAppointment'});
      },
    );
  }

  handleChosenDate(date) {
    this.setState({stage: 'chooseDamageType', date: date});
  }

  handleChosenDamageType(damageType) {
    this.setState({stage: 'photos', damageType: damageType});
  }

  handleChosenDamageLocation(damageLocation) {
    this.setState({stage: 'chooseDate', damageLocation: damageLocation});
  }

  handleNoAppointment() {
    this.setState({stage: 'noAppointment'});
  }

  handlePhotoUploads(allPhotos) {
    const nextState = Object.assign(
      {}, this.state, {
        stage: 'submitting',
        allPhotos: allPhotos,
      }
    );
    this.setState(nextState);
    this.submitClaim(nextState);
  }

  retryPhotos()
  {
	  const nextState = Object.assign(
      {}, this.state, {
        photoUploadFailed: false
      }
    );
	  
	  this.setState(nextState)
      this.submitClaim(nextState);
  }

  startAgain() {
    var state = this.freshState();
    this.setState(state);
  }

  render() {
    if (this.state.stage === 'intro') {
      return <SmartIntro onContinue={this.handleIntroContinue} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'splash') {
      return <SmartSplash onContinue={this.handleSplashContinue} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'chooseDate') {
      return <SmartDate date={this.state.date} onSubmit={this.handleChosenDate} goBack={() => this.setState({stage: 'chooseDamageLocation'})}/>;
    } else if (this.state.stage === 'chooseDamageType') {
      return <SmartDamageType onChosen={this.handleChosenDamageType} goBack={() => this.setState({stage: 'chooseDate'})}/>;
    } else if (this.state.stage === 'chooseDamageLocation') {
      return <SmartDamageLocation damageLocation={this.state.damageLocation} onChosen={this.handleChosenDamageLocation} goBack={() => this.setState({stage: 'intro'})}/>;
    } else if (this.state.stage === 'photos') {
      return <SmartPhotos damageLocation={this.state.damageLocation} onContinue={this.handlePhotoUploads} goBack={() => this.setState({stage: 'chooseDamageType'})}/>;
    } else if (this.state.stage === 'submitting') {
      return <SmartSubmitting photo1UploadProgress={this.state.photo1UploadProgress} photo2UploadProgress={this.state.photo2UploadProgress} photoUploadFailed={this.state.photoUploadFailed} onRetry={this.retryPhotos} goBack={() => this.setState({stage: 'photos'})} />;
    } else if (this.state.stage === 'another') {
      return <SmartAnother onYes={() => this.startAgain()} onNo={() => this.setState({stage: 'shinedirectrules'})} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'shinedirectrules') {
      if (this.state.meetsShineBusinessRules === 'true') {
		  return <SmartVehicleLocationStatement onNo={() => this.setState({stage: 'thanks'})} onContinue={() => this.setState({stage: 'vehiclelocation'})} goBack={() => this.setState({stage: 'photos'})}/>;
	  }
	  else {
		  this.setState({stage: 'differentReferred'});
		  return null;
	  }
    } else if (this.state.stage === 'vehiclelocation') {
	return <SmartLocation api={this.props.api}  onContinue={this.handleLocationContinue} goBack={() => this.setState({stage: 'shinedirectrules'})}/>;
    } else if (this.state.stage === 'chooseRepairDate') {
	return <SmartRepairDate api={this.props.api} vehiclePostcode={this.state.vehiclePostcode} onNoAppointment={this.handleNoAppointment} onContinue={this.handleRepairDateContinue}  goBack={() => this.setState({stage: 'vehiclelocation'})} />;
    } else if (this.state.stage === 'noAppointment') {
	return <SmartNoAppointment onDone={() => this.props.onDone()} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'different') {
      return <SmartDifferent onYes={() => this.props.onDone()} onNo={() => this.props.onDoneNoMoreClaims()} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'differentReferred') {
      return <SmartDifferentReferred onYes={() => this.props.onDone()} onNo={() => this.props.onDoneNoMoreClaims()} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'thanks') {
      return <SmartThanks onDone={() => this.props.onDone()} goBack={() => this.props.onDone()}/>;
    } else if (this.state.stage === 'fail') {
      return <SmartFail onRetry={() => this.setState({'stage': 'intro'})} goBack={() => this.props.onDone()}/>;
    }
  }

  submitClaim(state) {
    const component = this;
    this.props.api.smartClaim(
      this.props.policy,
      state.date,
      state.damageType,
      state.damageLocation,
      function done(data) {
        state.claimId = data.claim_id;
		state.meetsShineBusinessRules = data.meetsShineBusinessRules;
        component.setState(state);
        component.submitPhotos(state);
      },
      component.apiFail
    );
  }

  apiFail(xhr) {
    this.setState({photoUploadFailed: true});
  }
  
  apiDone() {
	  this.setState({photoUploadFailed: true});
	  this.setState({stage: 'another'})
  }

  submitPhotos(state) {
    const component = this;
    const photos =
      state.allPhotos.map(function(p, index) { return {type: index.toString(), data: p}; });
    component.props.api.uploadPhotos(
      component.props.api.smartPhoto,
      state.claimId,
      photos,
      component.apiDone,
      component.apiFail,
	  component.uploadProgress
    );
  }

  uploadProgress(photoType, loaded, total)
  {
	  let pc = loaded / total
	  if (photoType == "1")
	  {
		  this.setState({photo1UploadProgress: pc})
	  }
	  else
	  {
		  this.setState({photo2UploadProgress: pc})
	  }
  }
}

export default Smart;
