import React, { Component } from 'react';
import popupclosebutton from '../icon-close2.png'
import { Image, StyleSheet, View, Dimensions, TouchableOpacity } from 'react-native'
//import Dialog from "react-native-dialog";

class PhotoPreviewManage extends Component {
	
	constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);

    // Set the state directly. Use props if necessary.
    this.state = {
      isOpen: false
    }
  }
	/*state = { isOpen: false };

  handleShowDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
    console.log("clicked");
  };*/
	  handleShowDialog = () => {
		console.log("clicked");
		this.setState({ isOpen: !this.state.isOpen });
	  };
  
  render() {
	  const dimensions = Dimensions.get('window');

  const imageWidth = (dimensions.width - 80);
    if (this.props.data) {
      return(
        <View style={styles.rowInnerContainer}>
			<TouchableOpacity onPress={this.handleShowDialog} style={styles.imageContainer} >
				<Image style={styles.rowImage} source={{uri:this.props.data}} alt={'Preview'}></Image>
			</TouchableOpacity>
        </View>
      );
    } else {
      return null;
    }
  }
}

const styles = StyleSheet.create({
  imageContainer: {
    width: 150,
	height: 150
  },
  containedImage: {
    width: '100%',
    height: '100%'
  },
  imageOverlay: {
    position: 'relative',
    top: -35,
    width: '90%'
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
	width: '100%'
  },
  rowImage: {
    height: 140,
    resizeMode: 'cover',
    width: 140
  },
  rowInnerContainer: {
    width: '100%',
	display: 'flex',
	alignItems: 'center',
	textAlignVertical: 'top',
  }
})

export default PhotoPreviewManage;
