import React, { useEffect, useState } from 'react'
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import * as ImagePicker from 'expo-image-picker'
import OptionsMenu from "react-native-options-menu";
import store, { RootState } from '../../../redux/Store'
import { addPhoto, clearPhotos } from '../../../redux/SmartClaimSlice'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import Styles from '../../../constants/Styles'
import ConditionalButton from '../../components/ConditionalButton'
import MultiplePhotoUploadTogether from '../../components/MultiplePhotoUploadTogether';
import { useSelector } from 'react-redux'

interface WarrantyPhotosProps {
  navigation: any
  onContinue: Function
  goHome: Function
  goBack: Function
}

export default function WarrantyPhotos ({ navigation, onContinue, goHome, goBack }: WarrantyPhotosProps) {
  const images = useSelector((state: RootState) => state.smartClaim.value.allPhotos)
  const [managingPhotos, setManagingPhotos] = useState(false)
  const [errordisplay, setErrorDisplay] = useState('none')

  const emptyFunction = () => {}
  const canContinue = () => images.length > 0
  const canUpload = () => {
    images.length < 2
      ? pickImage()
      : alert('You have already uploaded 2 photos. Select Manage Photos to view or remove uploaded photos.')
  }
  const canTake = () => {
    images.length < 2
      ? takePhoto()
      : alert('You have already uploaded 2 photos. Select Manage Photos to view or remove uploaded photos.')
  }
  
  function doContinue()
  {
	  onContinue(images)
  }

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!')
        }
		else
		{
			const camstatus = await ImagePicker.requestCameraPermissionsAsync()
			if (camstatus.status !== 'granted') {
			  alert('Sorry, we need camera roll permissions to make this work!')
			}
		}
      }
    })()
  }, [])

  const pickImage = async () => {
    const result : any = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      base64: true,
      aspect: [4, 3],
      quality: 1
    })
    if (!result.cancelled) {
		//console.log(result);
		if (isValidImage(result.assets[0].uri))
		{
      // check file type! (validation method)
			if (Platform.OS != "web")
			{
				store.dispatch(addPhoto('data:image/jpeg;base64,' + result.assets[0].base64))
				//console.log(result.base64);
			}
			else
			{
				store.dispatch(addPhoto(result.assets[0].uri))
				//console.log(result.uri);
			}
			setErrorDisplay('none')
		}
		else {
	  setErrorDisplay('flex')
		}
    }
  }
  
  const takePhoto = async () => {
    const result : any = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      base64: true,
      aspect: [4, 3],
      quality: 1
    })
    if (!result.cancelled) {
		//console.log(result);
		if (isValidImage(result.uri))
		{
      // check file type! (validation method)
		console.log(Platform.OS);
			if (Platform.OS == "android")
			{
				store.dispatch(addPhoto('data:image/jpeg;base64,' + result.base64))
				//console.log(result.base64);
			}
			else
			{
				store.dispatch(addPhoto(result.uri))
				//console.log(result.uri);
			}
			setErrorDisplay('none')
		}
		else {
	  setErrorDisplay('flex')
		}
    }
  }
  
  function isValidImage(dataURI){
	  return true;
	  var retVal = false;
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	
	if (mimeString == 'image/jpeg' || mimeString == 'image/png')
	{
		retVal = true;
	}
	return retVal;
  }
  
  const managePhotos = () => {
		//if (Platform.OS == "web")
		//{
			setManagingPhotos(true)
		//}
  }

  const managePhotosDone = (photos) => {
	  setManagingPhotos(false)
	  store.dispatch(clearPhotos())
		for (var i = 0; i < photos.length; i ++) {
		  store.dispatch(addPhoto(photos[i]))
		}
  }
  
    if (managingPhotos) {
      return(
        <MultiplePhotoUploadTogether heading="Warranty" photos={images} onDone={managePhotosDone}/>
      );
    } 
	else {
      return (
    <View>
      <ScreenHeading text="Repair Request" goBack={goBack}/>
      <View style={styles.container}>
        <VerticalSpace height={15}/>
        <Para>Please upload a photo of your vehicle's speedometer, showing the current mileage.</Para>
        <VerticalSpace height={10}/>
        <View style={styles.rowContainer}>
          <TouchableOpacity style={styles.rowInnerContainer}>
            <View style={styles.imageContainer}>
               <OptionsMenu
				  button={require('../../take-photo2.png')}
				  buttonStyle={styles.rowImage}
				  destructiveIndex={1}
				  options={["Upload", "Take photo", "Cancel"]}
				  actions={[canUpload, canTake, emptyFunction]}/>
            </View>
            <Para centerAlign={true}>Upload / take photo</Para>
			<Para bold={false} color={'red'} display={errordisplay} centerAlign={true} fontSize={12}>Invalid file type, please upload a jpg or png file</Para>
          </TouchableOpacity>
		 
          <TouchableOpacity onPress={managePhotos} style={styles.rowInnerContainer}>
            <View style={styles.imageContainer}>
              <Image style={styles.rowImage} source={require('../../manage-photos2.png')}></Image>
              {/* extract this to own component? */}
              <View style={{ alignSelf: 'flex-end', backgroundColor: canContinue() ? 'green' : 'red', borderRadius: 17, height: 34, justifyContent: 'center', right: 15, position: 'absolute', top: 20, width: 34 }}>
                <Para bold={true} centerAlign={true} color={ 'white' }>{images.length}</Para>
              </View>
            </View>
			<Para centerAlign={true}>Manage photos</Para>
          </TouchableOpacity>
        </View>
        <VerticalSpace height={15}/>
        <ConditionalButton onClick={doContinue} text={'Submit'} condition={canContinue}/>
        <VerticalSpace height={15}/>
      </View>
    </View>
	)
}
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  imageContainer: {
    width: 150,
	height: 150
  },
  containedImage: {
    width: '100%',
    height: '100%'
  },
  imageOverlay: {
    position: 'relative',
    top: -35,
    width: '90%'
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
	width: '100%'
  },
  rowImage: {
    height: 140,
    resizeMode: 'cover',
    width: 140
  },
  rowInnerContainer: {
    width: '45%',
	display: 'flex',
	alignItems: 'center',
	textAlignVertical: 'top',
  }
})
