import React, { useState } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import {Picker} from '@react-native-picker/picker';
import Para from './Para'
import VerticalSpace from './VerticalSpace'
import { setVehiclePostcode } from '../../redux/SmartClaimSlice'
import Styles from '../../constants/Styles'

interface AddressSelectOptionsProps {
  addresses: any;
  formatAddress: any;
  onChange: any;
}

export default function AddressSelectOptions ({ addresses, formatAddress, onChange }: AddressSelectOptionsProps) {
	const [selectedValue, setSelectedValue] = useState('s')
  const selectAddress = (event : any) => {
	  console.log(event);
    const index = (event - 1)
	setSelectedValue(index)
    onChange(addresses[index])
    setVehiclePostcode(addresses[index]?.postcode)
	console.log('test')
  }

  if (addresses?.length > 0) {
    return (
      <View>
        <VerticalSpace height={10} />
		<View style={styles.pickerwrapper}>
			<Picker selectedValue={selectedValue} style={styles.pickerbox} onValueChange={(value, itemIndex) => selectAddress(itemIndex)}>
				<Picker.Item value="1" label="-- Select address --" />
			  {
			  addresses.map((address : any, i : number) =>
				<Picker.Item value={i} label={formatAddress(address)} />
				)
			  }
			</Picker>
		</View>
      </View>
    )
  } else if (addresses?.length === 0) {
    return (
    <Para color={'red'}>We are sorry we cannot find an address using that postcode, please enter the repair address manually.</Para>
    )
  } else {
    return <></>
  }
}

const styles = StyleSheet.create({
	pickerwrapper: {
		borderWidth: 1,
		backgroundColor: '#ffffff',
		borderColor: '#000000',
	},
	pickerbox: {
		fontSize: 16,
		borderWidth: 0,
		...Platform.select({
		  web: {
			height: 55
		  },
		  android: {
			height: 55
		  },
		}),
	},
  selectBox: {
    fontSize: 16,
	margin: 4,
    borderWidth: 2,
	borderStyle: 'solid',
    minHeight: 35,
	borderColor: 'black'
  }
})
