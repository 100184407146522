import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';
import GapResent from './GapResent';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class Gapclaimed extends Component {

  constructor(props) {
    super(props);
	
	this.state = {
      stage: 'intro'
    };
  }


  render() {
	if (this.state.stage === 'intro') {
	return (
      <View>
        <ScreenHeading text="GAP Claim" goBack={this.props.onDone}/>
		<View style={styles.container}>
			<Para>
			  We have already received a claim for this GAP policy. A link to upload the documents we require has been sent in an email to:
			</Para>
			<Para>
			  <strong>{this.props.existingClaimEmail}</strong>
			</Para>
			<Para>
			  If you have not received the email, please check your junk folder as it may be in there.
			</Para>
			<Para>
				If you would like us to resend the link to the email shown above, please select Resend Email below.
			</Para>
			  <DefaultButton text="Resend Email" onClick={() => this.onResendGapEmail()}/>
			<Para>
				If the email address shown is incorrect, please contact our customer support team on 01279 456 500 and select option 1.
			</Para>
		  <Bottom>
			<DefaultButton text="Return to main menu" onClick={this.props.onDone}/>
		  </Bottom>
		  </View>
      </View>
    );
	}
	else
	{
		return (
      <View>
        <ScreenHeading text="GAP Claim" goBack={this.props.onDone}/>
		<View style={styles.container}>
			<Para>
			  Thanks, your email has been scheduled to be resent. You should receive it within the next 10 minutes.
			</Para>
		  <Bottom>
			<DefaultButton text="Return to main menu" onClick={this.props.onDone}/>
		  </Bottom>
		  </View>
      </View>
		);
	}
 }
 
 
 onResendGapEmail() {
	 const component = this;
	this.props.api.resendGapEmail(
      this.props.existingClaimId,
      function done(data) {
		console.log('success');
		component.setState({stage: 'emailsent'});
      },
      function fail(xhr) {
        console.log(xhr);
      }
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default Gapclaimed;
