import React from 'react';
import DefaultButton from './DefaultButton';
import PhotoPreview from './PhotoPreview';

import { StyleSheet, View, Text } from 'react-native'
import Styles from '../../constants/Styles'

function PhotoPreviewWithDelete(props) {
  return (
    <View style={{marginBottom: 10}}>
      <PhotoPreview data={props.data}/>
		<DefaultButton text="Delete" newStyle={styles.deleteButton} onClick={() => props.onDelete(props.index)}/>
    </View>
  );
}


const styles = StyleSheet.create({
  deleteButton: {
    backgroundColor: '#d54b55',
    borderRadius: 4,
    padding: 10
  }
})

export default PhotoPreviewWithDelete;
