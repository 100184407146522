import React from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';

import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

function SmartSplash(props) {
  return (
    <View>
      <ScreenHeading text="SMART Claim" goBack={props.goBack}/>
	  <View style={styles.container}>
		  <Para>
			We're sorry that your vehicle has been damaged.
		  </Para>
		  <Para>
			To help us arrange a repair we need you to provide:</Para>
			<View>
				<Text style={Styles.bullet}>&#8226; Details of the incident.</Text>
				<Text style={Styles.bullet}>&#8226; Images of the damage.</Text>
			</View>
		  
		  <Para>
			Then, before submitting your claim please check your policy terms and conditions to confirm the damage fits within the size (cms) and limits described. These were supplied when you purchased the product/s.
		  </Para>
		  <Para>
			Then press continue.
		  </Para>
		  <Bottom>
			<DefaultButton text="Continue" onClick={props.onContinue}/>
		  </Bottom>
		</View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default SmartSplash;
