import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native'
import ButtonList from '../../components/ButtonList';
import ConditionalButton from '../../components/ConditionalButton';
import ScreenHeading from '../../components/ScreenHeading';
import ProductInfo from '../../ProductInfo';
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

class CancellationProduct extends Component {
  render() {
    return (
      <View>
        <ScreenHeading text="Cancellation" goBack={this.props.goBack}/>
		  <View style={styles.container}>
			<Para>
			  Please select the product you wish to cancel.
			</Para>
			<ButtonList>
			  {this.productButtons()}
			</ButtonList>
		  </View>
      </View>
    );
  }

  productButtons() {
    var info = new ProductInfo(this.props.policies);
    var cancellable = info.cancellablePolicies(true);
    var buttons = [];
    for (var i = 0; i < cancellable.length; i++) {
      let policy = cancellable[i];
      buttons.push(
        <View key={policy.id} style={Styles.optionsButton}>
          <ConditionalButton condition={() => !policy.isPendingCancellation} text={policy.product} onClick={(e) => this.props.onChosen(policy)}/>
        </View>
      );
    }
    return buttons;
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default CancellationProduct;
