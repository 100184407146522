import React from 'react'
import { StyleSheet, View } from 'react-native'
import ScreenHeading from './ScreenHeading'
import DefaultButton from './DefaultButton'
import VerticalSpace from './VerticalSpace'
import Para from './Para'
import Styles from '../../constants/Styles'

interface AnotherProps {
  doAnother: string
  heading: string
  onNo: Function
  onYes: Function
  goBack: Function
}

export default function Another ({ doAnother, heading, onNo, onYes, goBack }: AnotherProps) {
  if (heading === 'Tyre Claim') {
    return (
      <View>
        <ScreenHeading text={heading} goBack={goBack}/>
        <View style={styles.container}>
          <Para>
            Thank you. You should shortly receive an email with instructions on what actions to take next.
          </Para>
          <Para>
            Would you like to {doAnother}?
          </Para>
          <VerticalSpace height={15}/>
          <DefaultButton onClick={() => onYes()} text="Yes"/>
          <VerticalSpace height={15}/>
          <DefaultButton onClick={() => onNo()} text="No"/>
        </View>
      </View>
    )
  } else {
    return (
      <View>
        <ScreenHeading text={heading} goBack={goBack}/>
        <View style={styles.container}>
          <Para>
            Thank you.
          </Para>
          <Para>
            Would you like to {doAnother}?
          </Para>
          <VerticalSpace height={15}/>
          <DefaultButton onClick={() => onYes()} text="Yes"/>
          <VerticalSpace height={15}/>
          <DefaultButton onClick={() => onNo()} text="No"/>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})
