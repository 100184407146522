import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import MultiplePhotoUpload from '../../components/MultiplePhotoUpload';
import ScreenHeading from '../../components/ScreenHeading';
import PhotosBadge from '../../components/PhotosBadge';
import { StyleSheet, View, Text, TouchableOpacity, Platform, Image } from 'react-native'
import Para from '../../components/Para'
import Heading from '../../components/Heading'
import Styles from '../../../constants/Styles'

class TyrePhotos extends Component {
  constructor() {
    super();
    this.state = {
      fullWheelPhotos: [],
      closeUpPhotos: [],
      treadPhotos: [],
      currentPhoto: null
    };
  }

  addPhoto(whichPhoto) {
    this.setState({currentPhoto: whichPhoto});
  }

  addPhotoWithFile(whichPhoto, acceptedFiles) {
	for (var i = 0; i < acceptedFiles.length; i++) {
		this.onDoneDraggingPhoto(whichPhoto, acceptedFiles[i]);
	}
  }

  onDoneDraggingPhoto(whichPhoto, photo) {
	  console.log('test')
		const reader = new FileReader()	
		reader.onload = () => {
      // Do whatever you want with the file contents
        const binaryStr = reader.result
				var nextState = {currentPhoto: null};
				if (whichPhoto === 'full wheel') {
				  nextState.fullWheelPhotos = this.state.fullWheelPhotos;
					if (!nextState.fullWheelPhotos.includes(binaryStr))
					{
						nextState.fullWheelPhotos.push(binaryStr);
					}
				} else if (whichPhoto === 'close up') {
				  nextState.closeUpPhotos = this.state.closeUpPhotos;
					if (!nextState.closeUpPhotos.includes(binaryStr))
					{
						nextState.closeUpPhotos.push(binaryStr);
					}
				} else if (whichPhoto === 'tread') {
				  nextState.treadPhotos = this.state.treadPhotos;
					if (!nextState.treadPhotos.includes(binaryStr))
					{
						nextState.treadPhotos.push(binaryStr);
					}
				}
				this.setState(nextState);
      }

      reader.readAsDataURL(photo);
	  
}

  onDoneAddingPhotos(photos) {
    var nextState = {currentPhoto: null};
    if (this.state.currentPhoto === 'full wheel') {
      nextState.fullWheelPhotos = photos;
    } else if (this.state.currentPhoto === 'close up') {
      nextState.closeUpPhotos = photos;
    } else if (this.state.currentPhoto === 'tread') {
      nextState.treadPhotos = photos;
    }
    this.setState(nextState);
  }

  currentPhotos() {
    if (this.state.currentPhoto === 'full wheel') {
      return this.state.fullWheelPhotos;
    } else if (this.state.currentPhoto === 'close up') {
      return this.state.closeUpPhotos;
    } else if (this.state.currentPhoto === 'tread') {
      return this.state.treadPhotos;
    }
  }

  canSubmit() {
    return (
      this.state.fullWheelPhotos.length > 0 &&
      this.state.closeUpPhotos.length > 0 &&
      this.state.treadPhotos.length > 0
    );
  }

  onContinue() {
    this.props.onContinue(
      this.state.fullWheelPhotos,
      this.state.closeUpPhotos,
      this.state.treadPhotos
    );
  }

  render() {
    if (this.state.currentPhoto) {
      return(
        <MultiplePhotoUpload heading="Tyre Claim" photo={this.state.currentPhoto} photos={this.currentPhotos()} onDone={(photos) => this.onDoneAddingPhotos(photos)}/>
      );
    } else {
      return (
        <View>
          <ScreenHeading text="Tyre Claim" goBack={this.props.goBack}/>
			<View style={styles.container}>
			  <Para>Nearly there, we just need to see some pictures of your {this.props.chosenTyre.toLowerCase()} tyre. As shown in the examples:</Para>
			  <View>
				<Text style={Styles.bullet}>&#8226; Showing the full wheel</Text>
				<Text style={Styles.bullet}>&#8226; A close up</Text>
				<Text style={Styles.bullet}>&#8226; Tyre tread</Text>
			  </View>

			  <Heading>Examples</Heading>

				<View style={styles.rowContainer}>
				  <View style={styles.rowInnerContainer}>
					<View style={styles.imageContainer}>
					  <Image style={styles.rowImage} source={require('../../example-full-wheel.png')}></Image>
					</View>
					<Para centerAlign={true}>Full Wheel</Para>
				  </View>
				 
				  <View style={styles.rowInnerContainer}>
					<View style={styles.imageContainer}>
					  <Image style={styles.rowImage} source={require('../../example-close-up-tyre.png')}></Image>
					</View>
					<Para centerAlign={true}>Close Up</Para>
				  </View>
				</View>
				<View style={styles.rowContainer}>
				  <View style={styles.rowInnerContainer}>
					<View style={styles.imageContainer}>
					  <Image style={styles.rowImage} source={require('../../example-tread.png')}></Image>
					</View>
					<Para centerAlign={true}>Tread</Para>
				  </View>
					<View style={styles.rowInnerContainer}>
					</View>
				</View>

			  <Heading>
				Add your photos here
			  </Heading>

				  { Platform.OS == "web" ?
				<View>
				<View style={styles.rowContainer}>
				  <TouchableOpacity onPress={() => this.addPhoto('full wheel')} style={styles.rowInnerContainer} >
				  <div id="fullwheeldropped">
					<View style={styles.imageContainer}>
					  <Image style={styles.rowImage} source={require('../../add-full.png')}></Image>
					  {/* extract this to own component? */}
					  <View style={{ alignSelf: 'flex-end', backgroundColor: this.state.fullWheelPhotos.length > 0 ? 'green' : 'red', borderRadius: 17, height: 34, justifyContent: 'center', right: 15, position: 'absolute', top: 20, width: 34 }}>
						<Para bold={true} centerAlign={true} color={ 'white' }>{this.state.fullWheelPhotos.length}</Para>
					  </View>
					</View>
					<Para centerAlign={true}>Full Wheel</Para>
				  </div>
				  </TouchableOpacity>
				 
				  <TouchableOpacity onPress={() => this.addPhoto('close up')} style={styles.rowInnerContainer} >
				  <div id="closeupdropped">
					<View style={styles.imageContainer}>
					  <Image style={styles.rowImage} source={require('../../add-close-up.png')}></Image>
					  {/* extract this to own component? */}
					  <View style={{ alignSelf: 'flex-end', backgroundColor: this.state.closeUpPhotos.length > 0 ? 'green' : 'red', borderRadius: 17, height: 34, justifyContent: 'center', right: 15, position: 'absolute', top: 20, width: 34 }}>
						<Para bold={true} centerAlign={true} color={ 'white' }>{this.state.closeUpPhotos.length}</Para>
					  </View>
					</View>
					<Para centerAlign={true}>Close Up</Para>
				  </div>
				  </TouchableOpacity>
				</View>
				<View style={styles.rowContainer}>
				  <TouchableOpacity onPress={() => this.addPhoto('tread')} style={styles.rowInnerContainer} >
					<div id="treaddropped">
					<View style={styles.imageContainer}>
					  <Image style={styles.rowImage} source={require('../../add-tread.png')}></Image>
					  {/* extract this to own component? */}
					  <View style={{ alignSelf: 'flex-end', backgroundColor: this.state.treadPhotos.length > 0 ? 'green' : 'red', borderRadius: 17, height: 34, justifyContent: 'center', right: 15, position: 'absolute', top: 20, width: 34 }}>
						<Para bold={true} centerAlign={true} color={ 'white' }>{this.state.treadPhotos.length}</Para>
					  </View>
					</View>
					<Para centerAlign={true}>Tread</Para>
				  </div>
				  </TouchableOpacity>
					<View style={styles.rowInnerContainer}>
					</View>
				 
					</View>
				</View> :
				
				<View>
				<View style={styles.rowContainer}>
				  <TouchableOpacity onPress={() => this.addPhoto('full wheel')} style={styles.rowInnerContainer} id="fullwheeldropped">
					<View style={styles.imageContainer}>
					  <Image style={styles.rowImage} source={require('../../add-full.png')}></Image>
					  {/* extract this to own component? */}
					  <View style={{ alignSelf: 'flex-end', backgroundColor: this.state.fullWheelPhotos.length > 0 ? 'green' : 'red', borderRadius: 17, height: 34, justifyContent: 'center', right: 15, position: 'absolute', top: 20, width: 34 }}>
						<Para bold={true} centerAlign={true} color={ 'white' }>{this.state.fullWheelPhotos.length}</Para>
					  </View>
					</View>
					<Para centerAlign={true}>Full Wheel</Para>
				  </TouchableOpacity>
				 
				  <TouchableOpacity onPress={() => this.addPhoto('close up')} style={styles.rowInnerContainer} id="closeupdropped">
					<View style={styles.imageContainer}>
					  <Image style={styles.rowImage} source={require('../../add-close-up.png')}></Image>
					  {/* extract this to own component? */}
					  <View style={{ alignSelf: 'flex-end', backgroundColor: this.state.closeUpPhotos.length > 0 ? 'green' : 'red', borderRadius: 17, height: 34, justifyContent: 'center', right: 15, position: 'absolute', top: 20, width: 34 }}>
						<Para bold={true} centerAlign={true} color={ 'white' }>{this.state.closeUpPhotos.length}</Para>
					  </View>
					</View>
					<Para centerAlign={true}>Close Up</Para>
				  </TouchableOpacity>
				</View>
				<View style={styles.rowContainer}>
				  <TouchableOpacity onPress={() => this.addPhoto('tread')} style={styles.rowInnerContainer} id="treaddropped">
					<View style={styles.imageContainer}>
					  <Image style={styles.rowImage} source={require('../../add-tread.png')}></Image>
					  {/* extract this to own component? */}
					  <View style={{ alignSelf: 'flex-end', backgroundColor: this.state.treadPhotos.length > 0 ? 'green' : 'red', borderRadius: 17, height: 34, justifyContent: 'center', right: 15, position: 'absolute', top: 20, width: 34 }}>
						<Para bold={true} centerAlign={true} color={ 'white' }>{this.state.treadPhotos.length}</Para>
					  </View>
					</View>
					<Para centerAlign={true}>Tread</Para>
				  </TouchableOpacity>
					<View style={styles.rowInnerContainer}>
					</View>
					</View>
				 
				</View>
				  }
			  <Bottom>
				<ConditionalButton text="Submit" condition={() => this.canSubmit()} onClick={() => this.onContinue()}/>
			  </Bottom>
			</View>
        </View>
      );
    }
  }

 componentDidMount () {
	if (Platform.OS == "web")
	{
		var fullwheeldropped = document.getElementById("fullwheeldropped");
		var closeupdropped = document.getElementById("closeupdropped");
		var treaddropped = document.getElementById("treaddropped");
		
		if (fullwheeldropped != null && closeupdropped != null && treaddropped != null)
		{
			const the_app = this;
			
			fullwheeldropped.addEventListener('drop', function(e) {
			  e.preventDefault();
			  const dt = e.dataTransfer;
			  the_app.addPhotoWithFile('full wheel', dt.files)
			  fullwheeldropped.style.border = 'none';
			})
			fullwheeldropped.addEventListener('dragover', function(e) {
			  e.preventDefault();
			  fullwheeldropped.style.border = '4px dotted green';
			})
			fullwheeldropped.addEventListener('dragleave', function(e) {
			  e.preventDefault();
			  fullwheeldropped.style.border = 'none';
			})
			closeupdropped.addEventListener('drop', function(e) {
			  e.preventDefault();
			  const dt = e.dataTransfer;
			  the_app.addPhotoWithFile('close up', dt.files)
			  closeupdropped.style.border = 'none';
			})
			closeupdropped.addEventListener('dragover', function(e) {
			  e.preventDefault();
			  closeupdropped.style.border = '4px dotted green';
			})
			closeupdropped.addEventListener('dragleave', function(e) {
			  e.preventDefault();
			  closeupdropped.style.border = 'none';
			})
			treaddropped.addEventListener('drop', function(e) {
			  e.preventDefault();
			  const dt = e.dataTransfer;
			  the_app.addPhotoWithFile('tread', dt.files)
			  treaddropped.style.border = 'none';
			})
			treaddropped.addEventListener('dragover', function(e) {
			  e.preventDefault();
			  treaddropped.style.border = '4px dotted green';
			})
			treaddropped.addEventListener('dragleave', function(e) {
			  e.preventDefault();
			  treaddropped.style.border = 'none';
			})
		}
	}
 }

 componentDidUpdate () {
	if (Platform.OS == "web")
	{
		var fullwheeldropped = document.getElementById("fullwheeldropped");
		var closeupdropped = document.getElementById("closeupdropped");
		var treaddropped = document.getElementById("treaddropped");
		
		if (fullwheeldropped != null && closeupdropped != null && treaddropped != null)
		{
			const the_app = this;
			
			fullwheeldropped.addEventListener('drop', function(e) {
			  e.preventDefault();
			  const dt = e.dataTransfer;
			  the_app.addPhotoWithFile('full wheel', dt.files)
			  fullwheeldropped.style.border = 'none';
			})
			fullwheeldropped.addEventListener('dragover', function(e) {
			  e.preventDefault();
			  fullwheeldropped.style.border = '4px dotted green';
			})
			fullwheeldropped.addEventListener('dragleave', function(e) {
			  e.preventDefault();
			  fullwheeldropped.style.border = 'none';
			})
			closeupdropped.addEventListener('drop', function(e) {
			  e.preventDefault();
			  const dt = e.dataTransfer;
			  the_app.addPhotoWithFile('close up', dt.files)
			  closeupdropped.style.border = 'none';
			})
			closeupdropped.addEventListener('dragover', function(e) {
			  e.preventDefault();
			  closeupdropped.style.border = '4px dotted green';
			})
			closeupdropped.addEventListener('dragleave', function(e) {
			  e.preventDefault();
			  closeupdropped.style.border = 'none';
			})
			treaddropped.addEventListener('drop', function(e) {
			  e.preventDefault();
			  const dt = e.dataTransfer;
			  the_app.addPhotoWithFile('tread', dt.files)
			  treaddropped.style.border = 'none';
			})
			treaddropped.addEventListener('dragover', function(e) {
			  e.preventDefault();
			  treaddropped.style.border = '4px dotted green';
			})
			treaddropped.addEventListener('dragleave', function(e) {
			  e.preventDefault();
			  treaddropped.style.border = 'none';
			})
		}
	}
 }


}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  imageContainer: {
    width: 150,
	height: 150,
	alignItems: 'center',
  },
  containedImage: {
    width: '100%',
    height: '100%'
  },
  imageOverlay: {
    position: 'relative',
    top: -35,
    width: '90%'
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
	width: '100%',
	paddingTop: 20,
  },
  rowImage: {
    height: 140,
    resizeMode: 'cover',
    width: 140
  },
  rowInnerContainer: {
    width: '45%',
	display: 'flex',
	alignItems: 'center',
	textAlignVertical: 'top',
  }
})

export default TyrePhotos;
