import React, { Component, useEffect } from 'react';
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import ScreenHeading from '../../components/ScreenHeading';
import { StyleSheet, View, Text, TextInput } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'

class WarrantyAdditionalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      additionalInfo: props.additionalInfo
    };
  }

  additionalInfoChanged(value) {
    this.setState({additionalInfo: value});
  }
  
  canSubmit() {
    return (
      true
    );
  }


  render() {
    return (
      <View>
        <ScreenHeading text="Warranty" goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  Is there any other information that you would like to let us know?
			</Para>
			<TextInput multiline={true} numberOfLines={4} style={styles.textInput} onChangeText={(e) => this.additionalInfoChanged(e)} value={this.state.additionalInfo}>
			</TextInput>
			<VerticalSpace height={15} />
			<Bottom>
			  <ConditionalButton text="Submit" condition={() => this.canSubmit()} onClick={() => this.props.onSubmit(this.state.additionalInfo)}/>
			</Bottom>
		</View>
      </View>
    );
  }

}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
textInput: {
    borderWidth: 1,
    fontSize: 16,
    height: 140,
    padding: 5,
	paddingBottom: 15,
	textAlignVertical: 'top'
  }
})
export default WarrantyAdditionalInfo;
