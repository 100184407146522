import React from 'react';
import Another from '../../components/Another';

function SmartAnother(props) {
  return (
    <Another
      heading="SMART Claim"
      doAnother="submit another SMART claim"
      onYes={props.onYes}
	  goBack={props.goBack}
      onNo={props.onNo}/>
  );
}

export default SmartAnother;
