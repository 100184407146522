import React from 'react';
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';
import { StyleSheet, View, Text } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'

function CancellationContactDealer(props) {
  return(
      <View>
        <ScreenHeading text="Sorry" goBack={props.goBack}/>
		<View style={styles.container}>
		  <Para>
			As your purchase was within the last
			30 days, you will need to arrange your cancellation via your retailer.
		  </Para>
		  <Bottom>
			<DefaultButton text="Return to Main Menu" onClick={props.onDone}/>
		  </Bottom>
		</View>
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})

export default CancellationContactDealer;
