import React, { FunctionComponent } from 'react'
import { GestureResponderEvent, StyleSheet, Text, View, Linking } from 'react-native'
import Colors from '../../constants/Colors'
import Para from './Para'

function PhoneButton() {
  return (
    <View>
      <Para fontSize={18} centerAlign={false}>
        Contact customer services.
      </Para>
	  <Para>
	        {`Office opening hours:
	  Mon – Fri 9am to 5pm
	  Closed Weekends and Bank Holidays.`}
	  </Para>
		<View style={styles.defaultButton} >
		  <Text style={styles.buttonText} onPress={() => Linking.openURL('https://www.autoprotect.co.uk/customer_support')}>Send us a message</Text>
		</View>
    </View>
  );
}


const styles = StyleSheet.create({
  phoneButtonLabel: {
	  marginTop: -3,
	  textAlign: 'center',
	  fontSize: 18,
	  height: 30,
	  margin: 0,
  },
  phoneButtonLink: {
	  backgroundColor: '#d54b55',
	  color: '#fff',
	  textAlign: 'center',
	  textDecoration: 'none',
	  lineHeight: 20,
  },
  buttonText: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  defaultButton: {
    backgroundColor: '#312e49',
    borderRadius: 4,
    padding: 10
  }
})


export default PhoneButton;
