import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import Colors from '../../constants/Colors'
import Styles from '../../constants/Styles'
import VerticalSpace from './VerticalSpace'

type ScreenHeadingProps = {
  text: string
}

const ScreenHeading: FunctionComponent<ScreenHeadingProps> = (props: ScreenHeadingProps) => {
  return (
    <View>
      <View style={styles.textBannerContainer}>
        <Text style={styles.textBanner}>{props.text}</Text>
      </View>
	  {props.goBack != null ?
      <View style={styles.navBannerContainer}>
        <TouchableOpacity onPress={() => props.goBack()}><Text style={styles.navBanner} >&#8592; Go Back</Text></TouchableOpacity>
      </View>: null }
      <VerticalSpace height={5} />
    </View>
  )
}

export default ScreenHeading

const styles = StyleSheet.create({
  textBanner: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
	textTransform: 'uppercase'
  },
  textBannerContainer: {
    backgroundColor: Colors.red, // AutoProtect
    //backgroundColor: Colors.teal, // Shine
    paddingVertical: 8,
    width: '100%'
  },
  navBanner: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'left',
	textTransform: 'uppercase'
  },
  navBannerContainer: {
	paddingHorizontal: Styles.standardPadding,
    backgroundColor: Colors.darkgrey, // AutoProtect
    //backgroundColor: Colors.teal, // Shine
    paddingVertical: 8,
    width: '100%'
  }
})
