import React, { Component } from 'react';
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import DatePicker from '../../components/DatePicker';
import ScreenHeading from '../../components/ScreenHeading';
import moment from 'moment'
import ButtonList from '../../components/ButtonList';
import DefaultButton from '../../components/DefaultButton';
import waitingimg from '../../loading-png-gif.gif';

import { StyleSheet, View, Text, Image } from 'react-native'
import Para from '../../components/Para'
import Styles from '../../../constants/Styles'


class AlloyRepairDate extends Component {
  constructor(props) {
    super();
    this.state = {
      date: new Date(),
	  suggested_dates: [],
	  dates_index: 0,
	  current_suggested_date: '',
	  loading: true,
	  arrival_time_text: '',
	  formatted_date: ''
    };
	this.GetShineDates(props);
  }
  
  
  GetShineDates(props) {
	  let theApp = this;
	  this.setState({suggested_dates:[]});
	props.api.shineAlloyDatesLookup(props.vehiclePostcode, function done(suggested_dates) {
      if (suggested_dates) {
		if (suggested_dates.length > 0)
		{
    setTimeout(function () {
        const nextState = Object.assign({}, theApp.state, {suggested_dates: suggested_dates});
        theApp.setState(nextState);
		theApp.setState({current_suggested_date: suggested_dates[theApp.state.dates_index]});
		 var date = new Date(suggested_dates[theApp.state.dates_index].Date.split(' ').join('')); 
		 var formattedDate = moment(date, 'DD-MM-YYYY').format('DD MMM YYYY');
		 console.log(formattedDate);
		 console.log(suggested_dates);
		theApp.setState({formatted_date: formattedDate});
		
		if (suggested_dates[theApp.state.dates_index].Timeslot == "AM")
		{
			theApp.setState({arrival_time_text: 'Between 8:00 am and 10:00am'});
		}
		else
		{
			theApp.setState({arrival_time_text: 'Between 11:30 and 15:30'});
		}
		
		theApp.setState({loading: false});


    }, 5000);
		}
		else
		{
			props.onNoAppointment();
		}

      }
	  else
		{
			props.onNoAppointment();
		}
    }, function notFound() {

    }, function fail(xhr) {
      console.log(xhr);
      //onFail();
    });	 
 }
 
 GetNewShineDate()
 {
	 if (this.state.dates_index < (this.state.suggested_dates.length - 1))
	 {
		 const theApp = this;
		 var new_index = this.state.dates_index+1;
		 this.setState({dates_index: new_index});
		 this.setState({loading: true});
		 setTimeout(function () {
			theApp.setState({current_suggested_date: theApp.state.suggested_dates[theApp.state.dates_index]});
			 var date = new Date(theApp.state.suggested_dates[theApp.state.dates_index].Date.split(' ').join('')); 
			 var formattedDate = moment(date, 'DD-MM-YYYY').format('DD MMM YYYY');
			 console.log(formattedDate);
			theApp.setState({formatted_date: formattedDate});
			if (theApp.state.suggested_dates[theApp.state.dates_index].Timeslot == "AM")
			{
				theApp.setState({arrival_time_text: 'arrival time between 8:00 am and 10:00 am'});
			}
			else
			{
				theApp.setState({arrival_time_text: 'arrival time between 11:30 and 15:30'});
			}
			theApp.setState({loading: false});
			}, 5000);
	
	 }
	 else
	 {
		 this.props.onNoAppointment();
	 }
 }

  render() {
		if (this.state.suggested_dates.length == 0 || this.state.loading == true)
		{
			return (
			  <View>
				<ScreenHeading text="Alloy Claim" goBack={this.props.goBack}/>
				<View style={styles.container}>
					<Para>
					  Please wait. 
					</Para>
					<Para>
						Do not leave this screen. We are retrieving your appointment date.
					</Para>
					<Para>
						This may take up to 120 seconds.
					</Para>
					<View id='waiting-image'>
						<Image source={waitingimg} alt="Waiting..."></Image>
					</View>
				</View>
			  </View>
			);
		}
		else
		{
			return (
			  <View>
				<ScreenHeading text="Alloy Claim" goBack={this.props.goBack}/>
				<View style={styles.container}>
					<Para>
					  Thank you for your patience.
					</Para>
					<Para>
					  The next available date for your repair is:
					</Para>
					<Para>
					{this.state.formatted_date + " " + this.state.current_suggested_date.Timeslot}
					</Para>
					<Para>
						{this.state.arrival_time_text}
					</Para>
					<Para>Do you wish to accept this date and time.</Para>
					<ButtonList>
					  <View style={Styles.optionsButton}><DefaultButton text="Yes" onClick={(e) => this.props.onContinue(this.state.formatted_date + " " + this.state.current_suggested_date.Timeslot, this.state.current_suggested_date.Booking_Key)} /></View>
					  <View style={Styles.optionsButton}><DefaultButton text="No" onClick={(e) => this.GetNewShineDate()} /></View>
					</ButtonList>
				</View>
			  </View>
			);
		}
  }

  setDate(newDate) {
    this.setState({date: newDate});
  }

  canSubmit() {
    return true;
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})

export default AlloyRepairDate;
