import React, { Component } from 'react';

import { StyleSheet, View, Text, Platform, TouchableOpacity } from 'react-native'
import VerticalSpace from './VerticalSpace'

class YesNo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkState: props.checkState
    };
	this.handleSelectTrue = this.handleSelectTrue.bind(this);
	this.handleSelectFalse = this.handleSelectFalse.bind(this);
  }

  handleSelectTrue() {
    this.setState(Object.assign({}, this.state, {checkState: true}));
    this.props.onSelect(true);
	console.log('true')
  }

  handleSelectFalse() {
    this.setState(Object.assign({}, this.state, {checkState: false}));
    this.props.onSelect(false);
	console.log('false')
  }
  
  render() {
    return(
	<View>
        <VerticalSpace height={30} />
      <View style={styles.yesNo}>
        <View style={styles.yesNoQuestion}>
          <Text>{this.props.question}</Text>
        </View>
        <View style={styles.yesNoControl}>
          <TouchableOpacity style={Platform.OS == "web" ? styles.yesNoBoxWeb : styles.yesNoBox} onPress={this.handleSelectTrue}>
            {this.yesCheck()}
          </TouchableOpacity>
          <Text style={styles.yesNoLabel}>
            Yes
          </Text>
        </View>
        <View style={styles.yesNoControl}>
          <TouchableOpacity style={Platform.OS == "web" ? styles.yesNoBoxWeb : styles.yesNoBox} onPress={this.handleSelectFalse}>
            {this.noCheck()}
          </TouchableOpacity>
          <Text style={styles.yesNoLabel}>
            No
          </Text>
        </View>
      </View>
	</View>
    );
  }

  yesCheck() {
    if (this.state.checkState === true) {
      return (<Text style={styles.yesNoTick}>✔</Text>);
    } else {
      return null;
    }
  }

  noCheck() {
    if (this.state.checkState === false) {
      return (<Text style={styles.yesNoCross}>✘</Text>);
    } else {
      return null;
    }
  }
}

const styles = StyleSheet.create({
	yesNo: {
	  display: 'flex',
	  padding: 5,
	  flexDirection: 'row',
	},

	yesNoQuestion: {
	  flexBasis: '60%',
	},

	yesNoControl: {
	  flexBasis: '20%',
	  textAlign: 'center',
	  alignItems: 'center',
	},

	yesNoBoxWeb: {
	  margin: 'auto',
	  width: 30,
	  height: 30,
	  backgroundColor: '#fff',
	  borderWidth: 1,
	  borderStyle: 'solid',
	  borderColor: '#ccc',
	  textAlign: 'center',
	  cursor: 'pointer',
	  lineHeight: 20,
	  paddingTop: 5,
	},

	yesNoBox: {
	  margin: 'auto',
	  width: 30,
	  height: 30,
	  backgroundColor: '#fff',
	  borderWidth: 1,
	  borderStyle: 'solid',
	  borderColor: '#ccc',
	  textAlign: 'center',
	  lineHeight: 30,
	  paddingTop: 5,
	  paddingLeft: 9,
	},

	yesNoTick: {
	  color: 'green',
	},

	yesNoCross: {
	  color: 'red',
	},

	yesNoLabel: {
	  lineHeight: 20,
	},
})

export default YesNo;
